import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/sign` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const getsign = () => API.get('/');

export const createsign = (formdata) => API.post('/', formdata);
