// material-ui
import { styled } from '@mui/material/styles';

import { 
  CircularProgress, 
  // useMediaQuery 
} from '@mui/material';

// loader style

// ==============================|| Loader ||============================== //

const Loader = () => {
  // const small = useMediaQuery('max-width:900px');
  const LoaderWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    // left: small ? 0 : 300,
    zIndex: 2001,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }));
  return (
    <LoaderWrapper>
      <CircularProgress size={100} color='primary' />
    </LoaderWrapper>
  );
};

export default Loader;
