import { Grid, Typography, Box, useTheme, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from 'components/charts/grouped-bar-chart';
import { ChevronRight } from '@mui/icons-material';
import UpdatesCard from 'components/admin/updates_card';
import { Link, useNavigate } from 'react-router-dom';
import { getBusiness } from 'actions/business';
import { getEmployeeAll } from 'actions/employee';
import serialize from 'utils/serialize';
import { getDocument } from 'actions/document';
import { getDocumentCounts } from 'actions/document';
import { getUpdate } from 'actions/update';
import { deleteUpdate } from 'actions/update';
import { deleteMany } from 'actions/update';
import Loader from 'components/misc/Loader';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Home = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const theme = useTheme();
  const [business, setBusiness] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [chart, setChart] = useState({});
  const [updates, setUpdates] = useState({
    results: [],
    page: 0,
    limit: 3,
    totalPages: 0,
    totalResults: 0,
  });

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  useEffect(() => {
    const getData = async () => {
      setChart(await getDocumentCounts());
      setBusiness(await getBusiness(serialize({ page: 'all' })));
      setEmployees(await getEmployeeAll(serialize({ page: 'all' })));
      setDocuments(await getDocument(serialize({ page: 'all' })));
      setUpdates(
        await getUpdate(
          serialize({ page: 1, limit: 3, sortBy: 'createdAt:desc' }),
        ),
      );
    };
    getData();
  }, [setChart, setBusiness, setEmployees, setDocuments, setUpdates]);

  const deleteupdate = async (id) => {
    await deleteUpdate(id, async () => {
      const data = await getUpdate(
        serialize({ page: 1, limit: 3, sortBy: 'createdAt:desc' }),
      );
      setUpdates(data);
    });
  };

  const deleteBulkUpdates = async () => {
    const ids = updates.results.map((obj) => obj._id);

    await deleteMany({ ids }).then(async (res) => {
      setUpdates(
        await getUpdate(
          serialize({ page: 1, limit: 3, sortBy: 'createdAt:desc' }),
        ),
      );
    });
  };
  if (loading) {
    return <Loader />;
  }
  return (
    // <Suspense fallback={<Loader />}>
    <Grid sx={{ overflow: 'hidden' }}>
      <Grid sx={{ px: 3, py: 4.5, height: '100px' }} container alignItems='center' item xs={12}>
        <Typography color='primary' fontSize={25} fontWeight={600}>
          {lan.Dashboard}
        </Typography>
      </Grid>
      <Grid
        item
        container
        sx={{
          maxHeight: '83vh',
          overflowY: 'scroll',
          p: 3,
        }}
        xs={12}
      >
        <Typography
          color='primary'
          fontSize={20}
          fontWeight={600}
        >
          {lan.Daily_Documents}
        </Typography>
        <Grid item xs={12} sx={{ width: '100%', height: 300, my: 3 }}>
          <BarChart data={chart} />
        </Grid>
        <Typography
          sx={{ mb: 2 }}
          color='primary'
          fontSize={20}
          fontWeight={600}
        >
          {lan.Total_Stats}
        </Typography>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Box
              component={Link}
              to='/admin/manage-business'
              sx={{
                height: '200px',
                width: '100%',
                position: 'relative',
                backgroundColor: theme.custom.secondary,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                textDecoration: 'none',
                p: 1,
                boxSizing: 'border-box',
                borderRadius: '20px',
              }}
            >
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography color='white' fontSize={50} fontWeight={700}>
                  {business?.length || 0}
                </Typography>
                <Typography fontSize={20} fontWeight={400} color='white'>
                  {lan.Registered_Businesses}
                </Typography>
              </Box>
              <Box sx={{ position: 'absolute', top: '45%', right: '5%' }}>
                <ChevronRight color='white' sx={{ fontSize: 30 }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              component={Link}
              to='/admin/manage-employee'
              sx={{
                height: '200px',
                width: '100%',
                backgroundColor: theme.custom.secondary,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                p: 1,
                position: 'relative',

                textDecoration: 'none',
                boxSizing: 'border-box',
                borderRadius: '20px',
              }}
            >
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography color='white' fontSize={50} fontWeight={700}>
                  {employees?.length || 0}
                </Typography>
                <Typography fontSize={20} fontWeight={400} color='white'>
                  {lan.Registered_Employees}
                </Typography>
              </Box>
              <Box sx={{ position: 'absolute', top: '45%', right: '5%' }}>
                <ChevronRight color='white' sx={{ fontSize: 30 }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ position: 'relative' }}>
            <Box
              sx={{
                height: '200px',
                width: '100%',
                backgroundColor: theme.custom.secondary,
                color: 'white',
                display: 'flex',
                p: 1,
                alignItems: 'center',
                justifyContent: 'space-around',
                boxSizing: 'border-box',
                borderRadius: '20px',
              }}
            >
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography
                  color='white'
                  textAlign='center'
                  fontSize={50}
                  fontWeight={700}
                >
                  {documents?.length || 0}
                </Typography>
                <Typography
                  fontSize={20}
                  textAlign='center'
                  fontWeight={400}
                  color='white'
                >
                  {lan.Documents}
                </Typography>
              </Box>
              {/* <Box sx={{ position: 'absolute', top: '50%', right: '5%' }}>
                <ChevronRight color='white' sx={{ fontSize: 30 }} />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ mt: 4 }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography fontSize={20} fontWeight={600} color='primary'>
              {lan.Updates}
            </Typography>
          </Grid>
          {
            updates.results.length > 0 ? (
              <Grid item>
                <Button
                  variant='text'
                  disabled={updates.results.length === 0}
                  onClick={deleteBulkUpdates}
                >
                  <Typography
                    fontSize={18}
                    sx={{ textTransform: 'capitalize' }}
                    fontWeight={500}
                  >
                    {lan.Clear_All}
                  </Typography>
                </Button>
              </Grid>
            ) : ''
          }
        </Grid>
        <Grid
          item
          container
          justifyContent={'center'}
          alignItems='center'
          xs={12}
          sx={{ ...(updates.results.length < 1 ? { minHeight: '10vh' } : {}) }}
        >
          {updates.results.length > 0 ? (
            updates.results.map((obj, index) => {
              return (
                <UpdatesCard
                  data={obj}
                  keys={index}
                  onClick={async () => await deleteupdate(obj._id)}
                />
              );
            })
          ) : (
            <>
              <Typography>{lan.No_Update_found}</Typography>
            </>
          )}
        </Grid>
        {
          updates.results.length > 0 ? (
            <Grid item
              xs={12}
              sx={{ pt: 2 }}
              container justifyContent={'center'}>
              <Button
                variant='contained'
                disabled={updates.results.length === 0}
                color='primary'
                sx={{ color: 'white' }}
                onClick={() => navigation('/admin/updates')}
              >
                {lan.View_All}
              </Button>
            </Grid>
          ) : ''
        }
      </Grid>
    </Grid>
  );
};

export default Home;
