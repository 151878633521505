import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import UpdatesCard from 'components/admin/updates_card';
import serialize from 'utils/serialize';
import { getUpdate } from 'actions/update';
import { deleteUpdate } from 'actions/update';
import { deleteMany } from 'actions/update';
const Updates = () => {
  const [updates, setUpdates] = useState({
    results: [],
    page: 0,
    totalPages: 0,
    totalResults: 0,
  });
  const [page, setPage] = useState(1);
  useEffect(() => {
    const getData = async () => {
      setUpdates(
        await getUpdate(serialize({ page, limit: 10, sortBy: '-createdAt' })),
      );
    };
    getData();
  }, [setUpdates]);

  const deleteupdate = async (id) => {
    await deleteUpdate(id).then(async (res) => {
      setUpdates(
        await getUpdate(serialize({ page, limit: 10, sortBy: '-createdAt' })),
      );
    });
  };

  const deleteBulkUpdates = async () => {
    const ids = updates.results.map((obj) => obj._id);
    await deleteMany({ ids }).then(async (res) => {
      setUpdates(
        await getUpdate(serialize({ page, limit: 10, sortBy: '-createdAt' })),
      );
    });
  };
  return (
    <Grid>
      <Grid
        item
        xs={12}
        container
        sx={{ px: 3, py: 4.5, height: '100px' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography color='primary' fontSize={25} fontWeight={600}>
            Updates
          </Typography>
        </Grid>
        {
          updates.results.length > 0 ? (
            <Grid item>
              <Button
                variant='text'
                disabled={updates.length === 0}
                onClick={deleteBulkUpdates}
              >
                <Typography
                  fontSize={18}
                  sx={{ textTransform: 'capitalize' }}
                  fontWeight={500}
                >
                  Clear All
                </Typography>
              </Button>
            </Grid>
          ) : ''
        }
      </Grid>
      <Grid sx={{ p: 3 }}>
        <Grid
          item
          container
          justifyContent={'center'}
          alignItems='center'
          xs={12}
          sx={{ ...(updates.results.length < 1 ? { minHeight: '100vh' } : {}) }}
        >
          {updates.results.length > 0 ? (
            updates.results.map((obj) => {
              return (
                <UpdatesCard
                  data={obj}
                  keys={obj._id}
                  onClick={async () => deleteupdate(obj._id)}
                />
              );
            })
          ) : (
            <>
              <Typography>No Update found</Typography>
            </>
          )}
        </Grid>
        {updates.results.length > 10 ? (
          <Grid
            item
            container
            xs={12}
            spacing={3}
            justifyContent='flex-end'
            alignItems={'center'}
          >
            <Grid item>
              <Button
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
                variant='text'
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Typography color='primary'>{page}</Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={page === updates.totalPages || updates.totalPages === 0}
                onClick={() => setPage(page + 1)}
                variant='text'
              >
                Next
              </Button>
            </Grid>
          </Grid>
        ) : ''
        }
      </Grid>
    </Grid>
  );
};

export default Updates;
