import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/auth` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const login = (formdata) => API.post('/admin-login', formdata);
export const changepassword = (formdata) =>
  API.put('/admin-change-password', formdata);

export const updateprofile = (formdata) => API.put('/update-profile', formdata);
