import {
  Grid,
  Typography,
  TextField,
  useTheme,
  Button,
  Box,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

import { Link, useNavigate } from 'react-router-dom';
import BusinessCard from 'components/admin/business_card';
import { CSVLink } from 'react-csv';
import { getEmployeeAll } from 'actions/employee';
import { useSelector } from 'react-redux';
import { getEmployee } from 'actions/employee';
import serialize from 'utils/serialize';
import Loader from 'components/misc/Loader';
import { getBusiness } from 'actions/business';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const ManageEmployees = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState({
    results: [],
    page: 0,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
  });
  const [businesses, setBusiness] = useState([]);
  const [business, selectBusiness] = useState('');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth)?.user;
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });
  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      console.log(business);
      if (user.role === 'admin') {
        setEmployees(
          await getEmployeeAll(
            serialize({
              ...(search.length > 0 ? { page: 1, search } : { page }),
              ...(business.length > 0 ? { page: 1, business } : { page }),
              limit: 5,
              populate: 'business',
            }),
          ),
        );
        setBusiness(await getBusiness(serialize({ page: 'all' })));
      } else {
        setEmployees(
          await getEmployee(
            user._id,
            serialize({
              ...(search.length > 0 ? { page: 1 } : { page }),
              limit: 5,
              populate: 'business',
              ...(search.length > 0 ? { search: search } : {}),
            }),
          ),
        );
      }
    };
    getData();
  }, [setEmployees, user, page, search, business]);

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <Box sx={{ px: 3 }}>
      <Grid
        item
        container
        xs={12}
        sx={{ py: 4.5 }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={8}>
          <Typography color='primary' fontSize={20} fontWeight={600}>
            {lan.Manage_Employees}
          </Typography>
        </Grid>
        <Grid item>
          <CSVLink
            headers={[
              { label: 'First Name', key: 'first_name' },
              { label: 'Last Name', key: 'last_name' },
              { label: 'Email', key: 'email' },
              { label: 'Phone', key: 'phone' },
              { label: 'Language', key: 'language' },
              { label: 'Business', key: 'business.name' },
            ]}
            data={employees.results}
            filename={lan.jump_sign_employee}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Typography
              fontSize={20}
              sx={{ display: 'flex', alignItems: 'center' }}
              fontWeight={400}
            >
              <DownloadIcon style={{ marginRight: 6 }} /> {lan.Export_CSV}
            </Typography>
          </CSVLink>
        </Grid>
        <Grid item>
          <Typography
            component={Link}
            to={
              user.role === 'admin'
                ? '/admin/add-employee'
                : '/dashboard/add-employee'
            }
            fontSize={20}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'black',
            }}
            fontWeight={400}
          >
            <AddCircleOutlineIcon
              sx={{ color: theme.custom.primary, marginRight: 1 }}
            />{' '}
            {lan.Add}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid
          item
          xs={12}
          md={localStorage.getItem('role') === 'admin' ? 6 : 12}
        >
          <TextField
            variant='outlined'
            fullWidth
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 5 }} />,
            }}
            placeholder='Search'
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        {localStorage.getItem('role') === 'admin' && (
          <Grid item xs={12} md={6}>
            <TextField
              select
              value={business}
              fullWidth
              onChange={(e) => selectBusiness(e.target.value)}
              label='Business'
            >
              <MenuItem value=''>{lan.All}</MenuItem>
              {businesses.map((obj) => {
                return (
                  <MenuItem key={obj._id} value={obj._id}>
                    {obj.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={11}
        sx={{
          mt: 2,
          textDecoration: 'none',
          boxSizing: 'border-box',
          height: '60vh',
          overflowY: 'hidden'
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {
              employees.results.map((obj) => {
                return (
                  <BusinessCard
                    data={{ ...obj, name: obj.first_name + ' ' + obj.last_name }}
                    key={obj._id}
                    onClick={() =>
                      user.role === 'admin'
                        ? navigate('/admin/add-employee/' + obj._id)
                        : navigate('/dashboard/add-employee/' + obj._id)
                    }
                  />
                );
              })
            }
            {
              employees.totalResults === 0 ? (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '50vh',
                  alignItems: 'center'
                }}>
                  {lan.No_Employee_Added}
                </Box>
              ) : ''
            }
          </>
        )
        }
      </Grid>
      {employees.totalPages > 1 && (
        <Grid
          item
          container
          xs={12}
          spacing={3}
          justifyContent='flex-end'
          alignItems={'center'}
        >
          <Grid item>
            <Button
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
              variant='text'
            >
              {lan.Previous}
            </Button>
          </Grid>
          <Grid item>
            <Typography color='primary'>{page}</Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={page === employees.totalPages}
              onClick={() => setPage(page + 1)}
              variant='text'
            >
              {lan.Next}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ManageEmployees;
