import React, { useEffect, useState } from "react";

import { 
    Box, 
    // useMediaQuery 
} from "@mui/material";
import { 
    Routes, 
    Route, 
    // useNavigate, 
    useParams 
} from "react-router";
import jsonToNestedArray from "utils/jsonToNestedArray";
import Loader from "components/misc/Loader";
import PdfPreview from "../pages/Document/pdfPreview";
import axios from "axios";
import baseurl from "api/baseurl";
// import { toast } from "react-toastify";

// import EN_JSON from "assets/languages/en.json";
// import FN_JSON from "assets/languages/fn.json";

export default function ShareDocumentRoutes() {
    // const lan =
    //     localStorage.getItem("language") === "canadian" ? FN_JSON : EN_JSON;

    const { token, id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isPreviewModal, setPreviewModal] = React.useState(false);
    // const navigate = useNavigate();

    const [attributeArray, setAttributeArray] = React.useState([]);
    const [pdfName, setPdfName] = useState("");
    const [PdfImages, setPdfImages] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            axios
                .get(baseurl + "/document/" + id, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res) => {
                    setLoading(false);
                    setPdfName(res.data?.document?.document_name || "");
                    setAttributeArray(
                        res.data?.document?.attribute_array || []
                    );
                    setPdfImages(res.data?.document?.image_buffer_array || []);
                    localStorage.setItem("token", token);
                    setPreviewModal(true);
                    // document_id: id,
                    // image: urls,
                    // document_name: (pdfName || file.name),
                    // attribute_array: attributeArray || []

                    // localStorage.getItem('token')
                });
        };
        getData();
    }, [id]);

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <Box
                        sx={{
                            display: "flex",
                            backgroundColor: "#F5F5F5",
                        }}
                    >
                        {loading ? (
                            <Box>
                                <Loader />
                            </Box>
                        ) : (
                            ""
                        )}
                        {isPreviewModal ? (
                            <PdfPreview
                                pdf_id={id}
                                pdfName={pdfName}
                                pdfpages={PdfImages}
                                attributeArray={jsonToNestedArray(
                                    attributeArray
                                )}
                                close={() => {
                                    // toast.success(
                                    //     lan.Document_Updated_Sucessfully
                                    // );
                                    // setPreviewModal(false);
                                    // setLoading(false);
                                    // localStorage.clear();
                                    // navigate("/login");
                                    if (window.ReactNativeWebView)
                                        window.ReactNativeWebView.postMessage(
                                            true
                                        );
                                }}
                                isAutoShare={1}
                            />
                        ) : (
                            ""
                        )}
                    </Box>
                }
            />
        </Routes>
    );
}
