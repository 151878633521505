// import './DrawingCanvas.css';
import { useEffect, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas'
import { useTheme } from '@emotion/react';
import { createSign } from 'actions/sign';
import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const DrawingCanvas = ({
  canvasRef,
  contextRef,
  getImgURL,
  getClose,
  isCustomer,
  savedSignature
}) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  // const [isDrawing, setIsDrawing] = useState(false);
  // const [img, setImg] = useState('');
  const [color, setcolor] = useState('black');
  const colors = ['black', '#000EFF'];
  // const colors = ['black', 'red', '#000EFF', '#00FF19'];
  const theme = useTheme();

  useEffect(() => {
    // const canvas = canvasRef.current;
    // canvas.width = 500;
    // canvas.height = 500;

    // const context = canvas.getContext('2d');
    // context.lineCap = 'round';
    // context.strokeStyle = 'black';
    // context.lineWidth = 5;
    // contextRef.current = context;
  }, []);

  // const startDrawing = ({ nativeEvent }) => {
  //   const { offsetX, offsetY } = nativeEvent;
  //   contextRef.current.beginPath();
  //   contextRef.current.moveTo(offsetX, offsetY);
  //   contextRef.current.lineTo(offsetX, offsetY);
  //   contextRef.current.strokeStyle = color;
  //   contextRef.current.stroke();
  //   setIsDrawing(true);
  //   nativeEvent.preventDefault();
  // };

  // const draw = ({ nativeEvent }) => {
  //   if (!isDrawing) {
  //     return;
  //   }

  //   const { offsetX, offsetY } = nativeEvent;
  //   contextRef.current.lineTo(offsetX, offsetY);
  //   contextRef.current.stroke();
  //   nativeEvent.preventDefault();
  // };

  // const stopDrawing = () => {
  //   contextRef.current.closePath();
  //   setIsDrawing(false);
  // };

  // const saveImageToLocal = (event) => {
  //   let link = event.currentTarget;
  //   link.setAttribute('download', 'canvas.png');
  //   let image = canvasRef.current.toDataURL('image/png');
  //   link.setAttribute('href', image);
  // };

  const trim = () => {
    // setImg(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    // console.log('Promise >>>>', Promise);
    // console.log('Promise >>>>', Promise.all());
    getImgURL({
      img: sigPad.getTrimmedCanvas().toDataURL('image/png')
    })
    console.log('sigPad >>>>', sigPad);
    if (!isCustomer) {
      sigPad.getTrimmedCanvas().toBlob(async (blob) => {
        const formdata = new FormData();
        formdata.append('sign', blob, 'signature.jpeg');
        const data = await createSign(formdata);
        savedSignature(data);
      })
    }
    if (isCustomer)
      getClose({ status: false });
  };

  let sigPad = {}
  const clear = () => {
    sigPad.clear()
  }
  // const trim = () => {
  //   console.log('trim >>>>>', sigPad.getTrimmedCanvas().toDataURL('image/png'));
  // }
  return (
    <Box sx={{
      width: '100%',
    }}>
      <Grid sx={{
        width: '100%',
        height: '300px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '4px 4px 25px 2px #00000040',
      }}>
        <SignatureCanvas
          penColor={color}
          canvasProps={{
            width: '550px',
            height: '300px'
          }}
          getTrimmedCanvas={trim}
          ref={(ref) => { sigPad = ref }}
        />
      </Grid>
      <Grid item xs={12} sx={{ m: 0, width: '100%', mt: 2 }} spacing={3} container>
        <Grid item container xs={5} sx={{
          padding: '0px !important'
        }}>
          <Box
            display='flex'
            alignItems='center'
            width='100%'
          >
            {colors.map((item) => {
              return (
                <Box
                  key={item}
                  onClick={() => setcolor(item)}
                  sx={{
                    ...(color === item
                      ? { border: `4px solid ${theme.custom.highlight}` }
                      : {}),
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    marginLeft: '10px',
                    backgroundColor: item,
                  }}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={7} sx={{
          padding: '0px !important',
          display: 'flex',
          justifyContent: 'space-evenly'
        }}>
          <Button
            md={3}
            onClick={clear}
            size='large'
            sx={{ color: 'white' }}
            variant='contained'
            color='primary'
          >
            {lan.Clear}
          </Button>

          <Button
            md={3}
            onClick={trim}
            size='large'
            sx={{ color: 'white' }}
            variant='contained'
            color='primary'
          >
            {lan.Trim_and_sign}
          </Button>
        </Grid>
      </Grid>
      {/* <canvas
        // className='canvas-container'
        ref={canvasRef}
        style={{
          height: '450px',
          width: '100%',
          borderRadius: '10px',
        }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
      ></canvas> */}
      {/* <div> */}
      {/* <a
          id='download_image_link'
          href='download_link'
          onClick={saveImageToLocal}
        >
          Download Image
        </a> */}
      {/* </div> */}
    </Box>
  );
};

export default DrawingCanvas;
