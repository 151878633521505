import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/document` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const createdocument = (formdata) => {return API.post('/', formdata)};
export const getdocument = (query) => API.get('?' + query);
export const getdocumentbyid = (id) => API.get(`/${id}`);
export const updatedocument = (id, formdata) => API.put(`/${id}`, formdata);
export const getdocumentbybusiness = (id, query) => {
  return API.put(`/business/${id}${query ? `?${query}` : ''}`);
};

export const getdocumentcount = () => API.get('/counts');
