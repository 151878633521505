import React from 'react';
import {
  Checkbox,
  // Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router';

const Document = ({ setExpanded, tabs }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <List>
      {tabs.map((obj, index) => {
        return (
          <ListItemButton
            key={index}
            onClick={() => {
              if (obj.path) navigate(obj.path);
              else obj.onClick();
              setExpanded(false);
            }}
            sx={{
              alignItems: 'center',
              ...(location.pathname === obj.path
                ? { backgroundColor: theme.custom.highlight }
                : {}),
            }}
          >
            <ListItemIcon>
              {obj.icon ? (
                <img src={obj.icon} alt='' />
              ) : (
                <Checkbox
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                    ml: -1,
                  }}
                  checked={Boolean(obj.copy)}
                />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color='white' fontSize={20} fontWeight={500}>
                  {obj.name}
                </Typography>
              }
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default Document;
