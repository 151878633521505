import { getbusinessbyid } from 'api/business';
import { deletebusiness } from 'api/business';
import { updatebusiness } from 'api/business';
import { getbusiness } from 'api/business';
import { createbusiness } from 'api/business';

import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export const createBusiness = async (formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await createbusiness(formdata);
    console.log(data);
    toast.success(lan.Business_Created_Sucessfully);
    router('/admin/manage-business');
  } catch (error) {
    console.log(error);
    toast.error(lan.Something_went_wrong);
  }
};

export const getBusiness = async (query) => {
  const { data } = await getbusiness(query);
  return data.business;
};

export const getBusinessByID = async (id) => {
  const { data } = await getbusinessbyid(id);
  return data.business;
};

export const updateBusiness = async (id, formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { data } = await updatebusiness(id, formdata);
  if (localStorage.getItem('role') === 'business') {
    localStorage.setItem(
      'profile',
      JSON.stringify({
        ...data.business,
        role: 'business',
      }),
    );
    localStorage.setItem('language', data.business.language);
  }
  toast.success(lan.Business_Details_Updated);
  router(-1);
};

export const deleteBusiness = async (id, router) => {
  
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { data } = await deletebusiness(id);
  console.log(data);
  toast.success(lan.Business_Deleted);
  router(-2);
};
