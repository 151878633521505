import React from 'react';
import logo from 'assets/new_logo.png';
import {
  FormControl,
  TextField,
  Box,
  Container,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { adminLogin } from 'actions/auth';
import { useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export default function SignIn() {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;
  const { register, handleSubmit, formState: { errors } } = useForm();

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    console.log('data >>>', data)
    dispatch(adminLogin(navigation, data));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '100vh',
      }}
    >
      <Box
        sx={{ overflowX: 'hidden', overflowY: 'hidden' }}
        style={{
          backgroundColor: '#6DBD44',
        }}
      >
        <Box sx={{
          width: 'max-content',
          backgroundColor: 'white',
          display: 'flex',
          boxSizing: 'border-box',
          height: '100%',
          alignItems: 'center',
          p: 3
        }}
          justifyContent='center'
        >
          <img src={logo} alt={lan.logo} width={'120px'} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          justifyContent: 'center',
          bgcolor: '#f5f5f5',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: '100%',
        }}
      >
        <Container
          component='main'
          style={{
            backgroundColor: 'white',
            border: '1px solid #F0F0F0',
            borderRadius: '20px',
            height: '500px',
            padding: '50px'
          }}
          maxWidth='sm'
        >
          <form sx={{
            width: '100%'
          }} onSubmit={handleSubmit(onSubmit)}>
            <Typography
              component='h1'
              color='primary'
              fontWeight={600}
              fontSize={40}
            >
              {lan.Login}
            </Typography>
            <Box
              noValidate
              sx={{
                mt: 4,
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FormControl fullWidth>
                <Typography>{lan.Email_address}</Typography>
                <TextField
                  {...register('email', {
                    required: lan.Email_is_required, pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: lan.Invalid_email_address
                    }
                  })
                  }
                  fullWidth
                  sx={{ py: 1 }}
                  placeholder={lan.Enter_your_Email}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <span className='text-red'>{message}</span>}
                />
              </FormControl>
              <FormControl fullWidth sx={{ my: 2 }}>
                <Typography>{lan.Password}</Typography>
                <TextField
                  {...register('password', {
                    required: lan.Password_is_required
                  })
                  }
                  type='password'
                  fullWidth
                  sx={{ py: 1 }}
                  placeholder={lan.Enter_your_Password}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => <span className='text-red'>{message}</span>}
                />
              </FormControl>
              <Button
                type='submit'
                fullWidth
                size='large'
                variant='contained'
                sx={{ mt: 3, mb: 2, color: 'white' }}
              >
                {lan.Sign_In}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Box>
  );
}
