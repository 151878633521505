import React, { 
  useEffect, 
  // useRef, 
  useState 
} from 'react';
import { 
  Box, 
  // Grid, 
  // TextField, 
  // Typography 
} from '@mui/material';
import { getSign } from 'actions/sign';
import { useSelector } from 'react-redux';
import * as PDFJS from 'pdfjs-dist/webpack';
import DragAndDrop from './DragAndDrop';
import Loader from 'components/misc/Loader';
import axios from 'axios';
import { 
  // Routes, 
  // Route, 
  // Navigate, 
  // useNavigate, 
  useParams 
} from 'react-router';
import baseurl from 'api/baseurl';
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
const Home = ({
  stageRef,
  images,
  setImages,
  canvasPages,
  page,
  setPage,
  setMultipleImages,
  multipleImages,
  setUrl,
  urls,
  setAttributeArray,
  editDocumentId,
  setAllAttributeArray
}) => {
  // const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.auth)?.user;
  const [loading, setLoading] = useState(true);
  const file = useSelector((state) => state.file);
  const [signatures, setSignatures] = useState([]);
  useEffect(() => {
    const getData = async () => setSignatures(await getSign());
    getData();
  }, [setSignatures]);
  useEffect(() => {
    if (!id)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  }, [setLoading]);
  const [pdf, setPDF] = useState([]);
  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };
  const convertPdfToImages = async (file) => {
    console.log('convertPdfToImages >>>', file);
    const pdfPage = [];
    const data = await readFileData(file);
    console.log('data >>>');
    const pdf = await PDFJS.getDocument(data).promise;
    console.log('pdf >>>', pdf);
    const canvas = document.createElement('canvas');
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      console.log('viewport >>>>>', viewport);
      const context = canvas.getContext('2d');
      console.log('context >>>>>', context);
      var resolution = 1.5;
      canvas.height = resolution * viewport.height;
      canvas.width = resolution * viewport.width;
      console.log('canvas >>>>>', canvas);
      await page.render({
        canvasContext: context,
        viewport: viewport,
        transform: [resolution, 0, 0, resolution, 0, 0]
      }).promise;
      pdfPage.push(canvas.toDataURL('image/png', 1));
    }
    canvas.remove();
    setPDF(pdfPage);

    setUrl((state) => (state.length > 0 ? state : pdfPage));
  };

  const convertPdfToImagesEditDocument = async (file) => {
    console.log('convertPdfToImagesEditDocument >>>>>>', file);
    const pdfPage = [];
    const pdf = await PDFJS.getDocument(file).promise;
    const canvas = document.createElement('canvas');
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext('2d');
      var resolution = 1.5;
      canvas.height = resolution * 792;
      canvas.width = resolution * 612;
      await page.render({ canvasContext: context, viewport: viewport, transform: [resolution, 0, 0, resolution, 0, 0] }).promise;
      pdfPage.push(canvas.toDataURL('image/png', 1));
    }
    canvas.remove();
    setPDF(pdfPage);
    setUrl((state) => (state.length > 0 ? state : pdfPage));
    setLoading(false);
  };

  useEffect(() => {
    if (!id) {
      console.log('useEffect >>> ID >>>>>>>', id)
      convertPdfToImages(file.file);
    }
  }, [file, setPDF]);

  useEffect(() => {
    console.log('useEffect >>> ID >>>>>>>', id)
    const getDocumentData = async () => {
      axios
        .get(baseurl + '/document/' + id, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then((res) => {
          editDocumentId(id);
          // let attribute = res.data?.document?.attribute_array;
          let attribute = [];
          setAllAttributeArray(res.data?.document?.attribute_array);
          // setOriginalAttributeArray(res.data?.document?.attribute_array);
          console.log(res.data?.document?.attribute_array);
          if (res.data?.document?.attribute_array.length > 0)
            res.data?.document?.attribute_array.forEach((attr) => {
              console.log('res.data?.document?.created_by >>>>>', attr, res.data?.document?.created_by, user._id, res.data?.document?.send_to)
              if (res.data?.document?.created_by === user._id || (res.data?.document?.send_to.indexOf(attr.inviteId) > -1)) {
                if (!attribute[attr.pageNo])
                  attribute[attr.pageNo] = [];
                attribute[attr.pageNo].push(attr);
              }
            });
          console.log('GET attribute >>>>>', attribute);
          setAttributeArray(attribute);
          localStorage.setItem('all_images', JSON.stringify(attribute));
          convertPdfToImagesEditDocument(res.data.document.document);
        });
    };
    if (id)
      getDocumentData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  return (
    <Box
      width='100%'
      sx={{
        boxSizing: 'border-box',
        backgroundColor: '#F5F5F5',
      }}
    >
      <DragAndDrop
        signatures={signatures}
        stageRef={stageRef}
        pdfpages={pdf}
        images={images}
        setImages={setImages}
        canvases={canvasPages}
        page={page}
        setPage={setPage}
        multiple={multipleImages}
        setMultiple={setMultipleImages}
        setUrl={setUrl}
        urls={urls}
        setAttributeArray={setAttributeArray}
        editDocumentId={id}
      />
    </Box>
  );
};

export default Home;
