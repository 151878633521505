import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  useTheme,
  Avatar,
  Select
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import MuiPhoneNumber from 'mui-phone-number';
import { getEmployeeById } from 'actions/employee';
import { updateEmployee } from 'actions/employee';
// import baseurl from 'api/baseurl';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/misc/Loader';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const AddEmployee = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const user = useSelector((state) => state.auth).user;
  const theme = useTheme();
  const [state, setState] = useState({
    first_name: '',
    business: '',
    last_name: '',
    email: '',
    phone: '',
    language: 'english',
    ip_address: [''],
    account_expiry: new Date(),
  });

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState();
  const [imageURL, setImageURL] = useState('');
  const ref = useRef();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [language, setLanguage] = useState('english');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'language') {
      setLanguage(value);
    }

    setState((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getEmployeeById(user._id);
      setLoading(false);

      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('email', data.email);
      setLanguage(data.language);
      
      setState(data);
      setImage(data.logo);
      setImageURL(data.logo);
    };
    getData();
  }, [setState, user]);

  const onSubmit = async (dataObj) => {
    const formdata = new FormData();
    formdata.append('first_name', dataObj.first_name);
    formdata.append('last_name', dataObj.last_name);
    formdata.append('email', dataObj.email);
    formdata.append('language', language);
    formdata.append('phone', state.phone);
    
    if (state?.ip_address?.length > 0) {
      dataObj.ip_address = state.ip_address;
    } else {
      dataObj.ip_address = [];
    }
    formdata.append('ip_address', JSON.stringify(dataObj.ip_address));
    
    if (image) formdata.append('logo', image);

    setLoading(true);
    const data = await updateEmployee(user._id, formdata);
    dispatch({ type: 'UPDATE_PROFILE', data });
    setLoading(false);
  };
  return (
    <Grid sx={{ px: 3 }}>
      <Grid item sx={{ py: 4.5 }} xs={12}>
        <Typography color='primary' fontSize={25} fontWeight={600}>
          {lan.Edit_Profile}
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item container xs={12} sx={{ mt: 3 }} spacing={2}>
          <Grid container item xs={12} spacing={2} alignItems='center'>
            <input
              type='file'
              accept='image/jpg, image/png'
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImageURL(URL.createObjectURL(e.target.files[0]));
                e.target.value = '';
              }}
              style={{ display: 'none' }}
              ref={ref}
            />
            <Grid item>
              <IconButton
                sx={{ width: 120, height: 120 }}
                onClick={() => ref.current.click()}
              >
                <Avatar src={imageURL} sx={{ width: 120, height: 120 }}></Avatar>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{lan.First_Name}*</Typography>
            <TextField
              fullWidth
              {...register('first_name', { required: lan.Required_field })}
              sx={{ py: 1 }}
              placeholder={lan.Enter_First_Name}
            />
            <ErrorMessage
              errors={errors}
              name="first_name"
              render={({ message }) => <span className='text-red'>{message}</span>}
            />
            {/* <TextField
            fullWidth
            name='first_name'
            value={state.first_name}
            onChange={handleChange}
            sx={{ py: 1 }}
            placeholder='Enter First Name'
          /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{lan.Last_Name}*</Typography>
            <TextField
              fullWidth
              {...register('last_name', { required: lan.Required_field })}
              sx={{ py: 1 }}
              placeholder={lan.Enter_Last_Name}
            />
            <ErrorMessage
              errors={errors}
              name="last_name"
              render={({ message }) => <span className='text-red'>{message}</span>}
            />
            {/* <TextField
              value={state.last_name}
              fullWidth
              name='last_name'
              onChange={handleChange}
              sx={{ py: 1 }}
              placeholder='Enter First Name'
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{lan.Email_address}*</Typography>
            <TextField
              {...register('email', {
                required: lan.Email_is_required, pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: lan.Invalid_email_address
                }
              })
              }
              fullWidth
              sx={{ py: 1 }}
              placeholder={lan.Enter_your_Email}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <span className='text-red'>{message}</span>}
            />
            {/* <TextField
              name='email'
              value={state.email}
              onChange={handleChange}
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Email'
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{lan.Mobile_No}</Typography>
            <MuiPhoneNumber
              disableAreaCodes
              variant='outlined'
              defaultCountry='ca'
              disabled={true}
              name='phone'
              value={state.phone}
              fullWidth
              sx={{ py: 1 }}
              placeholder={lan.Enter_your_Phone}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography>{lan.Set_Language}</Typography>

            <Select
              sx={{ mt: 1 }}
              name='language'
              value={language}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value='english'>{lan.English}</MenuItem>
              <MenuItem value='canadian'>{lan.Canadian_French}</MenuItem>
            </Select>

            {/* <TextField
              name='language'
              onChange={handleChange}
              sx={{ width: '30%', py: 1 }}
              select
              value={state.language}
            >
              <MenuItem value='english'>English</MenuItem>
              <MenuItem value='canadian'>Canadian French</MenuItem>
            </TextField> */}
          </Grid>
          <Grid item xs={12} md={12} container>
            <Grid
              item
              xs={12}
              // justifyContent='space-between'
              alignItems='center'
              container
            >
              <Grid item xs={1}>
                <Typography>{lan.IP_Address}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={(theme) => ({
                    backgroundColor: theme.custom.primary,
                    color: 'white',
                    height: 25,
                    width: 25,
                    '&:hover': {
                      backgroundColor: theme.custom.secondary,
                      color: 'white',
                    },
                  })}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      ip_address: [...state.ip_address, ''],
                    }));
                  }}
                >
                  <Add fontSize={'15'} />
                </IconButton>
              </Grid>
            </Grid>
            {state.ip_address.map((obj, index) => {
              return (
                <Grid
                  item
                  container
                  alignItems={'center'}
                  spacing={1}
                  key={index}
                  xs={12}
                  md={4}
                >
                  {
                    obj ? (
                      <>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            name='ip_address'
                            value={obj ? obj : ''}
                            disabled={Boolean(obj)}
                            sx={{ py: 1 }}
                            placeholder={lan.Enter_your_IP_Address}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => {
                              setState((state) => ({
                                ...state,
                                ip_address: state.ip_address.filter(
                                  (item, i) => i !== index,
                                ),
                              }));
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            name='ip_address'
                            onChange={(e) => {
                              console.log('e >>>>>', e, e.target.value);
                              obj = e.target.value;
                              setState((state) => {
                                state.ip_address[index] = e.target.value;
                                return state;
                              });
                            }}
                            sx={{ py: 1 }}
                            placeholder={lan.Enter_your_IP_Address}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => {
                              setState((state) => ({
                                ...state,
                                ip_address: state.ip_address.filter(
                                  (item, i) => i !== index,
                                ),
                              }));
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              // onClick={handleSubmit}
              sx={{ padding: '5px 50px 5px 50px', color: 'white' }}
            >
              {lan.Save}
            </Button>
          </Grid>
        </Grid>
      </form>
      {
        loading ? (
          <Grid item xs={12} sx={{ height: '60vh', overflowY: 'hidden' }}>
            <Loader />
          </Grid>
        ) : ''
      }
    </Grid>
  );
};

export default AddEmployee;
