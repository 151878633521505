import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/updates` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const getupdate = (query) => API.get('?' + query);

export const deleteupdate = (id) => API.delete('/' + id);

export const deletemany = (ids) => API.post('/delete', ids);
