import { Box, Typography } from '@mui/material';
import React, { 
  useEffect, 
  useCallback, 
  // useState 
} from 'react';
import upload from 'assets/misc/upload.svg';
import { useNavigate, useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
// import Loader from 'components/misc/Loader';
import axios from 'axios';
import baseurl from 'api/baseurl';
import { CircularProgress } from '@mui/material';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Home = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(localStorage.getItem('language'));
  const onDrop = useCallback(
    (acceptedFiles) => {
      // toast.info('Uploading pdf');
      dispatch({
        type: 'FILE',
        data: {
          url: URL.createObjectURL(acceptedFiles[0]),
          file: acceptedFiles[0],
        },
      });
      navigate('/document');
    },
    [dispatch, navigate],
  );
  const { 
    getRootProps, 
    getInputProps, 
    // isDragActive 
  } = useDropzone({ onDrop });

  useEffect(() => {
    const getDocumentData = async () => {
      axios
        .get(baseurl + '/document/' + id, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then((res) => {
          dispatch({
            type: 'FILE',
            data: {
              data: res?.data?.document,
              s3url: res?.data?.document.document,
              file_name: res?.data?.document.document_name,
              file: {
                name: res?.data?.document?.document_name
              },
            }
          });
          navigate('/document/' + id);
        });
    };
    if (id)
      getDocumentData();
  }, [id]);

  return (
    <>
      {
        id ? (
          <Box
            sx={{
              width: '100%',
              height: '92vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} color='primary' />
            <Typography
              color='primary'
              sx={{ mt: 3 }}
              fontSize={25}
              fontWeight={600}
            >
              {lan.Loading_Document}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '92vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} accept='application/pdf' />
            <>
              <img src={upload} alt='/' />
              <Typography
                color='primary'
                sx={{ mt: 3 }}
                fontSize={25}
                fontWeight={600}
              >
                {lan.Upload_Document}
              </Typography>
            </>
          </Box>
        )
      }
    </>
  );
};

export default Home;
