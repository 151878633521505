import { deletemany } from 'api/update';
import { deleteupdate } from 'api/update';
import { getupdate } from 'api/update';
// import { toast } from 'react-toastify';

export const getUpdate = async (query) => {
  const { data } = await getupdate(query);
  return data.update;
};

export const deleteUpdate = async (id, fetch) => {
  try {
    await deleteupdate(id);

    await fetch();
  } catch (error) {
    console.log('something went wrong');
  }
};

export const deleteMany = async (formdata) => {
  try {
    await deletemany(formdata);
  } catch (error) {
    console.log('something went wrong');
  }
};
