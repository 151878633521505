import {
  Grid,
  Typography,
  TextField,
  useTheme,
  Box,
  Avatar,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BusinessCard from 'components/admin/business_card';
import { getBusinessByID } from 'actions/business';
import { getEmployee } from 'actions/employee';
import serialize from 'utils/serialize';
// import baseurl from 'api/baseurl';
import { getDocumentByBusiness } from 'actions/document';
import Loader from 'components/misc/Loader';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const ManageEmployees = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const { id } = useParams();
  const [selected, setSelected] = useState(null);
  const [employees, setEmployees] = useState({
    results: [],
    page: 0,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
  });
  const [documents, setDocuments] = useState([]);
  const [employeeCount, setEmployeeCount] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });
  useEffect(() => {
    const getData = async () => {
      setSelected(await getBusinessByID(id));
      setEmployees(
        await getEmployee(
          id,
          serialize({
            ...(search.length > 0 ? { page: 1 } : { page }),
            limit: 5,
            ...(search.length > 0 ? { search } : {}),
          }),
        ),
      );
      setEmployeeCount(await getEmployee(id, serialize({ page: 'all' })));
      setEmployeeCount(await getEmployee(id, serialize({ page: 'all' })));
      setDocuments(await getDocumentByBusiness(id));
    };
    getData();
  }, [id, setSelected, setEmployees, page, search, setDocuments]);
  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      <Grid
        item
        sx={{ px: 3, py: 4.5, mb: 2 }}
        container
        alignItems='center'
        xs={12}
        justifyContent='space-between'
      >
        <Grid item>
          <Typography color='primary' fontSize={20} fontWeight={600}>
            {lan.Business_Overview}
          </Typography>
        </Grid>
        <Grid
          component={Link}
          to={'/admin/add-business/' + id}
          sx={{ textDecoration: 'none', color: 'black' }}
          item
        >
          <Typography
            fontSize={20}
            fontWeight={400}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <EditIcon style={{ marginRight: 1 }} /> {lan.Edit_Profile}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        sx={{
          height: '80vh',
          overflow: 'scroll',
          px: 3,
          pb: 3,
        }}
      >
        {selected && (
          <Grid
            item
            container
            sx={{
              // border: `1px solid ${theme.custom.primary}`,
              borderRadius: '10px',
            }}
            spacing={3}
            xs={12}
          >
            <Grid container item xs={6} sx={{ p: 2 }}>
              <Grid item container xs={12} spacing={1} alignItems='center'>
                <Grid item xs={4}>
                  <Avatar
                    src={selected.logo}
                    alt=''
                    style={{
                      width: '120px',
                      height: '120px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    crossOrigin='anonymous'
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    sx={{ mb: 1 }}
                    fontSize={30}
                    color='primary'
                    fontWeight={700}
                  >
                    {selected.name}
                  </Typography>
                  <Typography fontSize={20} color='primary' fontWeight={400}>
                    {selected.address}
                  </Typography>
                </Grid>
                <Grid item xs={10} sx={{ mt: 3 }}>
                  <Box
                    fontSize={20}
                    fontWeight={300}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {lan.Email_address}
                    <Typography
                      fontSize={17}
                      sx={{ ml: 3 }}
                      fontWeight={500}
                      color='primary'
                    >
                      {selected.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box
                    fontSize={20}
                    fontWeight={300}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {lan.Phone}
                    <Typography
                      fontSize={17}
                      sx={{ ml: 3 }}
                      fontWeight={500}
                      color='primary'
                    >
                      {selected.phone}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid spacing={3} item xs={6} container>
              <Grid item xs={5.5}>
                <Box
                  component={Link}
                  to='/admin/manage-employee'
                  sx={{
                    height: '200px',
                    width: '100%',
                    textDecoration: 'none',
                    backgroundColor: theme.custom.secondary,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                  }}
                >
                  <Box sx={{ textAlign: 'center', width: '100%' }}>
                    <Typography color='white' fontSize={50} fontWeight={700}>
                      {employeeCount.length || 0}
                    </Typography>
                    <Typography fontSize={20} fontWeight={400} color='white'>
                      {lan.Registered_Employees}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={5.5}>
                <Box
                  sx={{
                    height: '200px',
                    width: '100%',
                    backgroundColor: theme.custom.secondary,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                  }}
                >
                  <Box sx={{ textAlign: 'center', width: '100%' }}>
                    <Typography
                      textAlign={'center'}
                      color='white'
                      fontSize={50}
                      fontWeight={700}
                    >
                      {documents.length}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      fontSize={20}
                      fontWeight={400}
                      color='white'
                    >
                      {lan.Documents}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          sx={{ my: 6 }}
          container
          xs={12}
          justifyContent='space-between'
        >
          <Grid item xs={8}>
            <Typography color='primary' fontSize={20} fontWeight={600}>
              {lan.Manage_Employees}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CSVLink
              headers={[
                { label: lan.First_Name, key: 'first_name' },
                { label: lan.Last_Name, key: 'last_name' },
                { label: lan.Email, key: 'email' },
                { label: lan.Phone, key: 'phone' },
                { label: lan.Language, key: 'language' },
                { label: lan.Business, key: 'business.name' },
              ]}
              data={employees.results}
              filename={lan.jump_sign_employee}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Typography
                fontSize={20}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: '-20px',
                }}
                fontWeight={400}
              >
                <DownloadIcon style={{ marginRight: 6 }} /> {lan.Export_CSV}
              </Typography>
            </CSVLink>
          </Grid>
          <Grid item>
            <Typography
              component={Link}
              to={'/admin/add-employee?' + serialize({ business: id })}
              fontSize={20}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'black',
              }}
              fontWeight={400}
            >
              <AddCircleOutlineIcon
                sx={{ color: theme.custom.primary, marginRight: 1 }}
              />{' '}
              {lan.Add}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            fullWidth
            InputProps={{
              style: { borderRadius: '20px' },
              startAdornment: <SearchIcon sx={{ mr: 5 }} />,
            }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={lan.Search}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ mt: 3, textDecoration: 'none', boxSizing: 'border-box' }}
        >
          {employees.results.map((obj) => {
            return (
              <BusinessCard
                data={{ ...obj, name: obj.first_name + ' ' + obj.last_name }}
                key={obj._id}
                onClick={() => navigate('/admin/add-employee/' + obj._id)}
              />
            );
          })}
          {
            employees.totalResults === 0 ? (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '50vh',
                alignItems: 'center'
              }}>
                {lan.No_Employee_Added}
              </Box>
            ) : ''
          }
        </Grid>
        {employees.totalPages > 1 && (
          <Grid
            item
            container
            xs={12}
            spacing={3}
            justifyContent='flex-end'
            alignItems={'center'}
          >
            <Grid item>
              <Button
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
                variant='text'
              >
                {lan.Previous}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  page === employees.totalPages || employees.totalPages === 0
                }
                onClick={() => setPage(page + 1)}
                variant='text'
              >
                {lan.Next}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ManageEmployees;
