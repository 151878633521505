import { toast } from 'react-toastify';
import { createsign } from 'api/sign';
import { getsign } from 'api/sign';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export const createSign = async (formdata) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await createsign(formdata);
    toast.success(lan.Signature_created);
    return data.sign;
  } catch (error) {
    console.log(error);
    // toast.error('Something Went Wrong');
  }
};

export const getSign = async () => {
  try {
    const { data } = await getsign();
    return data.sign;
  } catch (error) {
    console.log(error);
  }
};
