import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  // Legend,
  ResponsiveContainer,
} from 'recharts';

const GroupedBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart width={'100%'} height={'100%'} data={data}>
        <CartesianGrid vertical={false} strokeDasharray='0' />
        <XAxis dataKey='name' />
        <YAxis width={25} tickLine={false} />
        <Bar dataKey='count' fill='#35974A' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GroupedBarChart;
