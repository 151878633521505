import { 
  // Avatar, 
  Grid, Typography, Box, useTheme } from '@mui/material';
import React from 'react';
import logo from 'assets/new_logo.png';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { Edit } from '@mui/icons-material';

const Sidebar = ({ children }) => {
  const file = useSelector((state) => state.file);

  const theme = useTheme();
  return (
    <Box
      height={'100vh'}
      sx={{
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          px: 3,
          py: 1,  
          borderTopRightRadius: '10px',
          boxSizing: 'border-box',
          display: 'flex',
          height: '100px',
          alignItems: 'center'
        }}
        justifyContent='center'
      >
        <img src={logo} alt='logo' style={{ width: '120px' }} />
      </Grid>
      <Grid
        container
        alignItems={'center'}
        spacing={1}
        sx={{
          backgroundColor: theme.custom.primary,
          px: 2,
          py: 4,
          boxSizing: 'border-box',
          overflowY: 'hidden',
          textDecoration: 'none',
        }}
        // component={Link}
        // to='/dashboard/add-employee'
      >
        <Grid item sx={{ ml: 2 }} xs={8}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
            color='white'
            fontSize={15}
            fontWeight={600}
          >
            {file?.file_name || file?.file?.name}
          </Typography>
        </Grid>
      </Grid>
      <Box
        item
        overflow={'auto'}
        sx={{
          backgroundColor: theme.custom.primary,
          // borderBottomRightRadius: '10px',

          height: '100%',

          width: '100%',
        }}
        xs={12}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
