const nestedArrayToJson = (array = []) => {
    console.log('nestedArrayToJson in >>>', array)
    if (array.length === 0)
        return [];

    let json = [];
    array.forEach((nestedArray) => {
        if (nestedArray?.length > 0) {
            nestedArray.forEach((nestedObj) => {
                json.push(nestedObj);
            });
        }
    });

    return json;
};

export default nestedArrayToJson;
