import React, { useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Select
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'actions/auth';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const AddEmployee = () => {
  
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;
  
  const user = useSelector((state) => state.auth).user;
  let defaultValues = {
    name: user.name,
    email: user.email,
    language: user.language
  };

  const [language, setLanguage] = useState(user.language);
  const { register, handleSubmit, formState: { errors }, getValues } = useForm({
    defaultValues
  });

  const dispatch = useDispatch();

  const onSubmit = async (profileData) => {
    profileData.role = user.role;
    profileData.language = language;
    const data = await updateProfile(profileData);
    dispatch({ type: 'UPDATE_PROFILE', data: data.user });
  };

  const handleChange = (e) => {
    setLanguage(e.target.value);
    console.log('e', e.target.value, getValues('language'));
  }
  return (
    <Grid sx={{ p: 3 }}>
      <Typography color='primary' fontSize={25} fontWeight={600}>
        {localStorage.getItem('language') === 'english'
          ? 'My Profile'
          : 'Mon Profil'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate={true}>
        <Grid item container xs={12} sx={{ mt: 3 }} spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography> {lan.Name}*</Typography>
            <TextField
              fullWidth
              {...register('name', { required: lan.Required_field })}
              sx={{ py: 1 }}
              placeholder={lan.Enter_Your_Name}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <span className='text-red'>{message}</span>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ pb: 1 }}>{lan.Set_Language}*</Typography>
            <Select
              name='language'
              value={language}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value='english'>{lan.English}</MenuItem>
              <MenuItem value='canadian'>{lan.Canadian_French}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>{lan.Email_address}</Typography>
            <TextField
              {...register('email')}
              fullWidth
              disabled={true}
              sx={{ py: 1 }}
              placeholder={lan.Enter_your_Email}
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              variant='contained'
              color='primary'
              type="submit"
              sx={{ width: '20%', color: 'white' }}
            >
              {lan.Save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddEmployee;
