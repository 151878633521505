import { Avatar, Grid, Typography, Box, useTheme } from '@mui/material';
import React from 'react';
import logo from 'assets/new_logo.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { 
  // Link, 
  useNavigate 
} from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = ({ children }) => {
  const user = useSelector((state) => state.auth).user;
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box display='flex' style={{ overflowY: 'hidden' }}>
      <Box
        height={'100vh'}
        sx={{
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            borderTopRightRadius: '10px',
            boxSizing: 'border-box',
            height: '125px',
            alignItems: 'center'
          }}
          justifyContent='center'
        >
          <img
            style={{ cursor: 'pointer', width: '120px' }}
            onClick={() =>
              localStorage.getItem('role') === 'admin'
                ? navigate('/admin/')
                : localStorage.getItem('role') === 'employee'
                  ? navigate('/dashboard/')
                  : navigate('/dashboard/documents')
            }
            src={logo}
            alt='logo'
            loading='lazy'
          />
        </Grid>
        <Grid
          container
          alignItems={'center'}
          spacing={1}
          sx={{
            backgroundColor: theme.custom.primary,
            px: 2,
            py: 4,
            mt: 0,
            boxSizing: 'border-box',
            overflowY: 'hidden',
            textDecoration: 'none',
            ...(user.role === 'employee'
              ? { cursor: 'pointer' }
              : { cursor: 'pointer' }),
          }}
          onClick={() =>
            user.role === 'employee' || user.role === 'business'
              ? navigate('/dashboard/update-profile')
              : navigate('/admin/update-profile')
          }
        // component={Link}
        // to='/dashboard/add-employee'
        >
          {user.role !== 'admin' && (
            <Grid item xs={2}>
              <Avatar
                sx={{ width: 50, height: 50 }}
                src={user.role !== 'admin' ? user.logo : null}
                alt=''
              />
            </Grid>
          )}
          <Grid
            item
            sx={{ ml: 2 }}
            xs={user.role !== 'admin' ? 8 : 10}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <Typography color='white' fontSize={20} fontWeight={600}>
              {user?.role === 'admin'
                ? user?.name
                : user?.role === 'employee'
                  ? user.first_name + ' ' + user.last_name
                  : user?.owner_info?.name}
            </Typography>
            {/* <Typography color='white' fontSize={14} fontWeight={400}>
              {user.role === 'admin'
                ? user?.email
                : user.role === 'employee'
                  ? user.business.name
                  : user.name}
            </Typography> */}
            <Typography color='white' fontSize={14} fontWeight={400}>
              {user.role === 'admin'
                ? user?.email
                : user.role === 'business'
                  ? user.email
                  : user.email}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ChevronRightIcon sx={{ color: 'white', fontSize: 30 }} />
          </Grid>
        </Grid>
        <Box
          item
          sx={{
            backgroundColor: theme.custom.primary,
            // borderBottomRightRadius: '10px',

            height: '100%',

            width: '100%',
          }}
          xs={12}
        >
          {children}
        </Box>
      </Box>
    </Box >
  );
};

export default Sidebar;
