import * as React from "react";

import {
  CssBaseline,
  Box,
  Container,
  Typography,
} from "@mui/material";

export default function PrivacyPolicy() {

  return (
    <Container component="main" sx={{ backgroundColor: "white" }}>
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Typography
          component="h1"
          color="primary"
          fontWeight={600}
          fontSize={40}
        >
          Privacy Policy
        </Typography>
        <Box pt={1}>
          Last updated: 06/09/2023
        </Box>
        <Box pt={1}>
          [Tango Nordik Solutions] ("us", "we", or "our") operates [JumpSIGN] (the "App"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the App.
        </Box>
        <Box pt={2} fontWeight={600}>
          Information Collection and Use
        </Box>
        <Box pt={1}>
          While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address, and phone number ("Personal Information").
        </Box>
        <Box pt={2} fontWeight={600}>
          Log Data
        </Box>
        <Box pt={1}>
          We may also collect information that your device sends whenever you use our App ("Log Data"). This Log Data may include information such as your device's Internet Protocol ("IP") address, device name, operating system version, the configuration of the App when utilizing our App, the time and date of your use of the App, and other statistics.
        </Box>
        <Box pt={2} fontWeight={600}>
          Cookies
        </Box>
        <Box pt={1}>
          Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your device's internal memory. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our App.
        </Box>
        <Box pt={2} fontWeight={600}>
          Security
        </Box>
        <Box pt={1}>
          The security of your Personal Information is important to us but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </Box>
        <Box pt={2} fontWeight={600}>
          Changes to This Privacy Policy
        </Box>
        <Box pt={1}>
          This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
        </Box>
        <Box pt={1}>
          We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the App after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
        </Box>
        <Box pt={1}>
          If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us or by placing a prominent notice on our website.
        </Box>
        <Box pt={2} fontWeight={600}>
          Contact Us
        </Box>
        <Box pt={1}>
          If you have any questions about this Privacy Policy, please contact us at support@tangodistribution.com
        </Box>
        <Box pt={1}>
          ---
        </Box>
        <Box pt={1} pb={4}>
          Veuillez noter que ce modèle de politique de confidentialité est un exemple général et peut nécessiter des ajustements pour répondre aux besoins spécifiques de votre application. Vous devriez consulter un professionnel du droit ou un avocat spécialisé dans la protection des données pour vous assurer que votre politique de confidentialité est en conformité avec les lois et réglementations applicables à votre juridiction et à votre type d'application.
        </Box>
      </Box>
    </Container>
  );
}
