import { Box, Typography, Grid, Button } from '@mui/material';
import React, { useState } from 'react';

const Review = ({ image }) => {
  const [page, setPage] = useState(0);
  //   const image = convaRef.current.toDataURL('image/png', 1);
  console.log(image);
  return (
    <Box
      sx={{
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <Typography color='primary' sx={{ mb: 5 }} fontSize={25} fontWeight={600}>
        Preview PDF
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={image[page]} alt="img"/>
      </Box>
      <Grid
        item
        container
        xs={12}
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item variant='text'>
          <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
            Previous
          </Button>
        </Grid>
        <Grid item variant='text'>
          <Typography color='primary'>{page + 1}</Typography>
        </Grid>
        <Grid item variant='text'>
          <Button
            disabled={page === image.length - 1 || image.length === 0}
            onClick={() => setPage(page + 1)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Review;
