import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import Entry from 'components/dashboard/document';
import serialize from 'utils/serialize';
import { getDocumentByBusiness } from 'actions/document';
import { useSelector } from 'react-redux';
import { getDocument } from 'actions/document';
import Loader from 'components/misc/Loader';
import refresh from 'assets/misc/refresh.png';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Document = () => {
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState({
    results: [],
    page: 0,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
  });

  const [isReload, setIsReload] = useState(false);

  const [page, setPage] = useState(1);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });
  const user = useSelector((state) => state.auth).user;
  useEffect(() => {
    getData();
  }, [setDocument, page, user, isReload]);

  const getData = async () => {
    setLoading(true);
    if (localStorage.getItem('role') === 'business') {
      const data = await getDocumentByBusiness(
        user._id,
        serialize({ page, limit: 5 }),
      );
      if (data) setDocument(data);
    } else {
      const data = await getDocument(
        serialize({ page, limit: 5, sortBy: '-updatedAt' }),
      );
      setDocument(data);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Box sx={{ px: 3 }}>
      <Grid item xs={12} sx={{
        py: 4.5,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography color='primary' fontSize={25} fontWeight={600}>
          {lan.Documents}
        </Typography>
        <Button
          title={lan.Refresh_Documents}
          variant='contained'
          color='primary'
          onClick={() => { getData() }}
          sx={{
            color: 'white',
            mr: 1
          }}
        >
          <img src={refresh} alt={lan.Reload} />
        </Button>
      </Grid>
      <Grid container item xs={12} sx={{
        border: '1px solid #e3e3e3',
        borderRadius: '20px',
        display: 'flex',
        px: 3,
        py: 2,
      }}>
        <Grid item xs={4}>
          {lan.Name}
        </Grid>
        <Grid item xs={3}>
          {lan.Owner}
        </Grid>
        <Grid item xs={2}>
          {lan.Status}
        </Grid>
        <Grid item xs={2}>
          {lan.Updated_at}
        </Grid>
        <Grid item textAlign={'right'} xs={1}>
        {
          (JSON.parse(localStorage.getItem('profile')).role === 'business') ? <></> : (
            <>
              {lan.Action}
            </>
          )
        }
        </Grid>
      </Grid>
      <Box sx={{ height: '70vh', overflowY: 'scroll' }}>
        {document?.results?.map((obj) => {
          return <Entry key={obj._id} data={obj} setIsReload={setIsReload} />;
        })}
        {
          document?.totalResults === 0 ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '50vh',
              alignItems: 'center'
            }}>
              {lan.No_Document_Added}
            </Box>
          ) : ''
        }
      </Box>
      {document.totalPages > 1 && (
        <Grid
          item
          container
          xs={12}
          spacing={3}
          justifyContent='flex-end'
          alignItems={'center'}
        >
          <Grid item>
            <Button
              disabled={Boolean(page === 1)}
              onClick={() => setPage(page - 1)}
              variant='text'
            >
              {lan.Previous}
            </Button>
          </Grid>
          <Grid item>
            <Typography color='primary'>{page}</Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={
                Boolean(page === document.totalPages || document.totalPages === 0)
              }
              onClick={() => setPage(page + 1)}
              variant='text'
            >
              {lan.Next}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Document;
