import { 
  Grid, 
  Box, 
  // Typography, 
  // Button 
} from '@mui/material';
import React, { 
  // useState, 
  useRef, 
  // useEffect
 } from 'react';
import DrawSignature from 'components/dashboard/draw-signature';
// import { useTheme } from '@emotion/react';
// import { createSign } from 'actions/sign';
// import { getSign } from 'actions/sign';
// import baseurl from 'api/baseurl';
const Signature = ({
  getSignatureURL,
  savedSignature
}) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  // const [color, setcolor] = useState('black');
  // const theme = useTheme();
  // const colors = ['black', 'red', '#000EFF', '#00FF19'];
  // const [signatures, setSignatures] = useState([]);
  // const saveSignature = async () => {
  //   await Promise.all(
  //     canvasRef.current.toBlob(async (blob) => {
  //       const formdata = new FormData();
  //       formdata.append('sign', blob, 'signature.jpeg');
  //       await createSign(formdata);
  //       setSignatures(await getSign());
  //       const context = canvasRef.current.getContext('2d');
  //       context.clearRect(
  //         0,
  //         0,
  //         canvasRef.current.width,
  //         canvasRef.current.height,
  //       );
  //     }),
  //   );
  // };

  // useEffect(() => {
  //   const getData = async () => setSignatures(await getSign());
  //   getData();
  // }, [setSignatures]);

  const onImgChange = (e) => {
    console.log('>>> IN SIGNATURE IMG e >>>>>', e);
    getSignatureURL({ img: e.img })
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Grid item container xs={12}>
        {/* <Grid item xs={12}>
          <Typography color='primary' fontSize={25} fontWeight={600}>
            {localStorage.getItem('language') === 'english'
              ? 'Draw your Signature'
              : 'Designer une signature'}
          </Typography>
        </Grid> */}
        <Grid item container xs={12}>
          <Grid
            item
            xs={12}
            md={12}
          >
            <DrawSignature
              canvasRef={canvasRef}
              contextRef={contextRef}
              getImgURL={onImgChange}
              savedSignature={savedSignature}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={4}
            sx={{
              ml: 4,
              backgroundColor: 'white',
              borderRadius: '10px',
              height: '250px',
              boxShadow: '4px 4px 25px 2px #00000040',
              p: 3,
              overflowY: 'scroll',
            }}
          >
            <Typography fontSize={20} fontWeight={500}>
              {localStorage.getItem('language') === 'english'
                ? 'Saved Signatures'
                : 'Sauvegarder les signatures'}
            </Typography>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {signatures.map((obj) => {
                return (
                  <Box
                    key={obj._id}
                    sx={{
                      mt: 1,
                      border: '1px solid #E3E3E3',
                      borderRadius: '20px',
                      height: '40px',
                      width: '40%',
                    }}
                  >
                    <img
                      src={obj.sign}
                      style={{
                        height: '50px',
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      alt='sign'
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid> */}
          {/* <Grid item xs={8} sx={{ mt: 1 }} spacing={3} container>
            <Grid item container xs={5}>
              <Box
                display='flex'
                alignItems='center'
                width='100%'
                justifyContent='space-between'
              >
                <Typography sx={{ mr: 3 }} fontSize={20} fontWeight={500}>
                  Color
                </Typography>
                {colors.map((item) => {
                  return (
                    <Box
                      key={item}
                      onClick={() => setcolor(item)}
                      sx={{
                        ...(color === item
                          ? { border: `4px solid ${theme.custom.highlight}` }
                          : {}),
                        cursor: 'pointer',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: item,
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={saveSignature}
                fullWidth
                size='large'
                sx={{ color: 'white' }}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signature;
