import React, { useRef, useState } from 'react';
import {
  Box,
  Drawer,
  useMediaQuery,
  IconButton,
  TextField,
  Grid,
  Typography,
  Modal,
  MenuItem,
  Button,
  Select,
} from '@mui/material';
import edit from 'assets/document-sidebar-icons/edit.svg';
// import signature from 'assets/document-sidebar-icons/sign.svg';
// import document from 'assets/document-sidebar-icons/document.svg';
import save from 'assets/document-sidebar-icons/save.svg';
// import share from 'assets/document-sidebar-icons/share.svg';
import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router';
import Sidebar from 'components/document-sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from 'sidebar-tabs/consumer sign';
// import { useSelector } from 'react-redux';
import Signature from 'pages/Consumer Sign/Signature';
import JsPDF from 'jspdf';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Home from 'pages/Consumer Sign/Home';
import nestedArrayToJson from 'utils/nestedArrayToJson';
import jsonToNestedArray from 'utils/jsonToNestedArray';

import Loader from 'components/misc/Loader';

import PdfPreview from '../pages/Document/pdfPreview';

import Review from 'pages/Consumer Sign/Review';
import axios from 'axios';
import baseurl from 'api/baseurl';
import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const drawerWidth = 300;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function DashboardRoutes() {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { token, id } = useParams();
  const generatedPages = [];
  const convaRef = useRef();
  const [urls, setURLS] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [sendTo, setSendTo] = useState([]);
  const small = useMediaQuery('(max-width:900px)');
  const [employees, setEmployees] = useState([]);
  const [images, setImages] = React.useState([]);
  const [arrayImages, setArrayImages] = useState([]);
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState('');
  const [email, setEmail] = useState('');
  const [copy, setCopy] = useState(false);
  const [attribute_array, set_attribute_array] = useState([]);
  const [original_attribute_array, set_original_attribute_array] = useState([]);
  const [pdfName, setPdfName] = useState('');
  const [loading, setLoading] = useState(false);

  const [isPreviewModal, setPreviewModal] = React.useState(false);
  const [createDocumentData, setCreateDocumentData] = React.useState({});

  const updateCurrentPageImage = () => {
    setURLS((state) => {
      if (convaRef.current) state[page] = convaRef.current.toDataURL(null, 1);
      return state;
    });
  };
  const savePDf = () => {
    updateCurrentPageImage();
    setCopy((state) => !state);
  };

  const copyPDf = () => {
    setCopy((state) => !state);
  };

  const previewPDF = () => {
    updateCurrentPageImage();
    navigate(`/sign-consumer/${id}/${token}/review`);
  };

  const print = () => {
    updateCurrentPageImage();
    const pdf = new JsPDF('p', 'mm', 'a4');
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();

    urls.map((image, index) => {
      pdf.addImage(image, 'PNG', 0, 0, width, height, `${index}`, 'NONE');
      if (index !== urls.length - 1) {
        pdf.addPage();
      }
    });
    window.open(pdf.output('bloburl', 'document.pdf'), '_blank');
  };

  const navigate = useNavigate();

  const saveDocument = () => {
    console.log('saveDocument >>>>>', attribute_array);
    setLoading(true);
    let attributeArray = nestedArrayToJson(attribute_array || []);
    if (attributeArray) {
      let index = 0;
      attributeArray.forEach(element => {
        if (!element.src) {
          index = 1;
        }
      });
      if (index === 1) {
        toast.warning(lan.You_have_required_field_to_fill_out);
        setLoading(false);
        return;
      }
    }
    console.log('attributeArray >>>>>', {
      document_id: id,
      document_name: pdfName,
      image: urls,
      attribute_array: attributeArray || [],
      copy
    });

    axios
      .put(
        baseurl + '/document/update',
        {
          document_id: id,
          document_name: pdfName,
          image: urls,
          attribute_array: attributeArray || [],
          copy
        },
        {
          headers: { Authorization: 'Bearer ' + token },
        },
      )
      .then((res) => {
        // toast.success('Document Submitted');
        // localStorage.clear();
        // navigate('/login');
        // setLoading(false);
        const data = res.data.document;
        if (data.status === 2) {
          setCreateDocumentData(data);
          // setLoading(false);
          // localStorage.clear();
          setPreviewModal(true);
        } else {
          localStorage.clear();
          setLoading(false);
          toast.success(lan.Document_Updated_Sucessfully);
          navigate('/login');
        }
      });
  };

  return (
    <Routes>
      <Route
        path='*'
        element={
          true ? (
            <Box
              sx={{
                display: 'flex',
                ...(small ? { flexDirection: 'column' } : {}),
                backgroundColor: '#F5F5F5',
              }}
            >
              <Drawer
                open={expanded}
                onClose={() => setExpanded(false)}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant={small ? 'temporary' : 'permanent'}
                anchor='left'
              >
                {expiry ? (
                  <Box sx={{ p: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label='Expiry date and time'
                        // value={expiryDate}
                        onChange={(e) => setExpiryDate(e.$d)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ backgroundColor: 'white' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <></>
                )}
                <Sidebar>
                  <Dashboard
                    setExpanded={setExpanded}
                    downloadPDF={savePDf}
                    setExpiry={setExpiry}
                    print={print}
                    tabs={[
                      {
                        path: `/sign-consumer/${id}/${token}/`,
                        name: lan.Edit_Document,
                        icon: edit,
                      },
                      // {
                      //   name: 'Draw Signature',
                      //   path: `/sign-consumer/${id}/${token}/signature`,
                      //   icon: signature,
                      // },
                      // { name: 'Review', onClick: previewPDF, icon: document },
                      {
                        copy: copy,
                        name: lan.Request_a_Document_Copy_by_email,
                        onClick: copyPDf,
                      },
                      {
                        name: lan.Save_Document,
                        onClick: saveDocument,
                        icon: save,
                      },
                      // { name: 'Print', onClick: print, icon: document },
                    ]}
                    setModal={setModal}
                    previewPDF={previewPDF}
                  />
                </Sidebar>
              </Drawer>
              {small && (
                <Box
                  component='nav'
                  display='flex'
                  alignItems={'flex-start'}
                  style={{ p: 3 }}
                >
                  <IconButton onClick={() => setExpanded(true)}>
                    <MenuIcon
                      color='primary'
                      style={{
                        fontSize: 30,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box
                component='main'
                sx={{
                  ml: 0.5,
                  width: '100%',
                  boxSizing: 'border-box',
                  backgroundColor: '#F5F5F5',

                }}
              >
                <Modal
                  open={modal}
                  onClose={() => {
                    setModal(false);
                    setMode('');
                  }}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={style}>
                    {mode === '' ? (
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={3}
                        justifyContent='center'
                      >
                        <Grid item container justifyContent={'center'} xs={12}>
                          <Button
                            onClick={() => setMode('email')}
                            fullWidth
                            color='primary'
                            variant='outlined'
                          >
                            Share via Email
                          </Button>
                        </Grid>
                        <Grid item justifyContent={'center'} container xs={12}>
                          <Button
                            onClick={() => setMode('employee')}
                            fullWidth
                            color='primary'
                            variant='outlined'
                          >
                            Share With Employees
                          </Button>
                        </Grid>
                        <Grid item justifyContent={'center'} container xs={12}>
                          <Button
                            onClick={async () =>
                              await saveDocument({ drive: true })
                            }
                            fullWidth
                            color='primary'
                            variant='outlined'
                          >
                            Save to Google Drive
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Typography
                          id='modal-modal-title'
                          variant='h6'
                          component='h2'
                        >
                          {mode === 'employee'
                            ? 'Select Recipients'
                            : 'Enter Email'}
                        </Typography>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent='center'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {mode === 'employee' ? (
                              <Select
                                fullWidth
                                multiple
                                value={sendTo}
                                onChange={(e) => {
                                  setSendTo(
                                    typeof e.target.value === 'string'
                                      ? e.target.value.split(',')
                                      : e.target.value,
                                  );
                                }}
                              >
                                {employees.map((obj) => {
                                  return (
                                    <MenuItem value={obj._id} key={obj._id}>
                                      {obj.first_name} {obj.last_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            ) : (
                              <>
                                <TextField
                                  variant='outlined'
                                  fullWidth
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent={'center'}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              sx={{ color: 'white' }}
                              onClick={saveDocument}
                            >
                              Send PDF
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Modal>
                <Routes>
                  <Route path='signature' element={<Signature />} />
                  <Route
                    path=''
                    element={
                      <Home
                        stageRef={convaRef}
                        images={images}
                        setImages={setImages}
                        canvasPages={generatedPages}
                        page={page}
                        id={id}
                        setPage={setPage}
                        setMultipleImages={setArrayImages}
                        multipleImages={arrayImages}
                        setUrl={setURLS}
                        token={token}
                        setAttributeArray={set_attribute_array}
                        setOriginalAttributeArray={set_original_attribute_array}
                        pdfName={setPdfName}
                      />
                    }
                  />
                  <Route path='/review' element={<Review image={urls} />} />
                </Routes>
              </Box>
              {
                loading ? (
                  <Box>
                    <Loader />
                  </Box>
                ) : ''
              }
              {
                isPreviewModal ? (
                  <PdfPreview
                    pdf_id={createDocumentData._id}
                    // isCitrix={creds?.citrix}
                    // isOnedrive={creds?.onedrive}
                    // isDrive={creds?.gdrive}
                    // isComplete={IsCompleted}
                    pdfName={createDocumentData.document_name}
                    pdfpages={urls}
                    attributeArray={jsonToNestedArray(createDocumentData.attribute_array)}
                    close={() => {
                      toast.success(lan.Document_Updated_Sucessfully);
                      setPreviewModal(false);
                      setLoading(false);
                      localStorage.clear();
                      navigate('/login');
                    }}
                    isAutoShare={1}
                  // isReload={() => { setIsReload(true) }}
                  />
                ) : ''
              }
            </Box>
          ) : (
            <Navigate to='/dashboard/' replace={true} />
          )
        }
      />
    </Routes>
  );
}
