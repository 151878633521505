import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  Chip,
  DialogTitle,
  Avatar,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import deleteSvg from 'assets/misc/delete.svg';
import DoneIcon from '@mui/icons-material/Done';
import { updateBusiness } from 'actions/business';
import { useNavigate, useParams } from 'react-router';
import { getBusinessByID } from 'actions/business';
// import baseurl from 'api/baseurl';
import MuiPhoneNumber from 'mui-phone-number';
import { useSelector } from 'react-redux';
import { deleteBusiness } from 'actions/business';
import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const EditBusiness = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;
  
  const [loading, setLoading] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [deleteEmployee, setdeleteEmployee] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.auth).user;
  const ref = useRef();
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    language: '',
    ip_address: [''],
    owner_info: {
      name: '',
      email: '',
      phone: '',
    },
  });
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };
  const handleOwner = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      owner_info: { ...state.owner_info, [name]: value },
    }));
  };

  useEffect(() => {
    const getData = async () => {
      let data;
      if (localStorage.getItem('role') === 'business') {
        console.log(user);
        data = await getBusinessByID(user._id);
      } else {
        data = await getBusinessByID(id);
      }
      setState(data);
      setImageURL(data.logo);
    };
    getData();
  }, [setState, setImageURL, id, user]);
  const onSubmit = async () => {
    setLoading(true);
    const formdata = new FormData();
    Object.keys(state).map((obj) => {
      return formdata.append(
        obj,
        typeof state[obj] === 'object'
          ? JSON.stringify(state[obj])
          : state[obj],
      );
    });
    if (image) formdata.append('logo', image);
    await updateBusiness(
      localStorage.getItem('role') === 'admin' ? id : user._id,
      formdata,
      navigate,
    );
    setLoading(false);
    toast.success(lan.Business_Updated_Sucessfully);
  };

  const suspendAccount = async () => {
    const formdata = new FormData();
    Object.keys(state).map((obj) => {
      if (obj === 'active') {
        return formdata.append(obj, !state[obj]);
      }
      return formdata.append(
        obj,
        typeof state[obj] === 'object'
          ? JSON.stringify(state[obj])
          : state[obj],
      );
    });
    if (image) formdata.append('logo', image);
    await updateBusiness(
      localStorage.getItem('role') === 'admin' ? id : user._id,
      formdata,
      navigate,
    );
  };
  const theme = useTheme();
  const employeeDelete = async () => {
    await deleteBusiness(state._id, navigate);
  };
  return (
    <>
      <Dialog
        open={deleteEmployee}
        onClose={() => setdeleteEmployee(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Are your sure you want to delete this business
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            The employees and the documents created by them will also be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdeleteEmployee(false)}>No</Button>
          <Button
            onClick={async () => {
              await employeeDelete();
              setdeleteEmployee(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={suspend}
        onClose={() => setSuspend(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {`${state.active ? '' : 'Un'}Suspend this business account?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {state.active
              ? "This will block them for accessing the portal's services"
              : 'This will give back the access for the portal'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuspend(false)}>No</Button>
          <Button
            onClick={async () => {
              await suspendAccount();
              setSuspend(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item sx={{ px: 3, py: 2 }} xs={12}>
          <Typography color='primary' fontSize={25} fontWeight={600}>
            Business Profile
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ mt: 3, height: '85vh', overflow: 'scroll', px: 3, pb: 3 }}
          spacing={3}
        >
          <Grid container item xs={12} spacing={2} alignItems='center'>
            <input
              type='file'
              accept='image/jpg, image/png'
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImageURL(URL.createObjectURL(e.target.files[0]));
                e.target.value = '';
              }}
              style={{ display: 'none' }}
              ref={ref}
            />
            <Grid item>
              <IconButton
                sx={{ width: 120, height: 120 }}
                onClick={() => ref.current.click()}
              >
                <Avatar
                  src={imageURL}
                  style={{ width: 120, height: 120, objectFit: 'cover' }}
                  crossOrigin='anonymous'
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography color='primary'>(upto 10mb)</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Business Name*</Typography>
            <TextField
              name='name'
              value={state.name}
              onChange={handleChange}
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Name'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Business Email</Typography>
            <TextField
              value={state.email}
              fullWidth
              sx={{ py: 1 }}
              name='email'
              onChange={handleChange}
              placeholder='Enter your Email'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Business Mobile Number*</Typography>
            <MuiPhoneNumber
              disableAreaCodes
              required
              variant='outlined'
              defaultCountry='ca'
              diabled={true}
              value={state.phone}
              name='phone'
              onChange={(e) =>
                handleChange({ target: { value: e, name: 'phone' } })
              }
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Phone'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography> Address*</Typography>
            <TextField
              fullWidth
              value={state.address}
              name='address'
              onChange={handleChange}
              sx={{ py: 1 }}
              placeholder='Enter your Address '
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Set Language</Typography>

            <TextField
              fullWidth
              name='language'
              onChange={handleChange}
              value={state.language}
              sx={{ py: 1 }}
              select
            >
              <MenuItem value='english'>English</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12} container>
            <Grid
              item
              xs={12}
              // justifyContent='space-between'
              alignItems='center'
              container
            >
              <Grid item xs={5.7}>
                <Typography>IP Address</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={(theme) => ({
                    backgroundColor: theme.custom.primary,
                    color: 'white',
                    height: 25,
                    width: 25,
                    '&:hover': {
                      backgroundColor: theme.custom.secondary,
                      color: 'white',
                    },
                  })}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      ip_address: [...state.ip_address, ''],
                    }));
                  }}
                >
                  <Add fontSize={'15'} />
                </IconButton>
              </Grid>
            </Grid>
            {state.ip_address.map((obj, index) => {
              return (
                <Grid
                  item
                  container
                  alignItems={'center'}
                  spacing={1}
                  key={index}
                  xs={12}
                  md={6}
                >
                  <Grid item xs={11}>
                    <TextField
                      required
                      fullWidth
                      name='ip_address'
                      onChange={(e) => {
                        setState((state) => {
                          state.ip_address[index] = e.target.value;
                          return state;
                        });
                      }}
                      value={obj}
                      InputProps={{
                        style: {
                          color: theme.custom.primary,
                          fontWeight: 700,
                        },
                      }}
                      sx={{ py: 1 }}
                      placeholder='Enter your IP Address'
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        setState((state) => ({
                          ...state,
                          ip_address: state.ip_address.filter(
                            (item, i) => i !== index,
                          ),
                        }));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12}>
            <Typography color='primary' sx={{ borderBottom: '1px solid' }} fontSize={25} fontWeight={600}>
              Owner Info
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Name*</Typography>
            <TextField
              fullWidth
              sx={{ py: 1 }}
              name='name'
              value={state.owner_info.name}
              onChange={handleOwner}
              placeholder='Enter your Name'
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography>Email*</Typography>
            <TextField
              fullWidth
              sx={{ py: 1 }}
              value={state.owner_info.email}
              name='email'
              onChange={handleOwner}
              placeholder='Enter your Email'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Mobile No*</Typography>
            <MuiPhoneNumber
              disableAreaCodes
              required
              variant='outlined'
              disabled={localStorage.getItem('role') === 'business'}
              value={state.owner_info.phone}
              defaultCountry='ca'
              name='phone'
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Phone'
            />
          </Grid>
          {localStorage.getItem('role') === 'admin' && (
            <Grid item container xs={12} spacing={1}>
              <Grid item>
                <Chip
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setSuspend(true)}
                  icon={
                    state.active ? (
                      <img src={deleteSvg} alt="delete"/>
                    ) : (
                      <DoneIcon style={{ color: theme.custom.primary }} />
                    )
                  }
                  variant='outlined'
                  label={
                    state.active ? 'Suspend Business' : 'Unsuspend Business'
                  }
                />
              </Grid>
              <Grid item>
                <Chip
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setdeleteEmployee(true)}
                  icon={<img src={deleteSvg} alt="delete"/>}
                  variant='outlined'
                  label='Block Business'
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              disabled={loading}
              variant='contained'
              color='primary'
              onClick={onSubmit}
              sx={{ padding: '5px 50px 5px 50px', color: 'white' }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditBusiness;
