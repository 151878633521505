import React, { useState } from 'react';
import { Box, Drawer, useMediaQuery, IconButton } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router';
import Sidebar from 'components/sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import AddEmployee from 'pages/Common/AddEmployee';
import ManageEmployees from 'pages/Common/ManageEmployeeAll';
import Home from 'pages/Dashboard/Home';
import Dashboard from 'sidebar-tabs/home';
import Document from 'pages/Dashboard/Document';
import Setting from 'pages/Dashboard/Setting';
import EditEmployee from 'pages/Common/EditEmployee';
import UpdateProfile from 'pages/Dashboard/EditEmployee';
// import { useSelector } from 'react-redux';
// import EditBusiness from 'pages/Admin/EditBusiness';
import AddBusiness from 'pages/Admin/AddBusiness';

const drawerWidth = 300;

export default function DashboardRoutes() {
  const [expanded, setExpanded] = useState(false);
  const small = useMediaQuery('(max-width:900px)');
  // const user = useSelector((state) => state.auth)?.user;

  return (
    <Routes>
      <Route
        path='*'
        element={
          localStorage.getItem('role') === 'employee' ||
            localStorage.getItem('role') === 'business' ? (
            <Box
              sx={{
                display: 'flex',
                ...(small ? { flexDirection: 'column' } : {}),
              }}
            >
              <Drawer
                open={expanded}
                onClose={() => setExpanded(false)}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant={small ? 'temporary' : 'permanent'}
                anchor='left'
              >
                <Sidebar>
                  <Dashboard setExpanded={setExpanded} />
                </Sidebar>
              </Drawer>
              {small && (
                <Box
                  component='nav'
                  display='flex'
                  alignItems={'flex-start'}
                  style={{ p: 3 }}
                >
                  <IconButton onClick={() => setExpanded(true)}>
                    <MenuIcon
                      color='primary'
                      style={{
                        fontSize: 30,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box
                component='main'
                sx={{
                  ml: 0.5,

                  width: '100%',
                  boxSizing: 'border-box',
                }}
                style={{ backgroundColor: 'white' }}
              >
                <Routes>
                  <Route
                    path='/:id'
                    element={
                      localStorage.getItem('role') === 'employee' ? (
                        <Home />
                      ) : (
                        <Navigate to='/dashboard/documents' replace='true' />
                      )
                    }
                  />
                  <Route
                    path=''
                    element={
                      localStorage.getItem('role') === 'employee' ? (
                        <Home />
                      ) : (
                        <Navigate to='/dashboard/documents' replace='true' />
                      )
                    }
                  />
                  <Route
                    path='add-employee/:id'
                    element={
                      localStorage.getItem('role') === 'business' ? (
                        <AddEmployee />
                      ) : (
                        <Navigate to='/dashboard/' replace={true} />
                      )
                    }
                  />
                  <Route
                    path='edit-employee/:id'
                    element={
                      localStorage.getItem('role') === 'business' ? (
                        <EditEmployee />
                      ) : (
                        <Navigate to='/dashboard/' replace={true} />
                      )
                    }
                  />
                  <Route
                    path='add-employee'
                    element={
                      localStorage.getItem('role') === 'business' ? (
                        <AddEmployee />
                      ) : (
                        <Navigate to='/dashboard/' replace={true} />
                      )
                    }
                  />
                  <Route
                    path='manage-employee'
                    element={
                      localStorage.getItem('role') === 'business' ? (
                        <ManageEmployees />
                      ) : (
                        <Navigate to='/dashboard/' replace={true} />
                      )
                    }
                  />
                  <Route path='settings' element={<Setting />} />
                  <Route path='documents' element={<Document />} />
                  <Route
                    path='update-profile'
                    element={
                      localStorage.getItem('role') === 'employee' ? (
                        <UpdateProfile />
                      ) : (
                        <AddBusiness />
                      )
                    }
                  />
                </Routes>
              </Box>
            </Box>
          ) : (
            <Navigate to='/login' replace={true} />
          )
        }
      />
    </Routes>
  );
}
