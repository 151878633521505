import React, { useState, useRef } from 'react';
import { 
  Stage, 
  Layer, 
  Image, 
  // Transformer, 
  // Circle, 
  Rect, 
  // div, 
  Label, 
  Tag, 
  Text 
} from 'react-konva';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  // useMediaQuery,
  Modal,
  Tabs,
  Tab,
  // useTheme,
  MenuItem,
} from '@mui/material';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import { createSign } from 'actions/sign';
// import { getEmployee } from 'actions/employee';

import useImage from 'use-image';
// import baseurl from 'api/baseurl';
import Signature from './Signature'
import moment from 'moment';
// import signature from 'assets/document-sidebar-icons/sign.svg';
// import calendar from 'assets/document-sidebar-icons/calendar.svg';
// import document from 'assets/document-sidebar-icons/document.svg';
import { useSelector } from 'react-redux';
// import serialize from 'utils/serialize';
// import axios from 'axios';
// import { fontSize } from '@mui/system';

import complete from 'assets/misc/complete.png';
import pending from 'assets/misc/pending.png';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const URLImage = ({
  image,
  shapeProps,
  unSelectShape,
  onMouseOver,
  onMouseLeave,
  isSelected,
  onSelect,
  onChange,
  stageScale,
  onDelete,
  transformerData
}) => {
  const shapeRef = React.useRef();
  // const trRef = React.useRef();
  // const deleteButton = React.useRef();
  // console.log('image>>>>>>>', image);
  const [img] = useImage(image.src || 'Anonymous');
  // React.useEffect(() => {
  //   if (isSelected) {
  //     //  we need to attach transformer manually
  //     trRef.current.nodes([shapeRef.current]);
  //     trRef.current.getLayer().batchDraw();
  //   }
  // }, [isSelected]);

  const onMouseEnter = (event) => {
    console.log('onMouseEnter >>>', event, image)
    let emailObj = JSON.parse(localStorage.getItem('profile'))
    if (image.inviteEmail === emailObj.email) {
      event.target.getStage().container().style.cursor = 'pointer';
    } else {
      event.target.getStage().container().style.cursor = 'not-allowed';
    }
  };

  // const onMouseLeave = (event) => {
  //   // console.log('onMouseLeave >>>', event, event.currentTarget, event.currentTarget.parent.children[1], event.currentTarget.children);
  //   event.target.getStage().container().style.cursor = 'default';
  //   // const dummy = event.currentTarget.parent.children[1].getContext("2d")
  //   // dummy.fillRect(0, 0, 50, 50);
  // };

  // const handleDelete = () => {
  //   console.log('handleDelete >>>>>>>>>', shapeRef)
  //   unSelectShape(null);
  //   onDelete(shapeRef.current);
  // };

  return (
    <React.Fragment>
      {
        image.src ? (
          <Image
            image={img}
            x={(img && (img.width < image.width)) ? image.x : (image.x + 5)}
            y={(img && (img.height < image.height)) ? (image.y) : (image.y + 5)}
            width={(img && (img.width < image.width)) ? ((img.width)) : (image.width - 10)}
            height={(img && (img.height < image.height)) ? ((img.height)) : (image.height - 10)}
            align={"center"}
            verticalAlign={"middle"}
          />
        ) : (
          <Text
            text={image.label}
            fill={"black"}
            fontFamily={'arial'}
            fontSize={14}
            x={image.x}
            y={image.y}
            width={image.width}
            height={image.height}
            align={"center"}
            verticalAlign={"middle"}
          />
        )
      }
      <Rect
        x={image.x}
        y={image.y}
        id={image.id}
        name={image.id}
        width={image.width}
        height={image.height}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        fill={image.inviteEmailColour}
        strokeWidth={1} // border width
        stroke={image.inviteEmailBorderColour} // border color
        // shadowBlur={10}
        cornerRadius={10}
        // fillPatternImage={image.src}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={false}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          console.log('onTransformEnd >>>>', e, shapeRef);
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          // console.log('>>>>>>>>>>>>>>>>>>>>>', {
          //   ...shapeProps,
          //   x: node.x(),
          //   y: node.y(),
          //   // set minimal value
          //   width: Math.max(5, node.width() * scaleX),
          //   height: Math.max(node.height() * scaleY),
          // });
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {/* <Image
        image={img}
        x={image.x}
        y={image.y}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        // I will use offset to set origin to the center of the image
        offsetX={img ? img.width / 2 : 0}
        offsetY={img ? img.height / 2 : 0}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      /> */}
      {/* {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            console.log('Transformer >>>>', oldBox, newBox)

            transformerData()
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        >
          <Circle
            radius={8}
            fill='red'
            ref={deleteButton}
            onClick={handleDelete}
            x={shapeRef.current.width() * stageScale}
            sx={{
              current: 'pointer'
            }}
          ></Circle>
        </Transformer>
      )} */}
    </React.Fragment>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // console.log('TabPanel >>>>>', children, value, index, { ...other });
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DragandDrop = ({
  signatures,
  pdfpages,
  stageRef,
  images,
  setImages,
  page,
  setPage,
  setMultiple,
  multiple,
  setUrl,
  attributeArray,
  setAttributeArray
}) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  // console.log('LocalStorage images >>>>', pdfpages, page);
  const [img, status] = useImage(pdfpages[page] || 'Anonymous');
  // const [date, setDate] = useState(new Date());
  // const [text, setText] = useState('');
  // const [initialImage, setInitialTextImage] = useState('');
  // const [dateImage, setDateImage] = useState('');
  const [modal, setModal] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(-1);
  const [value, setValue] = React.useState(0);
  const [initials, setInitials] = React.useState({});
  // const theme = useTheme();
  const [employees, setEmployees] = useState([]);
  // const [inviteUser, setInviteUser] = useState('');
  // const [defaultColour, setDefaultColour] = useState({});
  const [inviteEmailColour, setInviteEmailColours] = useState('');
  const [inviteEmailBorderColour, setInviteEmailBorderColours] = useState('');
  // const [SelectedSignatureURL, setSelectedSignatureURL] = useState('');
  const [SaveInitial, setSaveInitial] = useState(false);
  const [Type, setType] = useState('');

  const [AllImagesStatus, setAllImagesStatus] = useState([]);
  const [PendingImagesStatus, setPendingImagesStatus] = useState({ count: 0 });

  const [DefaultInitialValues, setDefaultInitialValues] = useState({
    name: '',
    font_family: 'arial',
    date: ''
  });

  const initialRef = useRef(null);
  const dateRef = useRef(null);

  var user = useSelector((state) => state.auth)?.user;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createColor = () => {
    let colour = ('#' + Math.floor(Math.random() * 16777215).toString(16));
    let object = {
      bgColor: hexToRgbA(colour),
      borderColor: colour
    }
    setInviteEmailBorderColours(object.borderColor);
    setInviteEmailColours(object.bgColor);
    return object;
  }

  const hexToRgbA = (hex) => {
    // console.log('hexToRgbA >>>>>', hex)
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
    }
    createColor();
  }

  const handleChangeInitial = (event, newValue) => {
    console.log('event, newValue', event, event.target);
    // const { name, value } = e.target;
    // setState((state) => ({ ...state, [name]: value }));
    if (event.target && event.target.name === 'font_family') {
      initials.font_family = event.target.value;
      DefaultInitialValues.font_family = event.target.value;
      setDefaultInitialValues({ ...DefaultInitialValues })
      setInitials({ ...initials })
    } else if (event.target && event.target.name === 'name') {
      initials.initial = event.target.value;
      DefaultInitialValues.name = event.target.value;
      setDefaultInitialValues({ ...DefaultInitialValues })
      setInitials({ ...initials })
    } else {
      initials.date = moment(new Date(event)).format('MM/DD/YYYY');
      DefaultInitialValues.date = moment(new Date(event)).format('MM/DD/YYYY');
      setDefaultInitialValues({ ...DefaultInitialValues })
      setInitials({ ...initials })
    }
    console.log({ ...initials }, { ...DefaultInitialValues })
  };

  const saveInitials = () => {
    // console.log('(initials.initial.length * 15)', (initials.initial.length * 15), initialRef);
    const canvas = initialRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, (initials.initial.length * 30), 45);
    // context.clearRect(images[modalImageIndex].x, images[modalImageIndex].y, images[modalImageIndex].width, images[modalImageIndex].height);
    context.font = 'normal 60px ' + (initials.font_family || 'arial');
    context.fillText(initials.initial, 0, 45, ((initials.initial.length * 30)));
    // context.fillText(initials.initial, images[modalImageIndex].width, images[modalImageIndex].height);
    console.log(initialRef.current.toDataURL('image/png'))
    getSignature({
      img: initialRef.current.toDataURL('image/png')
    })
  }

  const saveDate = () => {
    console.log('(initials.initial.length * 15)', initials, dateRef);
    const canvas = dateRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, (initials.date.length * 10), 30);
    // context.clearRect(images[modalImageIndex].x, images[modalImageIndex].y, images[modalImageIndex].width, images[modalImageIndex].height);
    context.font = 'normal 20px arial';
    context.fillText(initials.date, 0, 25, ((initials.date.length * 10)));
    // context.fillText(initials.initial, images[modalImageIndex].width, images[modalImageIndex].height);
    console.log(dateRef.current.toDataURL('image/png'))
    getSignature({
      img: dateRef.current.toDataURL('image/png')
    })
  }

  const divRef = React.useRef(null);
  // const textRef = useRef(null);
  // const canvasRef = useRef(null);

  React.useEffect(() => {
    // if (attributeArray.length > 0)
    //   setImages(attributeArray)

    if (JSON.parse(localStorage.getItem('all_images'))) {
      getLocalImages();
      // setImages(JSON.parse(localStorage.getItem('images')));
      // setAttributeArray(JSON.parse(localStorage.getItem('images')));
      // user = JSON.parse(localStorage.getItem('profile'));
    }
    console.log('>>>>>>> setAttributeArray >>>>>', attributeArray, images, user, JSON.parse(localStorage.getItem('profile')));
    // const getData = async () => {
    //   setEmployees(
    //     await getEmployee(user.business._id, serialize({ page: 'all' }))
    //   );
    // };
    // getData();
  }, [page]);

  const getLocalImages = () => {
    console.log('getLocalImages >>>>');
    let localImages = JSON.parse(localStorage.getItem('all_images'));
    let images = [];

    if (!localImages) localImages = [];
    console.log('init getLocalImages localImages>>>>', localImages);
    if (localImages[page] && localImages[page].length > 0)
      localImages[page].forEach((img) => {
        images.push(img);
      });

    console.log('getLocalImages images >>>>', images)
    setImages(images);
    localStorage.setItem('images', JSON.stringify(images));
    setAttributeArray(JSON.parse(localStorage.getItem('all_images')));
    getPendingField();
  }

  const setLocalImages = (images) => {
    console.log('setLocalImages >>>>', images);
    localStorage.setItem('images', JSON.stringify(images));

    let localImages = JSON.parse(localStorage.getItem('all_images'));
    if (!localImages) localImages = [];
    localImages[page] = [];
    console.log('init setLocalImages localImages>>>>', localImages);
    images.forEach((img) => {
      if (!localImages[page]) localImages[page] = [];
      localImages[page].push(img);
    });
    console.log('setLocalImages images >>>>', localImages)
    localStorage.setItem('all_images', JSON.stringify(localImages));
    setAttributeArray(localImages);
    getPendingField();
  }
  React.useEffect(() => {
    let profile = JSON.parse(localStorage.getItem('profile'));
    employees.forEach((employee) => {
      let object = createColor();
      employee.inviteEmailColour = object.bgColor;
      employee.inviteEmailBorderColour = object.borderColor;
      if (profile.email === employee.email) {
        profile.inviteEmailColour = object.bgColor;
        profile.inviteEmailBorderColour = object.borderColor;
        localStorage.setItem('profile', JSON.stringify(profile))
      }
    });
  }, [employees, setEmployees]);

  // const dragUrl = React.useRef();
  // const stageRef = React.useRef();
  const [selectedId, selectShape] = React.useState(null);
  // const stage = stageRef.current?.getStage();
  const [stageSpec, setStageSpec] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const handleRemove = (index) => {
    console.log('handleRemove >>>>', index)

    const newList = images.filter((item) => item.index !== index);

    setImages(newList);
  };

  const checkDeselect = (e) => {
    console.log('checkDeselect >>>>', e.target, e.target.getStage())
    setNode({ x: -500, y: -500, text: '' });
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const unSelectShape = (prop) => {
    console.log('unSelectShape >>>>', prop)
    selectShape(prop);
  };

  const onDeleteImage = (node) => {
    console.log('onDeleteImage >>>>', node, (images) => images.filter((item) => item.id !== node.attrs.id));
    setImages((images) => images.filter((item) => item.id !== node.attrs.id));
  };

  const onMouseOver = (image) => {
    // console.log('>>>>>> onMouseOver', image)
    // var node = event.target;
    if (image) {
      // update tooltip
      // var mousePos = node.getStage().getPointerPosition();
      setNode({ x: (image.x + (image.width / 2)), y: (image.y), text: (image.inviteEmail || lan.Unassigned) });
    }
  }
  // const small = useMediaQuery('max-width:900px');

  const [node, setNode] = React.useState(null);
  const tooltip = () => {
    if (node === null) return null;
    return (
      <Label x={node.x} y={node.y}>
        <Tag
          fill={"white"}
          pointerDirection={"down"}
          pointerWidth={10}
          pointerHeight={10}
          lineJoin={"round"}
          shadowColor={"black"}
          shadowBlur={10}
          shadowOffsetX={10}
          shadowOffsetY={10}
          shadowOpacity={0.2}
          strokeWidth={0.5}
          stroke="grey"
          cornerRadius={10}
        />
        <Text text={node.text} fill={"black"} fontSize={10} padding={5} />
      </Label>
    );
  }

  const onMouseLeave = (event) => {
    // console.log('onMouseLeave >>>', event, event.currentTarget, event.currentTarget.parent.children[1], event.currentTarget.children);
    event.target.getStage().container().style.cursor = 'default';
    // const dummy = event.currentTarget.parent.children[1].getContext("2d")
    // dummy.fillRect(0, 0, 50, 50);
    setNode({ x: -500, y: -500, text: '' });
  };

  const getTransformerData = (data) => {
    console.log('getTransformerData >>>>', data);
  }

  const getSignature = (e) => {
    // console.log('getSignature >>>>>', e);
    images[modalImageIndex].src = e.img;
    setImages(images)
    setLocalImages(images);
    // localStorage.setItem('images', JSON.stringify(images));
    // setAttributeArray(JSON.parse(localStorage.getItem('images')));
    // console.log('images >>>>>', images);
  }

  const getPendingField = () => {
    console.log('getPendingField >>>>>', localStorage.getItem('all_images'), user)
    if (localStorage.getItem('all_images')) {
      let count = 0;
      let allImagesStatus = [];
      let attributes = JSON.parse(localStorage.getItem('all_images'));
      // console.log('attributes >>>>>', attributes);

      attributes.forEach((images) => {
        if (images?.length > 0) {
          images.forEach((image) => {
            if (image.inviteEmail === JSON.parse(localStorage.getItem('profile')).email) {
              allImagesStatus.push(image);
              if (!image.src)
                count++;
            }
          });
        }
      });
      setAllImagesStatus(allImagesStatus);
      console.log('count >>>>>>', count, allImagesStatus);
      setPendingImagesStatus(initCount => {
        console.log('initCount >>>>', initCount);
        initCount.count = count;
        return initCount;
      });
    } else {
      setPendingImagesStatus(initCount => {
        console.log('initCount >>>>', initCount);
        initCount.count = 0;
        return initCount;
      });
    }
  }

  const redirectToField = (pageNo) => {
    console.log('redirectToField >>>>>', pageNo, page);
    if ((pageNo + 1) === page) {
      return;
    }
    setMultiple((state) => {
      if (state[pageNo]) {
        state[pageNo] = images;
      } else {
        state.push(images);
      }
      return state;
    });
    if (multiple.length - 1 > pageNo) setImages(multiple[pageNo + 1]);
    else setImages([]);

    setPage(pageNo + 1);
  }
  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
      // direction={small ? 'column-reverse' : ''}
      sx={{
        backgroundColor: '#F5F5F5',
        overflowX: 'hidden',
        p: 2,
      }}
    >
      <Grid
        item
        xs={12}
        // sx={{ borderRadius: '20px', backgroundColor: 'white' }}
        md={10}
      >
        {
          status === 'loaded' ? (
            <>
              <Stage
                width={img?.width || 0}
                height={img?.height || 0}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
                scaleX={stageSpec.scale}
                scaleY={stageSpec.scale}
                x={stageSpec.x}
                y={stageSpec.y}
              >
                <Layer>
                  <Image
                    ref={divRef}
                    x={divRef?.current?.x}
                    y={divRef?.current?.y}
                    width={img?.width}
                    height={img?.height}
                    image={img}
                    draggable={false}
                    onDragStart={() => {
                      console.log('onDragStart >>>')
                      divRef?.current?.x(0);
                      divRef?.current?.y(0);
                    }}
                    onDragEnd={() => {
                      console.log('onDragEnd >>>')
                      divRef.current.x(0);
                      divRef.current.y(0);
                    }}
                  />
                </Layer>
                <Layer>{tooltip()}</Layer>
                <Layer>
                  {images.map((image, index) => {
                    // console.log('image >>>>>', image);
                    if (image && image.src) {
                      if (image.src.split('data:image/png').length === 1 && image.src.split('?').length === 1) {
                        let stopGivingMeHeadaches = '?stopGivingMeHeadaches=true';
                        let stopGivingMeHeadachesIndex = image.src.split('?').lastIndexOf(stopGivingMeHeadaches);
                        if (stopGivingMeHeadachesIndex === -1) {
                          image.src = image.src + stopGivingMeHeadaches;
                        }
                      }
                    }
                    return (
                      <URLImage
                        transformerData={getTransformerData}
                        image={image}
                        key={index}
                        shapeProps={image}
                        stageScale={stageSpec.scale}
                        isSelected={image === selectedId}
                        unSelectShape={unSelectShape}
                        onMouseOver={() => { onMouseOver(image) }}
                        onMouseLeave={onMouseLeave}
                        onClick={handleRemove}
                        onSelect={(select) => {
                          console.log('onSelect >>>>>', select, image.inviteEmail, JSON.parse(localStorage.getItem('profile')).email)
                          console.log('>>> IN IF >>>>')
                          setType(image.type);
                          setValue(0);
                          setModal(true);
                          setModalImageIndex(index);
                          console.log('onSelect >>>>', image, select)
                          selectShape(image);
                        }}
                        onChange={(newAttrs) => {
                          const rects = images.slice();
                          rects[index] = newAttrs;
                          console.log('onChange >>>>', images, newAttrs, rects[index])
                          setImages(rects);
                          setLocalImages(images);
                          // localStorage.setItem('images', JSON.stringify(images));
                          // console.log('localStorage GET IN CHANGE', JSON.parse(localStorage.getItem('images')));
                          // setAttributeArray(JSON.parse(localStorage.getItem('images')));
                        }}
                        onDragEnd={(e) => {
                          console.log('onDragEnd >>>', e)
                        }}
                        onDelete={onDeleteImage}
                      />
                    );
                  })}
                </Layer>
              </Stage>
              {
                modal ? (
                  <Modal
                    open={modal}
                    onClose={() => {
                      setModal(false);
                    }}
                  >
                    <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 600,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      borderRadius: '4px',
                      p: 4,
                    }}>
                      {
                        Type === 'signature' ? (
                          <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={lan.Draw} {...a11yProps(0)} />
                                {/* <Tab label="Assign" {...a11yProps(1)} /> */}
                              </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                              <Signature getSignatureURL={getSignature} isCustomer={true} onClose={() => { setModal(false) }}></Signature>
                            </TabPanel>
                          </>
                        ) : ''
                      }
                      {
                        Type === 'initials' ? (
                          <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={lan.Type} {...a11yProps(0)} />
                                {/* <Tab label="Assign" {...a11yProps(1)} /> */}
                                <Tab label="" {...a11yProps(2)} sx={{ display: 'none' }} />
                              </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                              <Grid item container xs={12} sx={{ mt: 3 }} spacing={3}>
                                <Grid item sx={{
                                  width: '100%',
                                  height: '200px',
                                  border: '1px solid grey',
                                  borderRadius: '10px',
                                  fontFamily: initials.font_family || 'arial',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: '60px'
                                }}>
                                  {initials.initial}
                                </Grid>
                                {
                                  SaveInitial ? (
                                    <canvas
                                      ref={initialRef}
                                      height={45}
                                      width={initials.initial.length * 30}
                                      style={{
                                        height: '45px',
                                        width: initials.initial.length * 30 + 'px',
                                        display: 'none'
                                      }}
                                    />
                                  ) : ''
                                }
                                <Grid item xs={12} md={6} sx={{ paddingLeft: '0px !important' }}>
                                  <Typography sx={{ paddingBottom: '10px' }}>{lan.Type_in_your_initials}</Typography>
                                  <TextField
                                    fullWidth
                                    name='name'
                                    value={DefaultInitialValues.name}
                                    onChange={handleChangeInitial}
                                    InputProps={{}}
                                    placeholder={lan.Enter_initials}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography sx={{ paddingBottom: '10px' }}>{lan.Font_family}</Typography>
                                  <TextField
                                    required
                                    name='font_family'
                                    value={DefaultInitialValues.font_family}
                                    onChange={handleChangeInitial}
                                    fullWidth
                                    select
                                  >
                                    <MenuItem value='sans-serif'>{lan.Helvetica}</MenuItem>
                                    <MenuItem value='serif'>{lan.Georgia}</MenuItem>
                                    <MenuItem value='fantasy'>{lan.Luminari}</MenuItem>
                                    <MenuItem value='cursive'>{lan.Comic_Sans_MS}</MenuItem>
                                    <MenuItem value='monospace'>{lan.Monaco}</MenuItem>
                                    <MenuItem value='MontSerrat'>{lan.MontSerrat}</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{
                                  paddingLeft: '0px !important'
                                }}>
                                  <Button
                                    md={3}
                                    onClick={() => {
                                      setSaveInitial(true);
                                      setTimeout(() => {
                                        saveInitials();
                                        setModal(false);
                                      }, 100);
                                    }}
                                    size='large'
                                    sx={{ color: 'white' }}
                                    variant='contained'
                                    color='primary'
                                  >
                                    {lan.Save}
                                  </Button>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}></TabPanel>
                          </>
                        ) : ''
                      }
                      {
                        Type === 'date' ? (
                          <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={lan.Date} {...a11yProps(0)} />
                                {/* <Tab label="Assign" {...a11yProps(1)} /> */}
                                <Tab label="" {...a11yProps(2)} sx={{ display: 'none' }} />
                              </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                              <Grid item container xs={12} spacing={3}>
                                {
                                  (initials.date && initials.date.length > 0) ? (
                                    <canvas
                                      ref={dateRef}
                                      height={30}
                                      width={initials.date.length * 10}
                                      style={{
                                        height: '30px',
                                        width: initials.date.length * 10 + 'px',
                                        display: 'none'
                                      }}
                                    />
                                  ) : ''
                                }
                                <Grid item xs={12} md={12} sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: '0px !important'
                                }}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                      name='date'
                                      disableFuture
                                      onChange={(e) => {
                                        handleChangeInitial(e);
                                        setTimeout(() => {
                                          saveDate();
                                          setModal(false);
                                        }, 100);
                                      }}
                                    // onClose={(e) => {
                                    //   setModal(false);
                                    // }}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}></TabPanel>
                          </>
                        ) : ''
                      }
                    </Box>
                  </Modal>
                ) : ''
              }
              <Grid
                item
                container
                xs={12}
                sx={{ pr: 2 }}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <Grid item>
                  <Button
                    variant='text'
                    onClick={() => {
                      console.log('page >>>>', page);
                      setMultiple((state) => {
                        if (state[page]) {
                          state[page] = images;
                        } else {
                          state.push(images);
                        }
                        return state;
                      });

                      // setUrl((state) => {
                      //   if (state[page]) {
                      //     state[page] = stageRef.current.toDataURL(null, 1);
                      //   } else {
                      //     state.push(stageRef.current.toDataURL(null, 1));
                      //   }
                      //   return state;
                      // });

                      setImages(multiple[page - 1]);
                      setPage(page - 1);
                    }}
                    color='primary'
                    disabled={page === 0}
                  >
                    {lan.Previous}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    onClick={() => {
                      console.log('page >>>>', page);
                      setMultiple((state) => {
                        if (state[page]) {
                          state[page] = images;
                        } else {
                          state.push(images);
                        }
                        return state;
                      });
                      // setUrl((state) => {
                      //   if (state[page]) {
                      //     // console.log(stageRef.current.toDataURL(null, 1));
                      //     state[page] = stageRef.current.toDataURL(null, 1);
                      //   } else {
                      //     state.push(stageRef.current.toDataURL(null, 1));
                      //   }
                      //   return state;
                      // });
                      if (multiple.length - 1 > page) setImages(multiple[page + 1]);
                      else setImages([]);

                      setPage(page + 1);
                    }}
                    color='primary'
                    disabled={page === pdfpages?.length - 1}
                  >
                    {lan.Next}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : ''
        }
      </Grid>
      <Grid item xs={12} md={2} spacing={3} container>
        <Grid
          item
          xs={12}
          sx={{
            mb: 3,
            backgroundColor: 'white',
            mt: 3,
            borderRadius: '20px',
            width: '100%',
            p: 2,
          }}>
          {
            (PendingImagesStatus && PendingImagesStatus.count) > 0 ? (
              <Box textAlign={'center'} sx={{
                border: '1px solid grey',
                borderRadius: '10px',
                p: 1,
                mt: 2
              }}>
                {lan.You_have}<br /> <b>{(PendingImagesStatus && PendingImagesStatus.count)} {lan.required_field}</b> <br />{lan.to_fill_out}.
                {
                  AllImagesStatus.map((image) => {
                    return (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        border: '0.5px solid grey',
                        borderRadius: '10px',
                        marginTop: '10px',
                        cursor: image.src ? 'no-drop': 'pointer'
                      }} onClick={() => { !image.src && redirectToField(image.pageNo - 1) }}>
                        {image.src ? (
                          <Box>
                            <img title={lan.Complete} width={'24px'} src={complete} alt={lan.Complete} />
                            <Typography sx={{ fontSize: '8px', color: 'green' }}>
                              {lan.Complete}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <img title={lan.Pending} width={'20px'} src={pending} alt={lan.Pending} />
                            <Typography sx={{ fontSize: '8px', color: 'maroon' }}>
                              {lan.Pending}
                            </Typography>
                          </Box>
                        )}
                        <Typography sx={{
                          paddingLeft: '10px',
                          fontSize: '12px'
                        }}>
                          {image.label} {lan.on_Page} {image.pageNo + 1}
                        </Typography>
                      </Box>
                    )
                  })
                }
              </Box>
            ) : (
              <Box textAlign={'center'} sx={{
                border: '1px solid grey',
                borderRadius: '10px',
                p: 1,
                mt: 2
              }}>
                {lan.All_fields_are_filled_by_you}
                {
                  AllImagesStatus.map((image) => {
                    return (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        border: '0.5px solid grey',
                        borderRadius: '10px',
                        marginTop: '10px',
                        cursor: image.src ? 'no-drop': 'pointer'
                      }}>
                        {image.src ? (
                          <Box>
                            <img title={lan.Complete} width={'24px'} src={complete} alt={lan.Complete} />
                            <Typography sx={{ fontSize: '8px', color: 'green' }}>
                            {lan.Complete}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <img title={lan.Pending} width={'20px'} src={pending} alt={lan.Pending} />
                            <Typography sx={{ fontSize: '8px', color: 'maroon' }}>
                            {lan.Pending}
                            </Typography>
                          </Box>
                        )}
                        <Typography sx={{
                          paddingLeft: '10px',
                          fontSize: '12px'
                        }}>
                          {image.label} {lan.on_Page} {image.pageNo + 1}
                        </Typography>
                      </Box>
                    )
                  })
                }
              </Box>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DragandDrop;
