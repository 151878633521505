import React, { 
  // lazy, 
  // Suspense, 
  useState 
} from 'react';
import { Box, Drawer, useMediaQuery, IconButton } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router';
import Sidebar from 'components/sidebar';
import Home from 'pages/Admin/Home';
import MenuIcon from '@mui/icons-material/Menu';
import ManageBusiness from 'pages/Admin/ManageBusiness';
import AddBusiness from 'pages/Admin/AddBusiness';
import EditBusiness from 'pages/Admin/EditBusiness';
import AddEmployee from 'pages/Common/AddEmployee';
import EditEmployee from 'pages/Common/EditEmployee';
import Setting from 'pages/Admin/Setting';
import ManageEmployees from 'pages/Common/ManageEmployees';
import ManageEmployeesAll from 'pages/Common/ManageEmployeeAll';
import Login from 'pages/Admin/Login';
import AdminMenuItems from 'sidebar-tabs/admin';
import UpdateProfile from 'pages/Admin/UpdateProfile';

import Updates from 'pages/Admin/Updates';
// import Loader from 'components/misc/Loader';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
const drawerWidth = 300;

export default function AdminRoutes() {
  const [expanded, setExpanded] = useState(false);
  const small = useMediaQuery('(max-width:900px)');
  const user = useSelector((state) => state.auth)?.user;
  return (
    <Routes>
      <Route
        path='login'
        element={
          <>
            <Helmet>
              <title>Jump Sign</title>
            </Helmet>
            <Login />
          </>
        }
      />
      <Route
        path='*'
        element={
          localStorage.getItem('role') === 'admin' || user?.role === 'admin' ? (
            <Box
              sx={{
                display: 'flex',
                ...(small ? { flexDirection: 'column' } : {}),
              }}
            >
              <Helmet>
                <title>Jump Sign - Admin</title>
              </Helmet>
              <Drawer
                open={expanded}
                onClose={() => setExpanded(false)}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant={small ? 'temporary' : 'permanent'}
                anchor='left'
              >
                <Sidebar>
                  <AdminMenuItems setExpanded={setExpanded} />
                </Sidebar>
              </Drawer>
              {small ? (
                <Box
                  component='nav'
                  display='flex'
                  alignItems={'flex-start'}
                  style={{ p: 3 }}
                >
                  <IconButton onClick={() => setExpanded(true)}>
                    <MenuIcon
                      color='primary'
                      style={{
                        fontSize: 30,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <></>
              )}
              <Box
                component='main'
                sx={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
                style={{ backgroundColor: 'white' }}
              >
                <Routes>
                  <Route path='' element={<Home />} />
                  <Route path='manage-business' element={<ManageBusiness />} />
                  <Route path='add-business' element={<AddBusiness />} />
                  <Route path='add-business/:id' element={<AddBusiness />} />
                  <Route path='edit-business/:id' element={<EditBusiness />} />
                  <Route path='add-employee' element={<AddEmployee />} />
                  <Route path='add-employee/:id' element={<AddEmployee />} />
                  <Route path='edit-employee/:id' element={<EditEmployee />} />
                  <Route
                    path='manage-business/:id'
                    element={<ManageEmployees />}
                  />
                  <Route
                    path='manage-employee'
                    element={<ManageEmployeesAll />}
                  />
                  <Route path='settings' element={<Setting />} />
                  <Route path='updates' element={<Updates />} />
                  <Route path='update-profile' element={<UpdateProfile />} />
                </Routes>
              </Box>
            </Box>
          ) : (
            <Navigate to='/admin/login' replace={true} />
          )
        }
      />
    </Routes>
  );
}
