import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/employee` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const getemployee = (id, query) =>
  API.get('/company/' + id + '?' + query);
export const getemployeeall = (query) => API.get('?' + query);
export const getemployeebyid = (id) => API.get(`/${id}`);

export const createemployee = (formdata) => API.post('/', formdata);
export const updateemployee = (id, formdata) => API.put('/' + id, formdata);
export const sendotpemployee = (formdata) => API.post('/send-otp', formdata);
export const loginemployee = (formdata) => API.post('/login', formdata);
export const deletemployee = (id) => API.delete('/' + id);
