import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import * as PDFJS from 'pdfjs-dist/webpack';
import DragAndDrop from './DragAndDrop';

import baseurl from 'api/baseurl';
import axios from 'axios';
import Loader from 'components/misc/Loader';
const Home = ({
  stageRef,
  images,
  setImages,
  canvasPages,
  page,
  setPage,
  setMultipleImages,
  multipleImages,
  setUrl,
  token,
  id,
  setAttributeArray,
  setOriginalAttributeArray,
  pdfName
}) => {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState({});
  // const [OriginalAttributeArray, setOriginalAttributeArray] = useState([]);
  const [signatures, setSignatures] = useState([]);
  useEffect(() => {
    const getData = async () => {
      axios
        .get(baseurl + '/document/' + id, {
          headers: { Authorization: 'Bearer ' + token },
        })
        .then((res) => {
          // let attribute = res.data?.document?.attribute_array;
          let attribute = [];
          setOriginalAttributeArray(res.data?.document?.attribute_array);
          pdfName(res.data?.document?.document_name || '');
          console.log(res.data?.document?.attribute_array);
          if (res.data?.document?.attribute_array.length > 0)
            res.data?.document?.attribute_array.forEach((attr) => {
              if (attr.inviteEmail === res.data?.document?.email) {
                if (!attribute[attr.pageNo])
                  attribute[attr.pageNo] = [];
                attribute[attr.pageNo].push(attr);
              }
            });
          console.log('GET attribute >>>>>', attribute);
          setAttributeArray(attribute);
          localStorage.setItem('all_images', JSON.stringify(attribute));
          localStorage.setItem('customer_token', token);
          console.log('setItem >>>>>', { email: res.data?.document?.email });
          localStorage.setItem('profile', JSON.stringify({ email: res.data?.document?.email }));
          setFile(res.data.document);
        });
    };
    getData();
  }, [setFile, id, token]);
  const [pdf, setPDF] = useState([]);
  // const readFileData = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       resolve(e.target.result);
  //     };
  //     reader.onerror = (err) => {
  //       reject(err);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [1000]);
  }, [setLoading]);
  const convertPdfToImages = async (file) => {
    console.log('convertPdfToImages >>>>>>', file);
    const pdfPage = [];
    // const data = await readFileData(file);
    const pdf = await PDFJS.getDocument(file).promise;
    const canvas = document.createElement('canvas');
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext('2d');
      var resolution = 1.5;
      canvas.height = resolution * 792;
      canvas.width = resolution * 612;
      await page.render({ canvasContext: context, viewport: viewport, transform: [resolution, 0, 0, resolution, 0, 0] }).promise;
      pdfPage.push(canvas.toDataURL('image/png', 1));
    }
    canvas.remove();
    setPDF(pdfPage);
    setUrl((state) => (state.length > 0 ? state : pdfPage));
  };
  useEffect(() => {
    if (file.document)
      convertPdfToImages(file.document);
  }, [file, setPDF]);
  if (loading) {
    return <Loader />;
  }
  return (
    <Box
      width='100%'
      sx={{
        height: '100vh',
        boxSizing: 'border-box',
        backgroundColor: '#F5F5F5',
      }}
    >
      <DragAndDrop
        signatures={signatures}
        stageRef={stageRef}
        pdfpages={pdf}
        images={images}
        setImages={setImages}
        canvases={canvasPages}
        page={page}
        setPage={setPage}
        multiple={multipleImages}
        setMultiple={setMultipleImages}
        setUrl={setUrl}
        // attributeArray={attributeArray}
        setAttributeArray={setAttributeArray}
      />
    </Box>
  );
};

export default Home;
