import * as React from "react";

import {
  FormControl,
  TextField,
  CssBaseline,
  Box,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ backgroundColor: "white" }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          color="primary"
          fontWeight={600}
          fontSize={40}
        >
          Forgot Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 4,
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl fullWidth>
            <Typography>Email/Phone </Typography>
            <TextField
              sx={{ mt: 0.5 }}
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              placeholder="Enter your email/phone number"
              autoComplete="email"
              autoFocus
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "white" }}
            component={Link}
            to="/reset-password"
          >
            Send Link
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
