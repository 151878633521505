import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Chip,
  useTheme,
  Dialog,
  DialogActions,
  DialogContentText,
  IconButton,
  DialogContent,
  DialogTitle,
  Avatar,
} from '@mui/material';
import moment from 'moment';
import { getBusiness } from 'actions/business';
import deleteSvg from 'assets/misc/delete.svg';
import { useNavigate, useParams } from 'react-router';
import { getEmployeeById } from 'actions/employee';
import { updateEmployee } from 'actions/employee';
import serialize from 'utils/serialize';
import MuiPhoneNumber from 'mui-phone-number';
import { Add, Close } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { removeEmployee } from 'actions/employee';
const AddEmployee = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const theme = useTheme();
  const [state, setState] = useState({
    first_name: '',
    business: '',
    last_name: '',
    email: '',
    phone: '',
    language: 'english',
    ip_address: [''],
    account_expiry: new Date(),
  });
  const ref = useRef();
  const [suspend, setSuspend] = useState(false);
  const [deleteEmployee, setdeleteEmployee] = useState(false);
  const [business, setBusiness] = useState([]);
  const [image, setImage] = useState();
  const [imageURL, setImageURL] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    const getData = async () => {
      setBusiness(await getBusiness(serialize({ page: 'all' })));
      const employee = await getEmployeeById(id);
      setState({ ...employee, business: employee.business._id });
      if (employee.logo) {
        setImageURL(employee.logo);
      }
    };
    getData();
  }, [setBusiness, setState, id, setImageURL]);
  console.log(state);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setLoading(true);
    const formdata = new FormData();
    Object.keys(state).map((obj) => {
      return formdata.append(
        obj,
        typeof state[obj] === 'object'
          ? JSON.stringify(state[obj].filter((item) => item !== ''))
          : state[obj],
      );
    });
    if (image) formdata.append('logo', image);

    await updateEmployee(id, formdata, navigate);
    setLoading(false);
  };

  const suspendAccount = async () => {
    await updateEmployee(id, { ...state, active: !state.active }, navigate);
  };

  const employeeDelete = async () => {
    await removeEmployee(id, navigate);
  };
  return (
    <>
      <Dialog
        open={deleteEmployee}
        onClose={() => setdeleteEmployee(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Are your sure you want to delete this employee
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            the documents created by this employee will also be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdeleteEmployee(false)}>No</Button>
          <Button
            onClick={async () => {
              await employeeDelete();
              setdeleteEmployee(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={suspend}
        onClose={() => setSuspend(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {`${state.active ? '' : 'Un'}Suspend this employee account?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {state.active
              ? "This will block them for accessing the portal's services"
              : 'This will give back the access for the portal'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuspend(false)}>No</Button>
          <Button
            onClick={async () => {
              await suspendAccount();
              setSuspend(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12} sx={{ py: 2, px: 3 }}>
          <Typography color='primary' fontSize={25} fontWeight={600}>
            Edit Employee
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ height: '90vh', px: 3, pb: 3, overflowY: 'scroll' }}
          spacing={2}
        >
          <Grid container item xs={12} spacing={2} alignItems='center'>
            <input
              type='file'
              accept='image/jpg, image/png'
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImageURL(URL.createObjectURL(e.target.files[0]));
                e.target.value = '';
              }}
              style={{ display: 'none' }}
              ref={ref}
            />
            <Grid item>
              <IconButton
                sx={{ width: 120, height: 120 }}
                onClick={() => ref.current.click()}
              >
                <Avatar
                  src={imageURL}
                  sx={{ width: 120, height: 120 }}
                ></Avatar>
              </IconButton>
            </Grid>
            <Grid item>
              <Typography color='primary'>(upto 10mb)</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>First Name*</Typography>
            <TextField
              fullWidth
              name='first_name'
              value={state.first_name}
              onChange={handleChange}
              InputProps={{
                style: {
                  color: theme.custom.primary,
                  fontWeight: 700,
                },
              }}
              sx={{ py: 1 }}
              placeholder='Enter First Name'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Last Name*</Typography>
            <TextField
              value={state.last_name}
              fullWidth
              name='last_name'
              onChange={handleChange}
              InputProps={{
                style: {
                  color: theme.custom.primary,
                  fontWeight: 700,
                },
              }}
              sx={{ py: 1 }}
              placeholder='Enter First Name'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Email*</Typography>
            <TextField
              name='email'
              value={state.email}
              onChange={handleChange}
              fullWidth
              InputProps={{
                style: {
                  color: theme.custom.primary,
                  fontWeight: 700,
                },
              }}
              sx={{ py: 1 }}
              placeholder='Enter your Email'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Mobile No</Typography>
            <MuiPhoneNumber
              disableAreaCodes
              required
              variant='outlined'
              defaultCountry='ca'
              disabled={true}
              name='phone'
              value={state.phone}
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Phone'
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography>Set Language</Typography>

            <TextField
              name='language'
              onChange={handleChange}
              sx={{ width: '30%', py: 1 }}
              select
              value={'english'}
            >
              <MenuItem value='english'>English</MenuItem>
              <MenuItem value='canadian'>Canadian French</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12} container>
            <Grid
              item
              xs={12}
              // justifyContent='space-between'
              alignItems='center'
              container
            >
              <Grid item xs={5.7}>
                <Typography>IP Address</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={(theme) => ({
                    backgroundColor: theme.custom.primary,
                    color: 'white',
                    height: 25,
                    width: 25,
                    '&:hover': {
                      backgroundColor: theme.custom.secondary,
                      color: 'white',
                    },
                  })}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      ip_address: [...state.ip_address, ''],
                    }));
                  }}
                >
                  <Add fontSize={'15'} />
                </IconButton>
              </Grid>
            </Grid>
            {state.ip_address.map((obj, index) => {
              return (
                <Grid
                  item
                  container
                  alignItems={'center'}
                  spacing={1}
                  key={index}
                  xs={12}
                  md={6}
                >
                  <Grid item xs={11}>
                    <TextField
                      required
                      fullWidth
                      name='ip_address'
                      onChange={(e) => {
                        setState((state) => {
                          state.ip_address[index] = e.target.value;
                          return state;
                        });
                      }}
                      value={obj}
                      InputProps={{
                        style: {
                          color: theme.custom.primary,
                          fontWeight: 700,
                        },
                      }}
                      sx={{ py: 1 }}
                      placeholder='Enter your IP Address'
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        setState((state) => ({
                          ...state,
                          ip_address: state.ip_address.filter(
                            (item, i) => i !== index,
                          ),
                        }));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Expiry Date</Typography>
            <TextField
              name='account_expiry'
              fullWidth
              onChange={handleChange}
              sx={{ py: 1 }}
              InputProps={{
                style: {
                  color: theme.custom.primary,
                  fontWeight: 700,
                },
              }}
              type='date'
              value={moment(state.account_expiry).format('YYYY-MM-DD')}
            />
          </Grid>
          {localStorage.getItem('role') === 'admin' && (
            <Grid item xs={12} md={6}>
              <Typography>Select Business</Typography>
              <TextField
                name='business'
                placeholder='select business'
                onChange={handleChange}
                fullWidth
                sx={{ py: 1 }}
                InputProps={{
                  style: {
                    color: theme.custom.primary,
                    fontWeight: 700,
                  },
                }}
                select
                value={state.business}
              >
                {business.map((obj) => {
                  return (
                    <MenuItem value={obj._id} key={obj._id}>
                      {obj.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <Chip
                sx={{ cursor: 'pointer' }}
                onClick={() => setSuspend(true)}
                icon={
                  state.active ? (
                    <img src={deleteSvg} alt="delete"/>
                  ) : (
                    <DoneIcon style={{ color: theme.custom.primary }} />
                  )
                }
                variant='outlined'
                label={state.active ? 'Suspend User' : 'Unsuspend User'}
              />
            </Grid>
            <Grid item>
              <Chip
                sx={{ cursor: 'pointer' }}
                onClick={() => setdeleteEmployee(true)}
                icon={<img src={deleteSvg} alt="delete"/>}
                variant='outlined'
                label='Block User'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={handleSubmit}
              sx={{ padding: '5px 50px 5px 50px', color: 'white' }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddEmployee;
