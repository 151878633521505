import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import home from 'assets/sidebar-icons/home.svg';
import employee from 'assets/sidebar-icons/employee.svg';
import settings from 'assets/sidebar-icons/settings.svg';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Admin = ({ setExpanded }) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const theme = useTheme();
  console.log(theme);
  const tabs = [
    { name: lan.Home, icon: home, path: '/admin/' },
    { name: lan.Manage_Business, icon: employee, path: '/admin/manage-business' },
    { name: lan.Manage_Employee, icon: employee, path: '/admin/manage-employee' },
  ];
  const location = useLocation();

  return (
    <List>
      {tabs.map((obj, index) => {
        return (
          <ListItemButton
            component={Link}
            onClick={() => setExpanded(false)}
            to={obj.path}
            key={index}
            sx={{
              alignItems: 'center',
              ...(location.pathname === obj.path
                ? { backgroundColor: theme.custom.highlight }
                : {}),
            }}
          >
            <ListItemIcon>
              <img src={obj.icon} alt='' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color='white' fontSize={20} fontWeight={500}>
                  {obj.name}
                </Typography>
              }
            />
          </ListItemButton>
        );
      })}

      <ListItemButton
        component={Link}
        to='/admin/settings'
        onClick={() => setExpanded(false)}
        sx={{
          alignItems: 'center',
          ...(location.pathname === '/admin/settings'
            ? { backgroundColor: theme.custom.highlight }
            : {}),
        }}
      >
        <ListItemIcon>
          <img src={settings} alt='' />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography color='white' fontSize={20} fontWeight={500}>
              {lan.Settings}
            </Typography>
          }
        />
      </ListItemButton>
    </List>
  );
};

export default Admin;
