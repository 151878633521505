import React, { 
  // useEffect, 
  useState 
} from 'react';
// import citrix from 'assets/misc/drive.svg';
import {
  Box,
  Typography,
  Grid,
  MenuItem,
  Tooltip,
  Menu,
  IconButton,
  Modal,
  Button,
  TextField,
} from '@mui/material';
import moment from 'moment';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

import PdfPreview from '../../pages/Document/pdfPreview';
import * as PDFJS from 'pdfjs-dist/webpack';
import axios from 'axios';
import baseurl from 'api/baseurl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const Document = ({ data, setIsReload }) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const user = useSelector((state) => state.auth)?.user;

  const [isPreviewModal, setPreviewModal] = React.useState(false);
  const [pdfpages, setPdfpages] = React.useState([]);
  const [attributes, setAttributes] = React.useState([]);
  const [pdfName, setPdfName] = React.useState('');
  const [pdfLink, setPdfLink] = React.useState('');
  const [PdfId, setPdfId] = React.useState('');
  const [IsCompleted, setIsCompleted] = React.useState(false);
  // const [creds, setCreds] = useState({});
  // const [isReload, setIsReload] = useState(false);

  const [SendToModal, setSendToModal] = useState(false);
  const [emailData, setEmailData] = useState({});

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   getCreds();
  // }, []);

  // const getCreds = () => {
  //   axios
  //     .get(baseurl + '/creds', {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log('res >>>>', res);
  //       setCreds(res.data);
  //     })
  // }

  const editDocument = (data) => {
    console.log('editDocument >>>', data, '/document/' + data._id);
    navigate('/dashboard/' + data._id);
  }

  const preview = (data) => {
    console.log(data);
    setPdfName(data.document_name);
    setPdfLink(data.document || '');
    setPdfId(data._id);
    setIsCompleted(data.created_by === user._id ? (data.status === 1 ? true : false) : false);
    setLocalImages(data.attribute_array, data);
    convertPdfURLToImages(data.document);
  }

  const setLocalImages = (images, data) => {
    console.log('setLocalImages >>>>', images, data);
    // let localImages = [];
    // images.forEach(element => {
    //   if (!localImages[element.pageNo]) localImages[element.pageNo] = [];
    //   localImages[element.pageNo].push(element);
    // });


    let attribute = [];
    console.log(images);
    if (images.length > 0)
      images.forEach((attr) => {
        console.log('>>>>>> asdasdas >>>>>', data?.created_by, user._id, data?.send_to.indexOf(attr.inviteId), attr.inviteId);
        if (data?.created_by === user._id || (data?.send_to.indexOf(attr.inviteId) > -1)) {
          if (!attribute[attr.pageNo])
            attribute[attr.pageNo] = [];
          attribute[attr.pageNo].push(attr);
        }
      });

    console.log('setLocalImages attribute >>>>', attribute);

    setAttributes(attribute);
  }

  const convertPdfURLToImages = async (file) => {
    console.log('convertPdfURLToImages >>>>>>', file);
    const pdfPage = [];
    const pdf = await PDFJS.getDocument(file).promise;
    const canvas = document.createElement('canvas');
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext('2d');
      var resolution = 1.5;
      canvas.height = resolution * 792;
      canvas.width = resolution * 612;
      await page.render({ canvasContext: context, viewport: viewport, transform: [resolution, 0, 0, resolution, 0, 0] }).promise;
      pdfPage.push(canvas.toDataURL('image/png', 1));
    }
    canvas.remove();
    setPdfpages(pdfPage);
    setPreviewModal(true);
  };

  const sendCopy = () => {
    axios
      .post(baseurl + '/document/send_email', {
        document_links: emailData.document,
        email: emailData.email,
        document_name: emailData.document_name
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setSendToModal(false);
        toast.success(lan.Pdf_copy_sent_on_Email);
      }, (error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error(lan.Invalid_email_address);
        }
      })
  }
  return (
    <Box>
      <Grid
        sx={{
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          px: 3,
          py: 2,
          mt: 3,
        }}
        item
        xs={12}
        justifyContent='space-between'
        alignItems='center'
        container
      >
        {/* <Grid item xs={1}>
          <img src={citrix} alt='' />
        </Grid> */}
        <Grid item xs={4} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography fontSize={20} color='primary' fontWeight={500} sx={{
            width: '80%', overflow: 'hidden', textOverflow: 'ellipsis'
          }}>
            {data.document_name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{
            width: '80%', overflow: 'hidden', textOverflow: 'ellipsis'
          }}>
            {data.created_by_email || ''}
          </Typography>
        </Grid>

        <Grid item xs={2} justifyContent={'flex-end'}>
          <Typography justifyContent={'flex-end'} color={data.status === 2 ? 'primary' : ''}>
            {data.status === 2 ? lan.Complete : lan.Pending}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography fontSize={15} fontWeight={500}>
            {moment(data.updatedAt).fromNow()}
          </Typography>
        </Grid>
        {
          (JSON.parse(localStorage.getItem('profile')).role === 'business') ? <>
            <Grid item xs={1}></Grid>
          </> : (
            <Grid textAlign={'right'} item xs={1}>
              <React.Fragment>
                <Tooltip title="Actions">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <MoreVertIcon
                      color='primary'
                      style={{
                        fontSize: 30,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {
                    (data.status === 1 && JSON.parse(localStorage.getItem('profile')).role === 'employee') ? (
                      <MenuItem onClick={() => { editDocument(data) }}>
                        {lan.Edit}
                      </MenuItem>
                    ) : ''
                  }
                  {
                    data.status === 2 ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        {lan.Preview}
                      </MenuItem>
                    ) : ''
                  }
                  {/* {
                    data.status === 2 ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        {lan.Download}
                      </MenuItem>
                    ) : ''
                  } */}
                  {/* {
                    data.status === 2 ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        {lan.Print}
                      </MenuItem>
                    ) : ''
                  } */}
                  {
                    data.status === 2 ? (
                      <MenuItem onClick={() => {
                        setEmailData(data)
                        setSendToModal(true)
                      }}>
                        {lan.Send_Copy}
                      </MenuItem>
                    ) : ''
                  }
                  {/* {
                    creds?.citrix ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        Share on Citrix
                      </MenuItem>
                    ) : ''
                  }
                  {
                    creds?.onedrive ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        Share on OneDrive
                      </MenuItem>
                    ) : ''
                  }
                  {
                    creds?.gdrive ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        Share on GDrive
                      </MenuItem>
                    ) : ''
                  } */}
                  {/* {
                    (data.created_by === user._id && data.status === 1) ? (
                      <MenuItem onClick={() => { preview(data) }}>
                        Mark Completed
                      </MenuItem>
                    ) : ''
                  } */}
                </Menu>
              </React.Fragment>
              {
                isPreviewModal ? (
                  <PdfPreview
                    pdf_id={PdfId}
                    // isCitrix={creds?.citrix}
                    // isOnedrive={creds?.onedrive}
                    // isDrive={creds?.gdrive}
                    // isComplete={IsCompleted}
                    pdfName={pdfName}
                    pdfpages={pdfpages}
                    pdfLink={pdfLink}
                    close={() => { setPreviewModal(false) }}
                    attributeArray={attributes}
                    isReload={() => { setIsReload(true) }}
                  />
                ) : ''
              }
              {
                SendToModal ? (
                  <Modal
                    open={SendToModal}
                    onClose={() => {
                      setSendToModal(false);
                    }}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Box sx={style}>
                      <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                      >
                        {lan.Enter_Email}
                      </Typography>
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent='center'
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            onChange={(e) => {
                              setEmailData({ ...emailData, ...{ email: e.target.value } })
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          justifyContent={'center'}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            sx={{ color: 'white' }}
                            onClick={() => {
                              sendCopy();
                            }}
                          >
                            {lan.Send_PDF}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                ) : ''
              }
            </Grid>
          )
        }
      </Grid>
    </Box>
  );
};

export default Document;
