import React from 'react';
import {
  // Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  // Button
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router';
import edit from 'assets/document-sidebar-icons/edit.svg';
// import calendar from 'assets/document-sidebar-icons/calendar.svg';
import document from 'assets/document-sidebar-icons/document.svg';
// import save from 'assets/document-sidebar-icons/save.svg';
import share from 'assets/document-sidebar-icons/share.svg';
import backIcon from 'assets/document-sidebar-icons/back.svg';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Document = ({
  setExpanded,
  downloadPDF,
  setExpiry,
  print,
  setModal,
  previewPDF,
  setSigned,
  signed,
  setStatusModal,
  back,
  editDocumentId,
  editDocument
}) => {
  
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const theme = useTheme();
  const navigate = useNavigate();
  const tabs = [
    { name: lan.Back, onClick: back, icon: backIcon },
    { path: '/document/', name: lan.Edit_Document, icon: edit },
    // { name: 'Draw Signature', path: '/document/signature', icon: signature },
    // {
    //   name: 'Set Expiry Date',
    //   onClick: () => setExpiry((state) => !state),
    //   icon: calendar,
    // },
    // { name: 'Review', onClick: previewPDF, icon: document },
    // { name: 'Save as PDF', onClick: downloadPDF, icon: save },
    // {
    //   name:
    //     localStorage.getItem('language') === 'english' ? 'Print' : 'Imprimer',
    //   onClick: print,
    //   icon: document,
    // },
    // {
    //   name: 'Signed By Employee',
    //   component: (
    //     <Checkbox
    //       checked={true}
    //       sx={{
    //         color: 'white',
    //         '&.Mui-checked': {
    //           color: 'white',
    //         },
    //         ml: -1,
    //       }}
    //     />
    //   ),
    // },
    // {
    //   name: 'Signed By Client',
    //   component: (
    //     <Checkbox
    //       checked={Boolean(signed)}
    //       sx={{
    //         color: 'white',
    //         '&.Mui-checked': {
    //           color: 'white',
    //         },
    //         ml: -1,
    //       }}
    //     />
    //   ),
    //   onClick: () => setSigned((state) => !state),
    // },
  ];
  if(!editDocumentId) {
    tabs.push(    {
      name: lan.Save,
      onClick: () => setModal((modal) => !modal),
      icon: share,
    });
    // tabs.push({
    //   name: 'Finish Document',
    //   onClick: () => setStatusModal((modal) => !modal),
    //   icon: document
    // });
  } else {
    tabs.push({
      name: lan.Save_Document,
      onClick: editDocument,
      icon: document
    });
  }
  const location = useLocation();

  return (
    <List>
      {tabs.map((obj, index) => {
        return (
          <ListItemButton
            key={index}
            onClick={() => {
              console.log('obj >>>>', obj);
              if (obj.path) navigate(obj.path);
              else obj.onClick();
              setExpanded(false);
            }}
            sx={{
              alignItems: 'center',
              ...(location.pathname === obj.path
                ? { backgroundColor: theme.custom.highlight }
                : {}),
            }}
          >
            <ListItemIcon>
              {obj.component ? obj.component : <img src={obj.icon} alt='' />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color='white' fontSize={20} fontWeight={500}>
                  {obj.name}
                </Typography>
              }
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default Document;
