import React from 'react';
import { Grid, Typography, Box, Avatar, IconButton } from '@mui/material';

import right from 'assets/misc/right-icon.svg';
// import baseurl from 'api/baseurl';
const BusinessCard = ({ data, onClick }) => {
  return (
    <Box
      sx={{
        border: `1px solid #C6C6C6`,
        px: 2,
        py: 1,
        my: 2,
        borderRadius: '10px',
      }}
    >
      <Grid container item alignItems='center' xs={12}>
        <Grid item xs={1}>
          {data.logo ? (
            <Avatar
              src={data.logo}
              style={{ width: 50, height: 50, objectFit: 'cover' }}
              crossOrigin='anonymous'
              alt=''
            />
          ) : (
            <Avatar sx={{ width: 50, height: 50 }} />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography color='primary' fontSize={20} fontWeight={700}>
            {data.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color='#c6c6c6' fontWeight={500} fontSize={15}>
            {data.email}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color='#c6c6c6' fontWeight={500} fontSize={15}>
            {data.phone}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClick}>
            <img src={right} alt='' />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessCard;
