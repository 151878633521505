import React, { useState } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import {
  Box,
  Button,
  Grid,
  Modal,
  // Typography
} from '@mui/material';
// import JsPDF from 'jspdf';
import useImage from 'use-image';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';

import cross from 'assets/misc/cross.svg';
// import printIcon from 'assets/misc/print.png';
// import save from 'assets/document-sidebar-icons/save.svg';
// import citrix from 'assets/logos/citrix.svg';
// import drive from 'assets/logos/drive.svg';
// import onedrive from 'assets/logos/onedrive.svg';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import baseurl from 'api/baseurl';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const URLImage = ({
  image
}) => {
  const [img, status] = useImage(image.src, 'Anonymous');
  return (
    <React.Fragment>
      {
        image.src ? (
          status === 'loaded' ? (
            <Image
              image={img}
              x={(img && (img.width < image.width)) ? image.x : (image.x + 5)}
              y={(img && (img.height < image.height)) ? image.y : (image.y + 5)}
              width={(img && (img.width < image.width)) ? ((img.width)) : (image.width - 10)}
              height={(img && (img.height < image.height)) ? ((img.height)) : (image.height - 10)}
              align={"center"}
              verticalAlign={"middle"}
            />
          ) : ''
        ) : ''
      }
    </React.Fragment>
  );
};

const PdfPreview = ({
  pdfName,
  pdfpages,
  attributeArray,
  close,
  // isCitrix,
  // isOnedrive,
  // isDrive,
  // isComplete,
  pdf_id,
  pdfLink,
  isReload,
  isAutoShare // Auto share document with Status is complete
}) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const stageRef = React.useRef();

  const file = useSelector((state) => state.file).file;
  const [page, setPage] = React.useState(0);
  const [urls, setUrl] = React.useState(pdfpages);
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  const [img] = useImage(pdfpages[page], 'Anonymous');

  const divRef = React.useRef(null);

  const [stageSpec, setStageSpec] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  React.useEffect(() => {
    if (isAutoShare) {
      setTimeout(() => {
        shareDocument()
      }, 2000);
    }
  }, []);

  React.useEffect(() => {
    getLocalImages()
  }, [page]);

  const setAttributes = () => {
    setUrl((state) => {
      if (state[page]) {
        state[page] = stageRef.current.toDataURL(null, 1);
      } else {
        state.push(stageRef.current.toDataURL(null, 1));
      }
      return state;
    });
  }
  // const savePDf = () => {
  //   setAttributes();
  //   const pdf = new JsPDF('p', 'mm', 'a4');
  //   pdf.internal.scaleFactor = 3;
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = pdf.internal.pageSize.getHeight();
  //   urls.map((image, index) => {
  //     pdf.addImage(image, 'PNG', 0, 0, width, height, `${index}`);
  //     if (index !== urls.length - 1) {
  //       pdf.addPage();
  //     }
  //   });
  //   pdf.save((pdfName || file.name));
  // };

  // const print = () => {
  //   setAttributes();
  //   const pdf = new JsPDF('p', 'mm', 'a4');
  //   pdf.internal.scaleFactor = 3;
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = pdf.internal.pageSize.getHeight();

  //   urls.map((image, index) => {
  //     pdf.addImage(image, 'PNG', 0, 0, width, height, `${index}`);
  //     if (index !== urls.length - 1) {
  //       pdf.addPage();
  //     }
  //   });
  //   window.open(pdf.output('bloburl', (pdfName || file.name)), '_blank');
  // };

  const getLocalImages = () => {
    let localImages = attributeArray;
    let images = [];

    if (!localImages) localImages = [];
    if (localImages[page] && localImages[page].length > 0)
      localImages[page].forEach((img) => {
        images.push(img);
      });
    // setImages([]);
    setImages(images);
  }

  // const saveDocument = (params) => {
  //   if (params.citrix || params.onedrive || params.drive) {
  //     setAttributes();
  //     params.image = urls;
  //   } else {
  //     params.image = pdfpages;
  //   }
  //   setLoading(true);
  //   console.log('saveDocument IN PDF data >>>>', params)
  //   axios
  //     .put(
  //       baseurl + '/document/update',
  //       {
  //         document_id: pdf_id,
  //         // status: 1,
  //         ...{ ...params },
  //         document_name: (pdfName || file.name),
  //         attribute_array: attributeArray || []
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${localStorage.getItem('customer_token')}` },
  //       },
  //     )
  //     .then((res) => {
  //       setLoading(false);
  //       isReload(true);
  //       close(false)
  //     });
  // }

  /**
  * Share Document API
  */
  const shareDocument = () => {
    setAttributes();
    // setLoading(true);
    axios
      .put(
        baseurl + '/document/share',
        {
          document_id: pdf_id,
          image: urls,
          document_name: (pdfName || file.name),
          attribute_array: attributeArray || []
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('customer_token') || localStorage.getItem('token')}` },
        },
      )
      .then((res) => {
        close(false)
        // setLoading(false);
        // navigate('/dashboard/documents');
      });
  }

  return (
    <Box fontSize={20} fontWeight={500} paddingBottom={'10px'} sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <Modal
        open={true}
        onClose={() => { }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 900,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '4px',
          p: 4,
          maxHeight: '80vh',
          minHeight: '600px',
          overflow: 'scroll'
        }}>
          <Box display='flex' justifyContent='end'>
            {/* {
              isCitrix ? (
                <Button
                  title='Share on Citrix'
                  variant='contained'
                  color='primary'
                  onClick={() => { saveDocument({ citrix: true }) }}
                  sx={{
                    color: 'white',
                    mr: 1
                  }}
                >
                  <Typography sx={{ pr: 1 }}>Share on</Typography>
                  <img src={citrix} alt='citrix' />
                </Button>
              ) : ''
            } */}
            {/* {
              isOnedrive ? (
                <Button
                  title='Share on OneDrive'
                  variant='contained'
                  color='primary'
                  onClick={() => { saveDocument({ onedrive: true }) }}
                  sx={{
                    color: 'white',
                    mr: 1
                  }}
                >
                  <Typography sx={{ pr: 1 }}>Share on</Typography>
                  <img src={onedrive} alt='onedrive' />
                </Button>
              ) : ''
            } */}
            {/* {
              isDrive ? (
                <Button
                  title='Share on GDrive'
                  variant='contained'
                  color='primary'
                  onClick={() => { saveDocument({ drive: true }) }}
                  sx={{
                    color: 'white',
                    mr: 1
                  }}
                >
                  <Typography sx={{ pr: 1 }}>Share on</Typography>
                  <img src={drive} alt='Gdrive' />
                </Button>
              ) : ''
            } */}
            {/* {
              isComplete ? (
                <Button
                  title='Mark Document Completed'
                  variant='contained'
                  color='primary'
                  onClick={() => { saveDocument({ status: 2 }) }}
                  sx={{
                    color: 'white',
                    mr: 1
                  }}
                >
                  Mark Completed
                </Button>
              ) : ''
            } */}
            {/* <Button
              title={lan.Download_Document}
              variant='contained'
              color='primary'
              onClick={savePDf}
              sx={{
                color: 'white',
                mr: 1
              }}
            >
              <img src={save} alt={lan.Save} />
            </Button> */}
            {/* <Button
              title={lan.Print_Document}
              variant='contained'
              color='primary'
              onClick={print}
              sx={{
                color: 'white',
                mr: 1
              }}
            >
              <img src={printIcon} alt={lan.Print} />
            </Button> */}
          </Box>
          {
            loading ? (
              <Box sx={{
                height: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={100} color='primary' />
              </Box>
            ) : (
              <Box
                sx={{
                  // p: 2,
                  boxSizing: 'border-box',
                  // display: 'flex',
                  // alignItems: 'start'
                }}
              >
                {/* <Button
                  title={lan.Close_Preview}
                  variant='contained'
                  color='primary'
                  onClick={() => { close(false) }}
                  sx={{
                    color: 'white'
                  }}
                >
                </Button> */}
                {/* <object width="100%" height="600" data={pdfLink} type="application/pdf"></object>
                <Box sx={{
                  pl: 1,
                  cursor: 'pointer'
                }}>
                  <img onClick={() => { close(false) }} src={cross} alt={lan.Cross} />
                </Box> */}
                <Box sx={{
                  pl: 1,
                  cursor: 'pointer',
                  textAlign: 'right'
                }}>
                  <img onClick={() => { close(false) }} src={cross} alt={lan.Cross} />
                </Box> 
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stage
                    width={img?.width || 0}
                    height={img?.height || 0}
                    ref={stageRef}
                    scaleX={stageSpec.scale}
                    scaleY={stageSpec.scale}
                    x={stageSpec.x}
                    y={stageSpec.y}
                  >
                    <Layer>
                      <Image
                        ref={divRef}
                        x={divRef?.current?.x}
                        y={divRef?.current?.y}
                        width={img?.width}
                        height={img?.height}
                        image={img}
                      />
                    </Layer>
                    <Layer>
                      {images.map((image, index) => {
                        return (
                          <URLImage
                            image={image}
                            key={index}
                          />
                        );
                      })}
                    </Layer>
                  </Stage>
                </Box>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ pr: 2 }}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <Button
                      variant='text'
                      onClick={() => {
                        if (page > 0)
                          setPage(page - 1)
                      }}
                      color='primary'
                      disabled={page === 0}
                    >
                      {lan.Previous}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='text'
                      onClick={() => {
                        if (page < pdfpages?.length - 1)
                          setPage(page + 1)
                      }}
                      color='primary'
                      disabled={page === pdfpages?.length - 1}
                    >
                      {lan.Next}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Box>
      </Modal>
    </Box>
  );
};

export default PdfPreview;
