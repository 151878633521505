import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Modal, TextField } from '@mui/material';
import citrix from 'assets/logos/citrix_black.svg';
import drive from 'assets/logos/drive_black.svg';
import onedrive from 'assets/logos/onedrive_black.svg';
import { useNavigate } from 'react-router';
import { 
  useDispatch, 
  // useSelector 
} from 'react-redux';
import axios from 'axios';
import baseurl from 'api/baseurl';
import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Setting = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [open, setOpen] = useState(false);
  const [state, setState] = useState({});
  const [mode, setMode] = useState('');
  const [data, setData] = useState({})

  const fetchCreds = () => {
    axios
      .get(baseurl + '/creds', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        setData(res.data);
      });
  };
  useEffect(() => {
    fetchCreds();
  }, [setData]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addDetais = (e) => {
    console.log('data >>>>', data);;
    e.preventDefault();
    axios
      .post(
        baseurl + '/creds',
        { [mode]: state },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      .then(async (res) => {
        if (!data) {
          selectedMethod(mode);
        }
        fetchCreds();
        setState({});
        setOpen(false);
        toast.success(lan.Account_Updated);
      })
      .catch((err) => {
        console.log('err >>>>>', err);
        toast.error(lan.Something_went_wrong)
      });
  };

  const selectedMethod = (share_method) => {
    axios
      .put(
        baseurl + '/business/share_method',
        { share_method: share_method },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      .then(async (res) => {
        toast.success(lan.Account_Updated);
      })
      .catch((err) => {
        console.log('err >>>>>', err);
        toast.error(lan.Something_went_wrong)
      });
  };

  const citrix_form = [
    { name: 'apiUrl', label: lan.API_Url },
    { name: 'client_secret', label: lan.Client_Secret },
    { name: 'client_id', label: lan.Client_ID },
    { name: 'client_username', label: lan.Client_Username },
    { name: 'client_password', label: lan.Client_Password },
    { name: 'folder_id', label: lan.Folder_ID },
  ];

  const gdrive = [
    { name: 'client_secret', label: lan.Client_Secret },
    { name: 'client_id', label: lan.Client_ID },
    { name: 'redirect_url', label: lan.Redirect_URL },
    { name: 'refresh', label: lan.Refresh_Token },
  ];

  const onedrive_form = [
    { name: 'client_secret', label: lan.Client_Secret },
    { name: 'client_id', label: lan.Client_ID },
    { name: 'redirect_url', label: lan.Redirect_URL },
    { name: 'refresh', label: lan.Refresh_Token },
  ];
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const forms = { citrix: citrix_form, gdrive, onedrive: onedrive_form };
  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box component={'form'} onSubmit={addDetais} sx={style}>
          {forms[mode]?.map((obj, index) => {
            return (
              <Grid item sx={{ mt: 1 }} xs={12} key={index}>
                <Typography>{obj.label}</Typography>
                <TextField
                  required
                  fullWidth
                  name={obj.name}
                  value={state[obj.name]}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      [obj.name]: e.target.value,
                    }));
                  }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              type='submit'
              fullWidth
              size='large'
              variant='contained'
              sx={{ mt: 3, mb: 2, color: 'white' }}
            >
              {lan.Save}
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Typography color='primary' fontSize={25} fontWeight={600}>
        {lan.Add_Accounts}
      </Typography>
      {localStorage.getItem('role') === 'business' && (
        <>
          <Box
            sx={{
              border: '1px solid #D9D9D9',
              px: 3,
              py: 2,
              width: '70%',
              borderRadius: '10px',
              mt: 3,
            }}
          >
            <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ p: 0, m: 0 }}>
              <Grid
                item
                display={'flex'}
                sx={{
                  p: '0px !important',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setMode('citrix');
                  setState(data ? data.citrix || {} : {});
                  setOpen(true);
                }}>
                <img src={citrix} alt={lan.Citrix} height='24px' />
                <Typography sx={{ pl: 2 }} fontSize={20} color='primary' fontWeight={500}>
                  {lan.Login_Citrix}
                </Typography>
              </Grid>
              {
                data && data.citrix && data.citrix.client_id ? (
                  <Grid
                    item
                    sx={{
                      p: '0px !important',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      selectedMethod('citrix');
                    }}
                  >
                    <Typography fontSize={20} color={data.share_method === 'citrix' ? 'primary' : 'grey'} fontWeight={500}>
                      {lan.Selected}
                    </Typography>
                  </Grid>
                ) : ''
              }
            </Grid>
          </Box>
          <Box
            sx={{
              border: '1px solid #D9D9D9',
              px: 3,
              py: 2,
              width: '70%',
              borderRadius: '10px',
              mt: 2,
            }}
          >
            <Grid
              item
              xs={12}
              container
              display={'flex'}
              justifyContent={'space-between'}
              sx={{ p: 0, m: 0 }}
            >
              <Grid
                item
                display={'flex'}
                sx={{
                  p: '0px !important',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setMode('gdrive');
                  setState(data ? data.gdrive || {} : {});
                  setOpen(true);
                }}>
                <img src={drive} alt={lan.Google_Drive} height='24px' />
                <Typography sx={{ pl: 2 }} fontSize={20} color='primary' fontWeight={500}>
                  {lan.Login_google_drive}
                </Typography>
              </Grid>
              {
                data && data.gdrive && data.gdrive.client_id ? (
                  <Grid
                    item
                    sx={{
                      p: '0px !important',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      selectedMethod('gdrive');
                    }}
                  >
                    <Typography fontSize={20} color={data.share_method === 'gdrive' ? 'primary' : 'grey'} fontWeight={500}>
                      {lan.Selected}
                    </Typography>
                  </Grid>
                ) : ''
              }
            </Grid>
          </Box>
          <Box
            sx={{
              border: '1px solid #D9D9D9',
              px: 3,
              py: 2,
              width: '70%',
              borderRadius: '10px',
              mt: 2,
            }}
          >
            <Grid
              item
              xs={12}
              container
              display={'flex'}
              justifyContent={'space-between'}
              sx={{ p: 0, m: 0 }}
            >
              <Grid
                item
                display={'flex'}
                sx={{
                  p: '0px !important',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setMode('onedrive');
                  setState(data ? data.onedrive || {} : {});
                  setOpen(true);
                }}>
                <img src={onedrive} alt={lan.OneDrive} height='24px' />
                <Typography sx={{ pl: 2 }} fontSize={20} color='primary' fontWeight={500}>
                  {lan.Login_Onedrive}
                </Typography>
              </Grid>
              {
                data && data.onedrive && data.onedrive.client_id ? (
                  <Grid
                    item
                    sx={{
                      p: '0px !important',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      selectedMethod('onedrive');
                    }}>
                    <Typography fontSize={20} color={data.share_method === 'onedrive' ? 'primary' : 'grey'} fontWeight={500}>
                      {lan.Selected}
                    </Typography>
                  </Grid>
                ) : ''
              }
            </Grid>
          </Box>
        </>
      )}
      <Grid item xs={11} container justifyContent='center' sx={{ mt: 3 }}>
        <Button
          size='large'
          variant='outlined'
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            navigate('/login');
          }}
          sx={{
            color: 'red',
            width: '40%',
            borderRadius: '10px',
            borderColor: 'gray',
          }}
        >
          {lan.Logout}
        </Button>
      </Grid>
    </Box>
  );
};

export default Setting;
