import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  useTheme,
  Avatar,
  Select,
  Chip,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Add, Close } from '@mui/icons-material';
import moment from 'moment';
import { getBusiness } from 'actions/business';
import { createEmployee } from 'actions/employee';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import serialize from 'utils/serialize';
import MuiPhoneNumber from 'mui-phone-number';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import deleteSvg from 'assets/misc/delete.svg';
import { getEmployeeById } from 'actions/employee';
import { updateEmployee } from 'actions/employee';
import { removeEmployee } from 'actions/employee';
import DoneIcon from '@mui/icons-material/Done';

import Loader from 'components/misc/Loader';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const AddEmployee = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { id } = useParams();

  const [language, setLanguage] = useState('english');
  const [FormSubmitted, setFormSubmitted] = useState(false);
  const [BusinessMobileVerify, setBusinessMobileVerify] = useState(false);
  const [BusinessAccount, setBusinessAccount] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [suspend, setSuspend] = useState(false);
  const [deleteEmployee, setdeleteEmployee] = useState(false);

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [state, setState] = useState({
    first_name: '',
    business: '',
    last_name: '',
    email: '',
    phone: '',
    language: 'english',
    ip_address: [''],
    account_expiry: '',
  });
  const [image, setImage] = useState();
  const [imageURL, setImageURL] = useState('');
  const ref = useRef();
  const [business, setBusiness] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector((state) => state.auth)?.user;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setBusiness(await getBusiness(serialize({ page: 'all' })));
      const employee = await getEmployeeById(id);
      setLoading(false);
      setState({ ...employee, business: employee.business._id });

      if (employee.business._id) {
        setBusinessAccount(true);
      }

      setValue('first_name', employee.first_name);
      setValue('last_name', employee.last_name);
      setValue('email', employee.email);
      setLanguage(employee.language);

      if (employee.phone)
        setBusinessMobileVerify(true)

      if (employee.logo) {
        setImageURL(employee.logo);
        setImage(employee.logo);
      }
    };
    if (id)
      getData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'language') {
      setLanguage(value);
    }
    if (name === 'business') {
      setBusinessAccount(true);
    }
    if (name === 'phone') {
      if (value.length < 10) {
        setBusinessMobileVerify(false);
      } else {
        setBusinessMobileVerify(true);
      }
    }
    setState((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    if (searchParams.get('business')) {
      setState({ ...state, business: searchParams.get('business') });
      setBusinessAccount(true);
    }
    const getData = async () => {
      setBusiness(await getBusiness(serialize({ page: 'all' })));
    };
    getData();
  }, [setBusiness, setState, searchParams]);
  const navigate = useNavigate();
  // const user = useSelector((state) => state.auth).user;
  const onSubmit = async (data) => {

    if (user.role === "employee") {
      return
    }

    data.business = user.role === 'business' ? user._id : state.business;
    data.language = state.language;
    data.phone = state.phone;

    if (state?.ip_address?.length > 0) {
      data.ip_address = state.ip_address;
    } else {
      data.ip_address = [];
    }

    if (state.account_expiry)
      data.account_expiry = state.account_expiry;
    const formdata = new FormData();
    Object.keys(data).map((obj) => {
      return formdata.append(
        obj,
        typeof data[obj] === 'object'
          ? JSON.stringify(data[obj])
          : data[obj],
      );
    });

    if (image)
      formdata.append('logo', image);

    setLoading(true);
    if (id) {
      await updateEmployee(id, formdata, navigate);
      setLoading(false);
    } else {
      await createEmployee(formdata, navigate);
      setLoading(false);
    }
  };

  const suspendAccount = async () => {
    let object = { ...state, active: !state.active };
    object.ip_address = JSON.stringify(object.ip_address);
    setLoading(true);
    await updateEmployee(id, object, navigate);
    setLoading(false);
  };

  const employeeDelete = async () => {
    setLoading(true);
    await removeEmployee(id, navigate);
    setLoading(false);
  }

  return (
    <>
      <Dialog
        open={deleteEmployee}
        onClose={() => setdeleteEmployee(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {lan.Are_your_sure_you_want_to_delete_this_employee}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {lan.The_documents_created_by_this_employee_will_also_be_deleted}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdeleteEmployee(false)}>{lan.No}</Button>
          <Button
            onClick={async () => {
              await employeeDelete();
              setdeleteEmployee(false);
            }}
            autoFocus
          >
            {lan.Yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={suspend}
        onClose={() => setSuspend(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {state.active
              ? lan.Suspend_this_employee_account
              : lan.UnSuspend_this_employee_account}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {state.active
              ? lan.This_will_block_them_for_accessing_the_portals_services
              : lan.This_will_give_back_the_access_for_the_portal}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuspend(false)}>No</Button>
          <Button
            onClick={async () => {
              await suspendAccount();
              setSuspend(false);
            }}
            autoFocus
          >
            {lan.Yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item sx={{ py: 2, px: 3 }}>
          <Typography color='primary' fontSize={25} fontWeight={600}>
            {id ? lan.Edit : lan.Add} {lan.Employee}
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            item
            container
            xs={12}
            sx={{ height: '91vh', overflowY: 'scroll', px: 3, pb: 3 }}
            spacing={2}
          >
            <Grid container item xs={12} spacing={2} alignItems='center'>
              <input
                type='file'
                accept='image/jpg, image/png'
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setImageURL(URL.createObjectURL(e.target.files[0]));
                  e.target.value = '';
                }}
                style={{ display: 'none' }}
                ref={ref}
              />
              <Grid item>
                <IconButton
                  sx={{ width: 120, height: 120 }}
                  onClick={() => ref.current.click()}
                >
                  <Avatar src={imageURL} sx={{ width: 120, height: 120 }}></Avatar>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography color='primary'>({lan.upto_2mb})</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.First_Name}*</Typography>
              <TextField
                fullWidth
                {...register('first_name', { required: lan.Required_field })}
                sx={{ py: 1 }}
                placeholder={lan.Enter_First_Name}
              />
              <ErrorMessage
                errors={errors}
                name="first_name"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
              {/* <TextField
              required
              fullWidth
              name='first_name'
              onChange={handleChange}
              sx={{ py: 1 }}
              placeholder='Enter First Name'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Last_Name}*</Typography>
              <TextField
                fullWidth
                {...register('last_name', { required: lan.Required_field })}
                sx={{ py: 1 }}
                placeholder={lan.Enter_Last_Name}
              />
              <ErrorMessage
                errors={errors}
                name="last_name"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
              {/* <TextField
              required
              fullWidth
              name='last_name'
              onChange={handleChange}
              sx={{ py: 1 }}
              placeholder='Enter Last Name'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Email_address}*</Typography>
              <TextField
                {...register('email', {
                  required: lan.Email_is_required, pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: lan.Invalid_email_address
                  }
                })
                }
                fullWidth
                sx={{ py: 1 }}
                placeholder={lan.Enter_your_Email}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
              {/* <TextField
              required
              name='email'
              onChange={handleChange}
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Email'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Mobile_No}*</Typography>
              <MuiPhoneNumber
                disableAreaCodes
                defaultCountry='ca'
                variant='outlined'
                fullWidth
                disabled={Boolean(id)}
                sx={{ py: 1 }}
                value={state.phone}
                onChange={(e, data) => {
                  console.log('e>>>>>>', e, data)
                  handleChange({ target: { value: e, name: 'phone' } })
                }}
                name='phone'
                placeholder={lan.Enter_your_Phone}
              />
              {
                FormSubmitted && !BusinessMobileVerify ? (
                  <span className='text-red'>{lan.Mobile_number_is_Mandatory}</span>
                ) : ''
              }
              {/* <MuiPhoneNumber
              disableAreaCodes
              variant='outlined'
              defaultCountry='ca'
              name='phone'
              onChange={(e) =>
                handleChange({ target: { value: e, name: 'phone' } })
              }
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Phone'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Set_Language}</Typography>
              <Select
                sx={{ mt: 1 }}
                name='language'
                value={language}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value='english'>{lan.English}</MenuItem>
                <MenuItem value='canadian'>{lan.Canadian_French}</MenuItem>
              </Select>
              {/* <TextField
              required
              name='language'
              onChange={handleChange}
              sx={{ py: 1 }}
              fullWidth
              select
            >
              <MenuItem value='english'>English</MenuItem>
              <MenuItem value='canadian'>Canadian French</MenuItem>
            </TextField> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Expiry_Date}</Typography>

              <TextField
                name='account_expiry'
                onChange={handleChange}
                sx={{ py: 1 }}
                fullWidth
                type='date'
                value={state.account_expiry ? moment(state.account_expiry).format('YYYY-MM-DD') : ''}
              />
            </Grid>
            {localStorage.getItem('role') === 'admin' && (
              <Grid item xs={12} md={4}>
                <Typography>{lan.Select_Business}*</Typography>

                <TextField
                  fullWidth
                  name='business'
                  placeholder={lan.Select_Business}
                  onChange={handleChange}
                  sx={{ py: 1 }}
                  select
                  disabled={Boolean(searchParams.get('business'))}
                  value={state.business}
                >
                  {business.map((obj) => {
                    return (
                      <MenuItem value={obj._id} key={obj._id}>
                        {obj.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {
                  FormSubmitted && !BusinessAccount ? (
                    <span className='text-red'>{lan.Business_is_Mandatory}</span>
                  ) : ''
                }
              </Grid>
            )}
            <Grid item xs={12} md={12} container>
              <Grid
                item
                xs={12}
                // justifyContent='space-between'
                alignItems='center'
                container
              >
                <Grid item xs={1}>
                  <Typography>{lan.IP_Address}</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={(theme) => ({
                      backgroundColor: theme.custom.primary,
                      color: 'white',
                      height: 25,
                      width: 25,
                      '&:hover': {
                        backgroundColor: theme.custom.secondary,
                        color: 'white',
                      },
                    })}
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        ip_address: [...state.ip_address, ''],
                      }));
                    }}
                  >
                    <Add fontSize={'15'} />
                  </IconButton>
                </Grid>
              </Grid>
              {state.ip_address.map((obj, index) => {
                return (
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    spacing={1}
                    key={index}
                    xs={12}
                    md={4}
                  >
                    {
                      obj ? (
                        <>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              name='ip_address'
                              value={obj ? obj : ''}
                              disabled={Boolean(obj)}
                              sx={{ py: 1 }}
                              placeholder={lan.Enter_your_IP_Address}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                setState((state) => ({
                                  ...state,
                                  ip_address: state.ip_address.filter(
                                    (item, i) => i !== index,
                                  ),
                                }));
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              name='ip_address'
                              onChange={(e) => {
                                console.log('e >>>>>', e, e.target.value);
                                obj = e.target.value;
                                setState((state) => {
                                  state.ip_address[index] = e.target.value;
                                  return state;
                                });
                              }}
                              sx={{ py: 1 }}
                              placeholder={lan.Enter_your_IP_Address}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                setState((state) => ({
                                  ...state,
                                  ip_address: state.ip_address.filter(
                                    (item, i) => i !== index,
                                  ),
                                }));
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Grid>
                        </>
                      )
                    }
                  </Grid>
                );
              })}
            </Grid>
            {
              localStorage.getItem('role') !== 'employee' &&
              <Grid item container xs={12} spacing={1}>
                <Grid item>
                  <Chip
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setSuspend(true)}
                    icon={
                      state.active ? (
                        <img src={deleteSvg} alt="delete"/>
                      ) : (
                        <DoneIcon style={{ color: theme.custom.primary }} />
                      )
                    }
                    variant='outlined'
                    label={state.active ? lan.Suspend_User : lan.Unsuspend_User}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setdeleteEmployee(true)}
                    icon={<img src={deleteSvg} alt="delete"/>}
                    variant='outlined'
                    label={lan.Block_User}
                  />
                </Grid>
              </Grid>
            }
            <Grid item xs={12} container justifyContent={'center'}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={loading}
                onClick={() => { setFormSubmitted(true); }}
                sx={{ padding: '5px 50px 5px 50px', color: 'white' }}
              >
                {lan.Save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {
        loading ? (
          <Grid item xs={12} sx={{ height: '60vh', overflowY: 'hidden' }}>
            <Loader />
          </Grid>
        ) : ''
      }
    </>
  );
};

export default AddEmployee;
