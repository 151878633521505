import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from 'pages/Login';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ConsumerSign from './routes/ConsumerSign';
import ShareDocumentRoutes from './routes/ShareDocument';
import { ThemeProvider, createTheme } from '@mui/material';

import Admin from 'routes/Admin';
import Dashboard from 'routes/Dashboard';
import ForgotPassword from 'pages/Forgot Password';
import ResetPassword from 'pages/Reset Password';
import Document from 'routes/Document';
function App() {
  const styles = createTheme({
    palette: {
      primary: { main: '#6DBD44', color: '#FFFFFF' },
      secondary: { main: '#35974A', color: '#FFFFFF' },
      highlight: { main: '#499A1F', color: '#FFFFFF' },
    },
    typography: {
      fontFamily: 'MontSerrat',
    },
    custom: {
      primary: '#6DBD44',
      secondary: '#35974A',
      highlight: '#499A1F',
    },
  });
  return (
    <ThemeProvider theme={styles}>
      <Router>
        <Routes>
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/admin/*' element={<Admin />} />
          <Route path='/dashboard/*' element={<Dashboard />} />
          <Route path='/document/*' element={<Document />} />
          <Route path='/login' element={<Login />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/' element={<Login />} />
          <Route
            path='/sign-consumer/:id/:token/*'
            element={<ConsumerSign />}
          />
          <Route
            path='/share-document/:id/:token/*'
            element={<ShareDocumentRoutes />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
