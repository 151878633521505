const jsonToNestedArray = (array = []) => {
    console.log('jsonToNestedArray in >>>', array)
    if (array.length === 0)
        return [];

    let json = [];
    array.forEach((attribute) => {
        if (!json[attribute.pageNo]) json[attribute.pageNo] = [];
        json[attribute.pageNo].push(attribute);
    })

    return json;
};

export default jsonToNestedArray;
