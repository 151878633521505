const reducer = (
  state = localStorage.getItem('profile')
    ? { user: JSON.parse(localStorage.getItem('profile')) }
    : null,
  actions,
) => {
  switch (actions.type) {
    case 'LOGIN':
      localStorage.setItem('profile', JSON.stringify(actions.data.user));
      localStorage.setItem('token', actions.data.token);
      localStorage.setItem('role', actions.data.user.role);
      localStorage.setItem('language', actions.data?.user?.language);
      return { ...actions.data };
    case 'UPDATE_PROFILE':
      localStorage.setItem(
        'profile',
        JSON.stringify({
          ...actions.data,
          role: actions?.data?.role || 'employee',
        }),
      );
      localStorage.setItem('language', actions.data.language);

      return {
        user: { ...actions.data, role: actions.data.role || 'employee' },
      };
    case 'LOGOUT':
      localStorage.clear();
      return null;
    default:
      return state;
  }
};

export default reducer;
