import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  Chip,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box
} from '@mui/material';
// import { toast } from 'react-toastify';
import { createBusiness } from 'actions/business';
import { useNavigate, useParams } from 'react-router';
import MuiPhoneNumber from 'mui-phone-number';
import { Add, Close } from '@mui/icons-material';
import deleteSvg from 'assets/misc/delete.svg';
import DoneIcon from '@mui/icons-material/Done';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
// import baseurl from 'api/baseurl';
import { useSelector } from 'react-redux';
import { getBusinessByID } from 'actions/business';

import { updateBusiness } from 'actions/business';
import { deleteBusiness } from 'actions/business';

import Loader from 'components/misc/Loader';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const EditBusiness = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const user = useSelector((state) => state.auth).user;
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    language: 'english',
    ip_address: [''],
    owner_info: {
      name: '',
      email: '',
      phone: '',
    },
  });
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  const [language, setLanguage] = useState('english');
  const [FormSubmitted, setFormSubmitted] = useState(false);
  const [BusinessMobileVerify, setBusinessMobileVerify] = useState(false);
  const [OwnerMobileVerify, setOwnerMobileVerify] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [suspend, setSuspend] = useState(false);
  const [deleteEmployee, setdeleteEmployee] = useState(false);
  const [IsEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      console.log(id, user._id);
      let data;
      if (id) {
        setIsEdit(true);
        data = await getBusinessByID(id);
      } else {
        setIsEdit(true);
        data = await getBusinessByID(user._id);
      }
      setValue('name', data.name);
      setValue('email', data.email);
      setValue('address', data.address);
      setLanguage(data.language);
      setValue('owner_name', data.owner_info.name);
      setValue('owner_email', data.owner_info.email);

      if (data.phone)
        setBusinessMobileVerify(true)

      if (data.owner_info.phone)
        setOwnerMobileVerify(true)

      setState(data);
      setImageURL(data.logo);
      setImage(data.logo);
      setLoading(false);
    };
    if (localStorage.getItem('role') === 'business' || id)
      getData();
  }, [setState, setImageURL, id, user]);

  console.log('id >>>>>', id);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('handleChange >>>>', e)
    if (e.target.name === 'language') {
      setLanguage(value);
    } else {
      if (value.length < 10)
        setBusinessMobileVerify(false);
      setState((state) => ({ ...state, [name]: value }));
    }
  };
  const handleOwner = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      owner_info: { ...state.owner_info, [name]: value },
    }));
  };

  const onSubmit = async (data) => {
    setFormSubmitted(true);
    if (!BusinessMobileVerify || !OwnerMobileVerify) {
      return false;
    }
    data.owner_info = {
      name: data.owner_name || '',
      email: data.owner_email || '',
      phone: state.owner_info.phone || ''
    }

    if (state.ip_address)
      data.ip_address = state.ip_address;
    if (language)
      data.language = language;
    if (state.phone)
      data.phone = state.phone;

    delete data.owner_name;
    delete data.owner_email;
    console.log('Final data >>>>', data);
    setLoading(true);

    const formdata = new FormData();
    Object.keys(data).map((obj) => {
      return formdata.append(
        obj,
        typeof data[obj] === 'object'
          ? JSON.stringify(data[obj])
          : data[obj],
      );
    });
    if (image)
      formdata.append('logo', image);


    if (IsEdit) {
      formdata.append('active', true);
      await updateBusiness(
        localStorage.getItem('role') === 'admin' ? id : user._id,
        formdata,
        navigate,
      );
      // toast.success('Business Updated');
    } else {
      await createBusiness(formdata, navigate);
    }

    setLoading(false);
  };

  const suspendAccount = async () => {
    const formdata = new FormData();
    formdata.append('active', !state['active']);
    formdata.append('name', state.name);
    formdata.append('email', state.email);
    formdata.append('phone', state.phone);
    formdata.append('ip_address', JSON.stringify(state.ip_address));
    let owner_info = state.owner_info || {};
    formdata.append('owner_info', JSON.stringify(owner_info));
    setLoading(true);
    await updateBusiness(
      localStorage.getItem('role') === 'admin' ? id : user._id,
      formdata,
      navigate,
    );
    setLoading(false);
  };
  const employeeDelete = async () => {
    setLoading(true);
    await deleteBusiness(state._id, navigate);
    setLoading(false);
  };

  const theme = useTheme();
  return (
    <>
      <Dialog
        open={deleteEmployee}
        onClose={() => setdeleteEmployee(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {lan.Are_your_sure_you_want_to_delete_this_business}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {lan.The_employees_and_the_documents_created_by_them_will_also_be_deleted}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdeleteEmployee(false)}>{lan.No}</Button>
          <Button
            onClick={async () => {
              await employeeDelete();
              setdeleteEmployee(false);
            }}
            autoFocus
          >
            {lan.Yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={suspend}
        onClose={() => setSuspend(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
        {state.active
              ? lan.Suspend_this_business_account
              : lan.Activate_this_business_account}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {state.active
              ? lan.This_will_stop_them_for_accessing_the_portal_services
              : lan.This_will_give_access_again_for_the_portal}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuspend(false)}>No</Button>
          <Button
            onClick={async () => {
              await suspendAccount();
              setSuspend(false);
            }}
            autoFocus
          >
            {lan.Yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12} sx={{ px: 3, py: 4.5, height: '100px', backgroundColor: '#ffffff', zIndex: 1 }} >
          <Typography color='primary' fontSize={25} fontWeight={600}>
            {lan.Business_Profile}
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            item
            container
            xs={12}
            sx={{ height: '85vh', overflow: 'scroll', px: 3, pb: 3 }}
            spacing={2}
          >
            <Grid container item xs={12} spacing={2} alignItems='center'>
              <input
                type='file'
                accept='image/jpg, image/png'
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setImageURL(URL.createObjectURL(e.target.files[0]));
                  e.target.value = '';
                }}
                style={{ display: 'none' }}
                ref={ref}
              />
              <Grid item>
                <IconButton
                  sx={{ width: 120, height: 120 }}
                  onClick={() => ref.current.click()}
                >
                  <Avatar src={imageURL} sx={{ width: 120, height: 120 }}></Avatar>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography color='primary'>({lan.upto_2mb})</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Business_Name}*</Typography>
              <TextField
                fullWidth
                {...register('name', { required: lan.Required_field })}
                sx={{ py: 1 }}
                placeholder={lan.Enter_Your_Name}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
              {/* <TextField
              required
              name='name'
              onChange={handleChange}
              fullWidth
              sx={{ py: 1 }}
              placeholder='Enter your Name'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Business_Email}*</Typography>
              <TextField
                {...register('email', {
                  required: lan.Email_is_required, pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: lan.Invalid_email_address
                  }
                })
                }
                fullWidth
                sx={{ py: 1 }}
                placeholder={lan.Enter_your_Email}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
              {/* <TextField
              required
              fullWidth
              sx={{ py: 1 }}
              name='email'
              type='email'
              onChange={handleChange}
              placeholder='Enter your Email'
            /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Business_Mobile_Number}*</Typography>
              {/* <MuiPhoneNumber
              disableAreaCodes
              defaultCountry='ca'
              variant='outlined'
              required
              fullWidth
              sx={{ py: 1 }}
              name='phone'
              onChange={(e) =>
                handleChange({ target: { value: e, name: 'phone' } })
              }
              placeholder='Enter your Phone'
            /> */}
              <MuiPhoneNumber
                disableAreaCodes
                defaultCountry='ca'
                variant='outlined'
                fullWidth
                disabled={IsEdit}
                sx={{ py: 1 }}
                value={state.phone}
                onChange={(e, data) => {
                  console.log('e>>>>>>', e, data)
                  setBusinessMobileVerify(true);
                  handleChange({ target: { value: e, name: 'phone' } })
                }}
                name='phone'
                placeholder={lan.Enter_your_Phone}
              />
              {
                FormSubmitted && !BusinessMobileVerify ? (
                  <span className='text-red'>{lan.Mobile_number_is_Mandatory}</span>
                ) : ''
              }
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Address}</Typography>
              {/* <TextField
              required
              fullWidth
              name='address'
              onChange={handleChange}
              sx={{ py: 1 }}
              placeholder='Enter your Address '
            /> */}
              <TextField
                {...register('address')}
                fullWidth
                sx={{ py: 1 }}
                placeholder={lan.Enter_your_Address}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Set_Language}</Typography>
              <Select
                sx={{ mt: 1 }}
                name='language'
                value={language}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value='english'>{lan.English}</MenuItem>
                <MenuItem value='canadian'>{lan.Canadian_French}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12} container>
              <Grid
                item
                xs={12}
                // justifyContent='space-between'
                alignItems='center'
                container
              >
                <Grid item xs={1}>
                  <Typography>{lan.IP_Address}</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={(theme) => ({
                      backgroundColor: theme.custom.primary,
                      color: 'white',
                      height: 25,
                      width: 25,
                      '&:hover': {
                        backgroundColor: theme.custom.secondary,
                        color: 'white',
                      },
                    })}
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        ip_address: [...state.ip_address, ''],
                      }));
                    }}
                  >
                    <Add fontSize={'15'} />
                  </IconButton>
                </Grid>
              </Grid>
              {state.ip_address.map((obj, index) => {
                return (
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    spacing={2}
                    key={index}
                    xs={12}
                    md={4}
                  >
                    <Grid item xs={10} md={10}>
                      <TextField
                        fullWidth
                        name='ip_address'
                        onChange={(e) => {
                          setState((state) => {
                            state.ip_address[index] = e.target.value;
                            return state;
                          });
                        }}
                        value={obj}
                        sx={{ py: 1 }}
                        placeholder={lan.Enter_your_IP_Address}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => {
                          setState((state) => ({
                            ...state,
                            ip_address: state.ip_address.filter(
                              (item, i) => i !== index,
                            ),
                          }));
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={12}>
              <Typography color='primary' sx={{ borderBottom: '1px solid' }} fontSize={25} fontWeight={600}>
                {lan.Owner_Info}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Name}*</Typography>
              {/* <TextField
              required
              fullWidth
              sx={{ py: 1 }}
              name='name'
              onChange={handleOwner}
              placeholder='Enter your Name'
            /> */}
              <TextField
                fullWidth
                {...register('owner_name', { required: lan.Required_field })}
                sx={{ py: 1 }}
                placeholder={lan.Enter_Your_Name}
              />
              <ErrorMessage
                errors={errors}
                name="owner_name"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography>{lan.Email_address}*</Typography>
              {/* <TextField
              required
              fullWidth
              sx={{ py: 1 }}
              name='email'
              onChange={handleOwner}
              placeholder='Enter your Email'
            /> */}
              <TextField
                {...register('owner_email', {
                  required: lan.Email_is_required, pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: lan.Invalid_email_address
                  }
                })
                }
                fullWidth
                sx={{ py: 1 }}
                placeholder={lan.Enter_owner_email}
              />
              <ErrorMessage
                errors={errors}
                name="owner_email"
                render={({ message }) => <span className='text-red'>{message}</span>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{lan.Mobile_Number}*</Typography>
              <MuiPhoneNumber
                disableAreaCodes
                disabled={Boolean(id)}
                variant='outlined'
                defaultCountry='ca'
                name='phone'
                value={state.owner_info.phone}
                onChange={(e) => {
                  setOwnerMobileVerify(true);
                  handleOwner({ target: { value: e, name: 'phone' } })
                }}
                fullWidth
                sx={{ py: 1 }}
                placeholder={lan.Enter_your_Phone}
              />
              {
                FormSubmitted && !OwnerMobileVerify ? (
                  <span className='text-red'>{lan.Mobile_number_is_Mandatory}</span>
                ) : ''
              }
            </Grid>
            {localStorage.getItem('role') === 'admin' && id && (
              <Grid item container xs={12} spacing={1}>
                <Grid item>
                  <Chip
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setSuspend(true)}
                    icon={
                      state.active ? (
                        <img src={deleteSvg} alt="delete"/>
                      ) : (
                        <DoneIcon style={{ color: theme.custom.primary }} />
                      )
                    }
                    variant='outlined'
                    label={
                      state.active ? lan.Suspend_Business : lan.Activate_Business
                    }
                  />
                </Grid>
                <Grid item>
                  <Chip
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setdeleteEmployee(true)}
                    icon={<img src={deleteSvg} alt="delete"/>}
                    variant='outlined'
                    label={lan.Block_Business}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} container justifyContent={'center'}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={() => { setFormSubmitted(true); }}
                sx={{ padding: '5px 50px 5px 50px', color: 'white' }}
              >
                {lan.Save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {
        loading ? (
          <Box>
            <Loader />
          </Box>
        ) : ''
      }
    </>
  );
};

export default EditBusiness;
