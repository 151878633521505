import {
  Grid,
  Typography,
  useTheme,
  Box,
  TextField,
  Button,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

import { Link, useNavigate } from 'react-router-dom';
import BusinessCard from 'components/admin/business_card';
import { getBusiness } from 'actions/business';
import serialize from 'utils/serialize';
import Loader from 'components/misc/Loader';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const ManageBusiness = () => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [business, setBusiness] = useState({
    results: [],
    page: 0,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
  });
  const [page, setPage] = useState(1);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });
  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      setBusiness(
        await getBusiness(
          serialize({
            page: search.length > 0 ? 1 : page,
            limit: 5,
            ...(search.length > 0 ? { search } : {}),
          }),
        ),
      );
    };
    getData();
  }, [setBusiness, page, search]);
  console.log(search);
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box>
      <Grid
        item
        sx={{ px: 3, py: 4.5, height: '100px' }}
        container
        alignItems='center'
        xs={12}
        justifyContent='space-between'
      >
        <Grid item xs={9}>
          <Typography color='primary' fontSize={20} fontWeight={600}>
            {lan.Manage_Business}
          </Typography>
        </Grid>
        <Grid item>
          {
            business.totalResults > 0 ? (
              <CSVLink
                headers={[
                  { label: lan.Name, key: 'name' },
                  { label: lan.Email, key: 'email' },
                  { label: lan.Phone, key: 'phone' },
                  { label: lan.Address, key: 'address' },
                  { label: lan.Language, key: 'language' },
                  { label: lan.IP_Address, key: 'ip_address' },
                  { label: lan.Owner_Name, key: 'owner_info.name' },
                  { label: lan.Owner_phone, key: 'owner_info.phone' },
                  { label: lan.Owner_email, key: 'owner_info.email' },
                ]}
                data={business.results}
                filename={lan.jump_sign_business}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Typography
                  fontSize={20}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  fontWeight={400}
                >
                  <DownloadIcon style={{ marginRight: 6 }} /> {lan.Export_CSV}
                </Typography>
              </CSVLink>
            ) : ''
          }
          {/* </CSVDownload> */}
        </Grid>
        <Grid item>
          <Typography
            component={Link}
            to='/admin/add-business'
            fontSize={20}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'black',
            }}
            fontWeight={400}
          >
            <AddCircleOutlineIcon
              sx={{ color: theme.custom.primary, marginRight: 1 }}
            />{' '}
            {lan.Add}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{ px: 3 }}
      >
        {
          business.totalResults > 0 ? (
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                InputProps={{
                  style: { borderRadius: '4px' },
                  startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                }}
                placeholder={lan.Search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          ) : ''
        }
        {
          loading ? (
            <Grid item xs={12} sx={{ height: '60vh', overflowY: 'hidden' }}>
              <Loader />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ pr: 0, height: '60vh', overflowY: 'hidden' }}>
              {business.results.map((obj) => {
                return (
                  <BusinessCard
                    key={obj._id}
                    data={obj}
                    onClick={() => navigate('/admin/manage-business/' + obj._id)}
                  />
                );
              })}
              {
                business.totalResults === 0 ? (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50vh',
                    alignItems: 'center'
                  }}>
                    {lan.No_Business_Added}
                  </Box>
                ) : ''
              }
            </Grid>
          )
        }
        {business.totalPages > 1 && (
          <Grid
            item
            container
            xs={12}
            spacing={3}
            justifyContent='flex-end'
            alignItems={'center'}
          >
            <Grid item>
              <Button
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
                variant='text'
              >
                {lan.Previous}
              </Button>
            </Grid>
            <Grid item>
              <Typography color='primary'>{page}</Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={page === business.totalPages}
                onClick={() => setPage(page + 1)}
                variant='text'
              >
                {lan.Next}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ManageBusiness;
