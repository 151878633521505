import { getemployee } from 'api/employee';
import { getemployeeall } from 'api/employee';
import { getemployeebyid } from 'api/employee';
import { createemployee } from 'api/employee';
import { toast } from 'react-toastify';
import { updateemployee } from 'api/employee';
import { sendotpemployee } from 'api/employee';
import { loginemployee } from 'api/employee';
import { deletemployee } from 'api/employee';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export const createEmployee = async (formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await createemployee(formdata);
    console.log(data);
    toast.success(lan.Employee_Created_Sucessfully);
    localStorage.getItem('role') === 'business' ? router('/dashboard/manage-employee') : router('/admin/manage-employee')
  } catch (error) {
    console.log(error.response.data.error);
    toast.error(error.response.data.error);
  }
};

export const getEmployee = async (id, query) => {
  const { data } = await getemployee(id, query);
  return data.employee;
};

export const getEmployeeById = async (id) => {
  const { data } = await getemployeebyid(id);

  return data.employee;
};

export const updateEmployee = async (id, formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await updateemployee(id, formdata);
    toast.success(lan.Employee_Updated_Sucessfully);
    if (router) router(-1);
    return data.employee;
  } catch (error) {
    console.log(error);
    toast.error(lan.Something_went_wrong);
  }
};

export const getEmployeeAll = async (query) => {
  const { data } = await getemployeeall(query);
  return data.employee;
};

export const sendOTP = async (formdata, state) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await sendotpemployee(formdata);
    console.log(data);
    toast.success(lan.OTP_Sent);
    state();
  } catch (error) {
    console.log(error, error);
    if (error?.response?.status === 403) {
      toast.error(lan.Account_blocked_please_contact_the_owner);
    } else {
      console.log(error?.response?.data, error?.response?.data?.error);
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(lan.Invalid_Credentials);
      }
    }
  }
};

export const login = (formdata, router) => async (dispatch) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await loginemployee(formdata);
    dispatch({ type: 'LOGIN', data });
    if (data.user.role === 'employee') router('/dashboard/');
    else {
      router('/dashboard/documents');
    }
  } catch (error) {
    toast.error(lan.Invalid_OTP);
  }
};

export const removeEmployee = async (id, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    await deletemployee(id);
    toast.success(lan.Employee_Deleted_Successfully);
    router(-1);
  } catch (error) {
    console.log(error);
    toast.error(lan.Something_went_wrong);
  }
};
