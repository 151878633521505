import React, { 
  // useEffect, 
  useRef, 
  useState 
} from 'react';
import {
  Box,
  Drawer,
  useMediaQuery,
  IconButton,
  TextField,
  // Grid,
  Typography,
  Modal,
  // MenuItem,
  Button,
  // Select,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Routes, Route, Navigate, useNavigate } from 'react-router';
import Sidebar from 'components/document-sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from 'sidebar-tabs/document';
import { useSelector } from 'react-redux';
import Signature from 'pages/Document/Signature';
import JsPDF from 'jspdf';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Home from 'pages/Document/Home';
// import { getEmployee } from 'actions/employee';
// import serialize from 'utils/serialize';
import { createDocument } from 'actions/document';
import Review from 'pages/Document/Review';
import baseurl from 'api/baseurl';
import Loader from 'components/misc/Loader';
import nestedArrayToJson from 'utils/nestedArrayToJson';
import jsonToNestedArray from 'utils/jsonToNestedArray';

import PdfPreview from '../pages/Document/pdfPreview';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const drawerWidth = 300;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};
export default function DashboardRoutes() {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const generatedPages = [];
  const convaRef = useRef();
  const [urls, setURLS] = useState([]);
  const [attributeArray, setAttributeArray] = useState([]);
  const [editDocumentId, setEditDocumentId] = useState('');
  const [allAttributeArray, setAllAttributeArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState(false);
  const [StatusModal, setStatusModal] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());
  // const [sendTo, setSendTo] = useState([]);
  const small = useMediaQuery('(max-width:900px)');
  const user = useSelector((state) => state.auth)?.user;
  const file = useSelector((state) => state.file).file;
  // const [employees, setEmployees] = useState([]);
  const [images, setImages] = React.useState([]);
  const [arrayImages, setArrayImages] = useState([]);
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState('');

  const [isPreviewModal, setPreviewModal] = React.useState(false);
  const [createDocumentData, setCreateDocumentData] = React.useState({});

  // const [email, setEmail] = useState('');
  // const [creds, setCreds] = useState({});
  const [signed, setSigned] = useState(false);

  // console.log('file >>>>>>>', file, useSelector((state) => state.file));
  const updateCurrentPageImage = () => {
    setURLS((state) => {
      if (convaRef.current) state[page] = convaRef.current.toDataURL(null, 1);
      return state;
    });
  };
  const savePDf = () => {
    updateCurrentPageImage();

    const pdf = new JsPDF('p', 'mm', 'a4');
    pdf.internal.scaleFactor = 3;
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    urls.map((image, index) => {
      pdf.addImage(image, 'PNG', 0, 0, width, height, `${index}`);
      if (index !== urls.length - 1) {
        pdf.addPage();
      }
    });
    pdf.save(file.name);
  };

  const previewPDF = () => {
    updateCurrentPageImage();
    navigate('/document/review');
  };

  const print = () => {
    updateCurrentPageImage();
    const pdf = new JsPDF('p', 'mm', 'a4');
    pdf.internal.scaleFactor = 3;
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();

    urls.map((image, index) => {
      pdf.addImage(image, 'PNG', 0, 0, width, height, `${index}`);
      if (index !== urls.length - 1) {
        pdf.addPage();
      }
    });
    window.open(pdf.output('bloburl', file.name), '_blank');
  };
  // useEffect(() => {
  //   const getData = async () => {
  //     setEmployees(
  //       await getEmployee(user.business._id, serialize({ page: 'all' })),
  //       axios
  //         .get(baseurl + '/creds', {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem('token')}`,
  //           },
  //         })
  //         .then((res) => {
  //           setCreds(res.data);
  //         }),
  //     );
  //   };
  //   getData();
  // }, [setEmployees, user]);
  const navigate = useNavigate();

  /**
  * Create Document
  */
  const saveDocument = async (params) => {
    console.log('saveDocument >>>>', params, attributeArray);
    setLoading(true);
    // updateCurrentPageImage();
    let attributes = nestedArrayToJson(attributeArray || []);
    console.log('attributes >>>>', attributes);
    let sendTo = [];
    let email = '';
    if (params?.isShare === 1) {
      delete params?.isShare;
      attributes.forEach((attribute) => {
        // console.log('user.email >>>>', user.email);
        if (user.email !== attribute.inviteEmail) {
          if (attribute.isCustomer) {
            email = attribute.inviteEmail;
          } else {
            if (sendTo.indexOf(attribute.employeeId) === -1)
              sendTo.push(attribute.employeeId);
          }
        }
      });
      // console.log('In IF >>>>>>', attributes, sendTo);
    }
    // console.log('console.log >>>>>>', sendTo, email, params, { send_to: sendTo, email, ...params }, urls);
    const formdata = {
      // expiry: expiryDate,
      // status: 1,
      ...{ send_to: sendTo, email, ...params },
      image: urls,
      document_name: file.name,
      attribute_array: attributes || []
    };
    console.log('formdata >>>>', formdata);

    // const { createDocumentData } = await createDocument(formdata, navigate);
    createDocument(formdata).then((createDocumentData) => {
      console.log('createDocumentData >>>>>>', createDocumentData);
      if (createDocumentData.status === 2) {
        setCreateDocumentData(createDocumentData);
        // setLoading(false);
        localStorage.removeItem('images');
        localStorage.removeItem('all_images');
        setPreviewModal(true);
      } else {
        localStorage.removeItem('images');
        localStorage.removeItem('all_images');
        setLoading(false);
        toast.success(lan.Document_Created_Sucessfully);
        navigate('/dashboard/documents');
      }
    });
  };

  /**
  * Edit Document Function
  */
  const editDocument = () => {
    console.log('editDocument >>>>', attributeArray);
    setLoading(true);
    // updateCurrentPageImage();

    let attributes = nestedArrayToJson(attributeArray || []);
    if (attributes) {
      let index = 0;
      attributes.forEach(element => {
        if (element.inviteEmail === user.email)
          if (!element.src) {
            index = 1;
          }
      });
      if (index === 1) {
        toast.warning(lan.You_have_required_field_to_fill_out);
        setLoading(false);
        return;
      }
    }
    console.log('attributes >>>', attributes);

    // let allAttributes = [];
    // let indexObj = {};
    // allAttributeArray.forEach((attr) => {
    //   indexObj = {};
    //   indexObj = attributes.find((selectedAttr) => { return attr.id === selectedAttr.id });
    //   if (indexObj?.id) {
    //     allAttributes.push(indexObj);
    //   } else {
    //     // allAttributes.push(attr);
    //   }
    // });
    // console.log('attributes >>>>', allAttributes);

    const formdata = {
      document_id: editDocumentId,
      image: urls,
      document_name: file.name,
      attribute_array: attributes || []
    };
    console.log('formdata >>>>', formdata);
    editDocumentAPI(formdata);
  }

  /**
  * Edit Document API
  */
  const editDocumentAPI = (data) => {
    // console.log('editDocumentAPI data >>>>', data)
    setLoading(true);
    axios
      .put(
        baseurl + '/document/update',
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      .then((res) => {
        // localStorage.removeItem('images');
        // localStorage.removeItem('all_images');
        // setLoading(false);
        // navigate('/dashboard/documents');
        console.log('res >>>>>>', res.data.document);
        const data = res.data.document;
        if (data.status === 2) {
          setCreateDocumentData(data);
          // setLoading(false);
          localStorage.removeItem('images');
          localStorage.removeItem('all_images');
          setPreviewModal(true);
        } else {
          localStorage.removeItem('images');
          localStorage.removeItem('all_images');
          setLoading(false);
          toast.success(lan.Document_Updated_Sucessfully);
          navigate('/dashboard/documents');
        }
      });
  }

  const back = () => {
    console.log('back >>>>>');
    localStorage.removeItem('images');
    localStorage.removeItem('all_images');
    navigate('/dashboard/documents');
  }
  return (
    <Routes>
      <Route
        path='*'
        element={
          // 
          user?.role === 'employee' && file ? (
            <Box
              sx={{
                display: 'flex',
                ...(small ? { flexDirection: 'column' } : {}),
                backgroundColor: '#F5F5F5',
              }}
            >
              <Drawer
                open={expanded}
                onClose={() => setExpanded(false)}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant={small ? 'temporary' : 'permanent'}
                anchor='left'
              >
                {expiry ? (
                  <Box sx={{ p: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label='Expiry date and time'
                        // value={expiryDate}
                        onChange={(e) => setExpiryDate(e.$d)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ backgroundColor: 'white' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <></>
                )}
                <Sidebar>
                  <Dashboard
                    setExpanded={setExpanded}
                    downloadPDF={savePDf}
                    setExpiry={setExpiry}
                    print={print}
                    setModal={setModal}
                    previewPDF={previewPDF}
                    signed={signed}
                    setSigned={setSigned}
                    setStatusModal={setStatusModal}
                    back={back}
                    editDocumentId={editDocumentId}
                    editDocument={editDocument}
                  />
                </Sidebar>
              </Drawer>
              {small && (
                <Box component='nav' display='flex' alignItems={'flex-start'}>
                  <IconButton onClick={() => setExpanded(true)}>
                    <MenuIcon
                      color='primary'
                      style={{
                        fontSize: 30,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box
                component='main'
                sx={{
                  ml: 0.5,
                  // p: 3,
                  width: '100%',
                  boxSizing: 'border-box',
                  backgroundColor: '#F5F5F5',
                }}
              >
                <Modal
                  open={modal}
                  onClose={() => {
                    setModal(false);
                    setMode('');
                  }}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={style}>
                    <Typography fontSize={20} color='primary' fontWeight={500} sx={{ p: 1 }}>
                      {lan.Share_Document}
                    </Typography>
                    <Box sx={{ p: 2 }}>
                      <Box textAlign={'center'} sx={{ lineHeight: '2' }}>
                        {lan.Do_you_want_to_Share_this_Document_with_assigned_persons}?
                      </Box>
                    </Box>
                    <Box textAlign={'right'} sx={{ p: 1, pt: 2 }}>
                      <Button
                        onClick={() => setModal(false)}>
                        {lan.No}
                      </Button>
                      <Button
                        size='large'
                        sx={{ color: 'white' }}
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                          await saveDocument({ isShare: 1 })
                          setModal(false)
                        }}
                      >
                        {lan.Yes}
                      </Button>
                    </Box>
                    {/* {mode === '' ? (
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={3}
                        justifyContent='center'
                      > */}
                    {/* <Grid item container justifyContent={'center'} xs={12}>
                          <Button
                            disabled={loading}
                            onClick={() => setMode('email')}
                            fullWidth
                            color='primary'
                            variant='outlined'
                          >
                            {localStorage.getItem('language')
                              ? 'Share via Email'
                              : 'Partager via courriel'}
                          </Button>
                        </Grid> */}
                    {/* <Grid item justifyContent={'center'} container xs={12}>
                          <Button
                            disabled={loading}
                            onClick={() => setMode('employee')}
                            fullWidth
                            color='primary'
                            variant='outlined'
                          >
                            {localStorage.getItem('langauge') === 'english'
                              ? 'Share With Employees'
                              : 'Partager avec un employé'}
                          </Button>
                        </Grid> */}
                    {/* {creds?.gdrive && (
                          <Grid
                            item
                            justifyContent={'center'}
                            container
                            xs={12}
                          >
                            <Button
                              onClick={async () =>
                                await saveDocument({ drive: true })
                              }
                              disabled={loading}
                              fullWidth
                              color='primary'
                              variant='outlined'
                            >
                              {localStorage.getItem('language') === 'english'
                                ? 'Save to Google Drive'
                                : 'Sauvegarder sur Google drive'}
                            </Button>
                          </Grid>
                        )} */}
                    {/* {creds?.citrix && (
                          <Grid
                            item
                            justifyContent={'center'}
                            container
                            xs={12}
                          >
                            <Button
                              onClick={async () =>
                                await saveDocument({ citrix: true })
                              }
                              fullWidth
                              disabled={loading}
                              color='primary'
                              variant='outlined'
                            >
                              {localStorage.getItem('language') === 'english'
                                ? 'Save to Citrix'
                                : 'Sauvegarder sur Citrix'}
                            </Button>
                          </Grid>
                        )} */}
                    {/* {creds?.onedrive && (
                          <Grid
                            item
                            justifyContent={'center'}
                            container
                            xs={12}
                          >
                            <Button
                              disabled={loading}
                              onClick={async () =>
                                await saveDocument({ onedrive: true })
                              }
                              fullWidth
                              color='primary'
                              variant='outlined'
                            >
                              {localStorage.getItem('language')
                                ? 'Save to OneDrive & Share Point'
                                : 'Sauvegarder sur OneDrive'}
                            </Button>
                          </Grid>
                        )} */}
                    {/* </Grid>
                    ) : (
                      <>
                        <Typography
                          id='modal-modal-title'
                          variant='h6'
                          component='h2'
                        >
                          {mode === 'employee'
                            ? 'Select Recipients'
                            : localStorage.getItem('language') === 'english'
                              ? 'Enter Email'
                              : 'Entrer votre adresse courriel'}
                        </Typography>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent='center'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {mode === 'employee' ? (
                              <Select
                                fullWidth
                                multiple
                                value={sendTo}
                                onChange={(e) => {
                                  setSendTo(
                                    typeof e.target.value === 'string'
                                      ? e.target.value.split(',')
                                      : e.target.value,
                                  );
                                }}
                              >
                                {employees.map((obj) => {
                                  return (
                                    <MenuItem value={obj._id}>
                                      {obj.first_name} {obj.last_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            ) : (
                              <>
                                <TextField
                                  variant='outlined'
                                  fullWidth
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent={'center'}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={loading}
                              sx={{ color: 'white' }}
                              onClick={saveDocument}
                            >
                              Send PDF
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )} */}
                  </Box>
                </Modal>
                <Modal
                  open={StatusModal}
                  onClose={() => {
                    setStatusModal(false);
                    setMode('');
                  }}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={style}>
                    <Typography fontSize={20} color='primary' fontWeight={500} sx={{ p: 1 }}>
                      {lan.Finish_Document}
                    </Typography>
                    <Box sx={{ p: 2 }}>
                      <Box textAlign={'center'}>
                        {lan.Do_you_want_to_Finish_this_Document}?
                      </Box>
                    </Box>
                    <Box textAlign={'right'} sx={{ p: 1, pt: 2 }}>
                      <Button
                        onClick={() => setStatusModal(false)}>
                        {lan.No}
                      </Button>
                      <Button
                        size='large'
                        sx={{ color: 'white' }}
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                          if (editDocumentId) {
                            await editDocument({ status: 2 });
                          } else {
                            await saveDocument({ status: 2 })
                          }
                          setStatusModal(false)
                        }}
                      >
                        {lan.Yes}
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Routes>
                  <Route path='signature' element={<Signature />} />
                  <Route
                    path=''
                    element={
                      <Home
                        stageRef={convaRef}
                        images={images}
                        setImages={setImages}
                        canvasPages={generatedPages}
                        page={page}
                        setPage={setPage}
                        setMultipleImages={setArrayImages}
                        multipleImages={arrayImages}
                        setUrl={setURLS}
                        urls={urls}
                        setAttributeArray={setAttributeArray}
                        setAllAttributeArray={setAllAttributeArray}
                      />
                    }
                  />
                  <Route path='/:id'
                    element={
                      <Home
                        stageRef={convaRef}
                        images={images}
                        setImages={setImages}
                        canvasPages={generatedPages}
                        page={page}
                        setPage={setPage}
                        setMultipleImages={setArrayImages}
                        multipleImages={arrayImages}
                        setUrl={setURLS}
                        urls={urls}
                        setAttributeArray={setAttributeArray}
                        editDocumentId={setEditDocumentId}
                        setAllAttributeArray={setAllAttributeArray}
                      />
                    }
                  />
                  <Route path='/review' element={<Review image={urls} />} />
                </Routes>
              </Box>
              {
                loading ? (
                  <Box>
                    <Loader />
                  </Box>
                ) : ''
              }
              {
                isPreviewModal ? (
                  <PdfPreview
                    pdf_id={createDocumentData._id}
                    // isCitrix={creds?.citrix}
                    // isOnedrive={creds?.onedrive}
                    // isDrive={creds?.gdrive}
                    // isComplete={IsCompleted}
                    pdfName={createDocumentData.document_name}
                    pdfpages={urls}
                    attributeArray={jsonToNestedArray(createDocumentData.attribute_array)}
                    close={() => {
                      toast.success(lan.Document_Updated_Sucessfully);
                      setPreviewModal(false);
                      setLoading(false);
                      navigate('/dashboard/documents');
                    }}
                    isAutoShare={1}
                  // isReload={() => { setIsReload(true) }}
                  />
                ) : ''
              }
            </Box>
          ) : (
            <Navigate to='/dashboard/' replace={true} />
          )
        }
      />
    </Routes>
  );
}
