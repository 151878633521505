import { getdocumentbyid } from 'api/document';
import { getdocumentbybusiness } from 'api/document';
import { getdocumentcount } from 'api/document';
import { updatedocument } from 'api/document';
import { getdocument } from 'api/document';
import { createdocument } from 'api/document';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const { toast } = require('react-toastify');


export const createDocument = async (formdata) => {
  const { data } = await createdocument(formdata);
  return data.returnDoc;
  // try {
  //   toast.success('Document Created Sucessfully');
  //   // router('/dashboard/documents');
  // } catch (error) {
  //   console.log(error);
  //   toast.error('Something went Wrong');
  // }
};

export const getDocument = async (query) => {
  const { data } = await getdocument(query);

  return data.document;
};

export const getDocumentByID = async (id) => {
  const { data } = await getdocumentbyid(id);
  return data.document;
};

export const updateDocument = async (id, formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { data } = await updatedocument(id, formdata);
  console.log(data);
  toast.success(lan.Document_Updated_Sucessfully);
  router('/admin/manage-document');
};

export const completeDocument = async (id, formdata, router) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const { data } = await updatedocument(id, formdata);
  console.log(data);
  toast.success(lan.Document_Completed);
  router('/admin/manage-document');
};

export const getDocumentByBusiness = async (id, query) => {
  const { data } = await getdocumentbybusiness(id, query);
  return data.document;
};

export const getDocumentCounts = async () => {
  const { data } = await getdocumentcount();
  return data.document;
};
