import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  Grid,
  Box,
  Container,
  Typography,
  Slide,
  Button,
} from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';
import logo from 'assets/new_logo.png';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { sendOTP } from 'actions/employee';
import { login } from 'actions/employee';
import { useDispatch } from 'react-redux';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export default function SignIn() {
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [slide, setSlide] = useState(false);
  const [unslide, setUnSlide] = useState(true);
  const [timer, setTimer] = useState(60);
  const [phone, setPhone] = useState('');
  const [otp, setOTP] = useState('');
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    [],
  );
  const sendotp = async (e) => {
    setLoading(true);
    e.preventDefault();
    await sendOTP({ phone }, () => {
      setUnSlide(false);
      setTimer(60);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
    });
    setLoading(false);
  };

  useEffect(() => {
    slide && timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback, slide]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verify = async (e) => {
    setLoading(true);
    e.preventDefault();
    await dispatch(login({ phone, otp }, navigate));
    setLoading(false);
  };
  useEffect(() => {
    if (!unslide) {
      setTimeout(() => setSlide(true), 200);
    }
  }, [unslide, setSlide]);
  useEffect(() => {
    if (!slide) {
      setTimeout(() => setUnSlide(true), 200);
    }
  }, [slide, setUnSlide]);

  return (
    <>
      <Box
        sx={{}}
        style={{
          backgroundColor: '#6DBD44',
        }}
      >
        <Box sx={{
          width: 'max-content',
          backgroundColor: 'white',
          display: 'flex',
          boxSizing: 'border-box',
          height: '100px',
          alignItems: 'center',
          p: 3
        }}
          justifyContent='center'
        >
          <img src={logo} alt='logo' width={'120px'} />
        </Box>
      </Box>
      <Box
        sx={{
          height: '86vh',
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: '#f5f5f5',
          boxSizing: 'border-box',
          overflowX: 'hidden',
        }}
      >
        <Container
          component='main'
          ref={ref}
          style={{
            backgroundColor: 'white',
            border: '1px solid #F0F0F0',
            borderRadius: '20px',
            height: '80%',
          }}
          maxWidth='sm'
        >
          <Slide
            direction='left'
            in={unslide}
            container={ref.current}
            unmountOnExit
          >
            <Container
              maxWidth='xs'
              sx={{
                marginTop: 4,
                display: 'flex',

                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component='h1'
                color='primary'
                fontWeight={600}
                fontSize={40}
              >
                {lan.Login}
              </Typography>
              <Box
                component='form'
                noValidate
                sx={{
                  mt: 4,
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <FormControl fullWidth>
                  <Typography>{lan.Mobile_Number}</Typography>
                  <MuiPhoneNumber
                    disableAreaCodes
                    required
                    variant='outlined'
                    defaultCountry='ca'
                    name='phone'
                    onChange={(e) => setPhone(e)}
                    fullWidth
                    InputProps={{
                      style: {
                        fontWeight: 700,
                      },
                    }}
                    sx={{ p: 1 }}
                    placeholder={lan.Enter_your_Mobile_Number}
                  />
                </FormControl>

                <Button
                  type='submit'
                  // component={Link}
                  // to='/admin/'
                  fullWidth
                  onClick={sendotp}
                  size='large'
                  disabled={loading}
                  variant='contained'
                  sx={{ mt: 3, mb: 2, color: 'white' }}
                >
                  {lan.Send_OTP}
                </Button>
              </Box>
            </Container>
          </Slide>

          <Slide
            direction='left'
            in={slide}
            container={ref.current}
            mountOnEnter
            unmountOnExit
          >
            <Container
              maxWidth='xs'
              sx={{
                marginTop: 4,
                display: 'flex',

                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component='h1'
                color='primary'
                fontWeight={600}
                fontSize={40}
              >
                {lan.OTP}
              </Typography>
              <Box
                component='form'
                noValidate
                onSubmit={verify}
                sx={{
                  mt: 4,
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <FormControl fullWidth>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <Typography>{lan.Please_Enter_OTP}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography color='red'>
                        {timer === 60 ? '01:00' : `00:${timer}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container justifyContent='center' xs={12}>
                    <OtpInput
                      numInputs={5}
                      containerStyle={{
                        marginTop: '10px',
                        color: 'black',
                      }}
                      isInputNum={true}
                      isInputSecure={false}
                      inputStyle={{
                        marginRight: '10px',
                        color: 'black',
                        height: '50px',
                        width: '50px',
                        borderRadius: '10px',
                        border: '1px solid #E3E3E3',
                      }}
                      value={otp}
                      onChange={(e) => setOTP(e)}
                    />
                  </Grid>
                </FormControl>

                <Button
                  type='submit'
                  fullWidth
                  size='large'
                  disabled={loading}
                  variant='contained'
                  sx={{ mt: 3, mb: 2, color: 'white' }}
                >
                  {lan.Verify}
                </Button>
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    <Button
                      onClick={() => setSlide(false)}
                      sx={{ color: 'black', textTransform: 'none' }}
                      variant='text'
                    >
                      <Typography>{lan.Go_Back}</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='text'
                      onClick={sendotp}
                      disabled={timer > 0}
                      sx={{ color: 'black', textTransform: 'none' }}
                    >
                      <Typography>{lan.Resend_OTP}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Slide>
        </Container>
      </Box>
    </>
  );
}
