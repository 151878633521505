import { changepassword } from 'api/auth';
import { updateprofile } from 'api/auth';
import { login } from 'api/auth';
import { toast } from 'react-toastify';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

export const adminLogin = (router, formdata) => async (dispatch) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await login(formdata);
    dispatch({ type: 'LOGIN', data });
    router('/admin/');
  } catch (error) {
    toast.error(lan.Invalid_Credentials);
  }
};

export const changePassword = async (formdata, state) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;
  
  try {
    await changepassword(formdata);
    state();
    toast.success(lan.Password_changed);
  } catch (error) {
    toast.error(lan.Something_went_wrong);
  }
};

export const updateProfile = async (formdata) => {

  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  try {
    const { data } = await updateprofile(formdata);
    toast.success(lan.Profile_Updated);
    return data;
  } catch (error) {
    console.log(error);
  }
};
