import {
  Grid,
  Typography,
  TextField,
  // MenuItem,
  // useTheme,
  // Divider,
  Button,
} from '@mui/material';
import { changePassword } from 'actions/auth';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import EN_JSON from 'assets/languages/en.json';
import FN_JSON from 'assets/languages/fn.json';

const Setting = () => {
  
  const lan = localStorage.getItem('language') === 'canadian' ? FN_JSON : EN_JSON;

  // const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    new_password: '',
    confirm_password: '',
  });

  const handleSubmit = async () => {
    await changePassword(state, () =>
      setState({
        new_password: '',
        confirm_password: '',
      }),
    );
  };
  return (
    <Grid sx={{ px: 3 }}>
      <Grid item xs={12} sx={{ py: 4.5 }} container alignItems='center'>
        <Typography color='primary' fontSize={25} fontWeight={600}>
          {lan.Settings}
        </Typography>
      </Grid>
      {/* <Grid
        item
        container
        xs={10}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Typography color='secondary' fontSize={20} fontWeight={400}>
            Language
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            sx={{ p: 1 }}
            InputProps={{
              style: {
                color: theme.custom.primary,
                fontWeight: 700,
                borderRadius: '20px',
              },
            }}
            select
            value={'english'}
          >
            <MenuItem value='english'>English</MenuItem>
            <MenuItem value='canadian'>Canadian French</MenuItem>
          </TextField>
        </Grid>
      </Grid> */}
      {/* <Grid item xs={12} sx={{ paddingRight: 5, paddingLeft: 5 }}>
        <Divider sx={{ mt: 2 }} />
      </Grid> */}
      <Grid item container xs={12}>
        <Typography color='secondary' fontSize={20} fontWeight={400}>
          {lan.Change_Password}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems='center'
        justifyContent='center'
        spacing={6}
        sx={{ mt: 1, px: 6 }}
      >
        <Grid item xs={3}>
          <Typography>{lan.Enter_new_Password}</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            value={state.new_password}
            sx={{ width: '60%' }}
            onChange={(e) =>
              setState((state) => ({ ...state, new_password: e.target.value }))
            }
            type='text'
            placeholder={lan.Enter_your_password}
          />
        </Grid>

        {/* <Grid item xs={3}>
          <Typography>Confirm Password</Typography>
        </Grid> */}
        {/* <Grid item xs={9}>
          <TextField
            sx={{ width: '60%' }}
            value={state.confirm_password}
            placeholder='Enter your password'
            type='password'
            onChange={(e) =>
              setState((state) => ({
                ...state,
                confirm_password: e.target.value,
              }))
            }
          />
        </Grid> */}
        <Grid item xs={4}></Grid>
        <Grid item xs={8} spacing={4} container>
          <Grid item xs={12}>
            <Button
              color='primary'
              variant='contained'
              sx={{ color: 'white', width: '40% ' }}
              onClick={handleSubmit}
              size={'large'}
            >
              {lan.Change_Password}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size='large'
              variant='outlined'
              onClick={() => {
                dispatch({ type: 'LOGOUT' });
                navigate('/admin/login');
              }}
              sx={{
                color: 'red',
                width: '40%',
                borderRadius: '10px',
                borderColor: 'gray',
              }}
            >
              {lan.Logout}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Setting;
