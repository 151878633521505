import axios from 'axios';
import baseurl from './baseurl';
const API = axios.create({ baseURL: `${baseurl}/business` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return req;
});

export const createbusiness = (formdata) => API.post('/', formdata);
export const getbusiness = (query) => API.get('?' + query);
export const getbusinessbyid = (id) => API.get(`/${id}`);
export const updatebusiness = (id, formdata) => API.put(`/${id}`, formdata);
export const deletebusiness = (id) => API.delete(`/${id}`);
