import * as React from "react";

import {
  FormControl,
  TextField,
  CssBaseline,
  Box,
  Container,
  Typography,
  Button,
} from "@mui/material";

export default function ResetPassword() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ backgroundColor: "white" }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          color="primary"
          fontWeight={600}
          fontSize={40}
        >
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 4,
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl fullWidth>
            <Typography>New Password</Typography>
            <TextField
              sx={{ mt: 0.5 }}
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              placeholder="Enter your email id"
              autoComplete="email"
              autoFocus
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 2 }}>
            <Typography>Confirm Password</Typography>

            <TextField
              sx={{ mt: 0.5 }}
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              placeholder="Enter your password"
              id="password"
              autoComplete="current-password"
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "white" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
