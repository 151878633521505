import React from 'react';
import { Grid, Typography, useTheme, Box, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';
const UpdatesCard = ({ data, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `1px solid ${theme.custom.primary}`,
        width: '100%',
        p: 2,
        my: 1,
        borderRadius: '10px',
      }}
    >
      <Grid
        container
        justifyContent='space-between'
        item
        alignItems='center'
        xs={12}
      >
        <Grid item sx={12} md={6}>
          <Typography fontWeight={500} fontSize={15}>
            {data.update}
          </Typography>
        </Grid>
        <Grid item sx={6} md={2}>
          <Typography color='#C6C6C6' fontWeight={500} fontSize={15}>
            {moment(data.createdAt).fromNow()}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} container justifyContent={'flex-end'}>
          <IconButton onClick={onClick}>
            <HighlightOffIcon sx={{ color: theme.custom.primary }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdatesCard;
