import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import auth from './auth';
import file from './file';
import load from './loader';
const store = createStore(
  combineReducers({ auth: auth, file: file, load: load }),
  applyMiddleware(thunk),
);

export default store;
