const reducer = (state = { file: null, url: '' }, actions) => {
  switch (actions.type) {
    case 'FILE':
      return actions.data;
    default:
      return state;
  }
};

export default reducer;
